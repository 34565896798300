export interface Breadcrumb {
	id: string
	displayText: string
	url: string
}

type AdminRoute<
	Id extends string,
	Path extends (...args: never) => string = () => string,
	Display extends (...args: never[]) => Breadcrumb = () => Breadcrumb,
	BreadCrumbs extends (...args: never[]) => Breadcrumb[] = () => Breadcrumb[],
> = {
	[key in Id]: {
		path: Path
		display: Display
		breadcrumbs: BreadCrumbs
	}
}

type AdminRouteMap = AdminRoute<
	| 'admin'
	| 'workspace'
	| 'workspaces'
	| 'applications'
	| 'users'
	| 'manage-system'
	| 'manage-invitations'
> &
	AdminRoute<
		'workspaceApps',
		() => string,
		(workspaceId: string, workspaceName: string) => Breadcrumb,
		(workspaceId: string, workspaceName: string) => Breadcrumb[]
	> &
	AdminRoute<
		'appVariants',
		() => string,
		(workspaceId: string, appId: string, appName: string) => Breadcrumb,
		(
			workspaceId: string,
			workspaceName: string,
			appId: string,
			appName: string
		) => Breadcrumb[]
	> &
	AdminRoute<
		'applicationsVariants',
		() => string,
		(appName: string, appId: string) => Breadcrumb,
		(appName: string, appId: string) => Breadcrumb[]
	>
export const ADMIN_ROUTES: AdminRouteMap = {
	admin: {
		path: () => '/admin/manage-system',
		display: () => ({
			id: 'admin',
			displayText: 'Admin',
			url: '/admin/manage-system',
		}),
		breadcrumbs: () => [
			{ id: 'home', displayText: 'ASTA', url: '/' },
			ADMIN_ROUTES.admin.display(),
		],
	},
	workspaces: {
		path: () => '/admin/workspaces',
		display: () => ({
			id: 'workspaces',
			displayText: 'Workspaces',
			url: '/admin/workspaces',
		}),
		breadcrumbs: () => [
			...ADMIN_ROUTES.admin.breadcrumbs(),
			ADMIN_ROUTES.workspaces.display(),
		],
	},
	workspace: {
		path: () => '/admin/workspace',
		display: () => ({
			id: 'workspace',
			displayText: 'Workspace',
			url: '/admin/workspace',
		}),
		breadcrumbs: () => [
			...ADMIN_ROUTES.admin.breadcrumbs(),
			ADMIN_ROUTES.workspace.display(),
		],
	},
	applications: {
		path: () => '/admin/applications',
		display: () => ({
			id: 'applications',
			displayText: 'Applications',
			url: '/admin/applications',
		}),
		breadcrumbs: () => [
			...ADMIN_ROUTES.admin.breadcrumbs(),
			ADMIN_ROUTES.applications.display(),
		],
	},
	users: {
		path: () => '/admin/users',
		display: () => ({
			id: 'users',
			displayText: 'Users',
			url: '/admin/users',
		}),
		breadcrumbs: () => [
			...ADMIN_ROUTES.admin.breadcrumbs(),
			ADMIN_ROUTES.users.display(),
		],
	},
	'manage-system': {
		path: () => '/admin/manage-system',
		display: () => ({
			id: 'manage-system',
			displayText: 'System',
			url: '/admin/manage-system',
		}),
		breadcrumbs: () => [
			...ADMIN_ROUTES.admin.breadcrumbs(),
			ADMIN_ROUTES['manage-system'].display(),
		],
	},
	'manage-invitations': {
		path: () => '/admin/manage-invitations',
		display: () => ({
			id: 'manage-invitations',
			displayText: 'Invitations',
			url: '/admin/manage-invitations',
		}),
		breadcrumbs: () => [
			...ADMIN_ROUTES.admin.breadcrumbs(),
			ADMIN_ROUTES['manage-invitations'].display(),
		],
	},
	workspaceApps: {
		path: () => `/admin/workspaces/:workspaceId`,
		display: (workspaceId: string, workspaceName: string) => ({
			id: 'workspaceApps',
			displayText: workspaceName,
			url: `/admin/workspaces/${workspaceId}`,
		}),
		breadcrumbs: (workspaceId: string, workspaceName: string) => [
			...ADMIN_ROUTES.workspaces.breadcrumbs(),
			ADMIN_ROUTES.workspaceApps.display(workspaceId, workspaceName),
		],
	},
	appVariants: {
		path: () =>
			'/admin/workspaces/:workspaceId/applications/:applicationId',
		display: (workspaceId: string, appId: string, appName: string) => ({
			id: 'appVariants',
			displayText: appName,
			url: `/admin/workspaces/${workspaceId}/applications/${appId}`,
		}),
		breadcrumbs: (
			workspaceId: string,
			workspaceName: string,
			appId: string,
			appName: string
		) => [
			...ADMIN_ROUTES.workspaceApps.breadcrumbs(
				workspaceId,
				workspaceName
			),
			ADMIN_ROUTES.appVariants.display(workspaceId, appId, appName),
		],
	},
	applicationsVariants: {
		path: () => '/admin/applications/:applicationId',
		display: (appName: string, appId: string) => ({
			id: 'applicationsVariants',
			displayText: appName,
			url: `/admin/applications/${appId}`,
		}),
		breadcrumbs: (appName: string, appId: string) => [
			...ADMIN_ROUTES.applications.breadcrumbs(),
			ADMIN_ROUTES.applicationsVariants.display(appName, appId),
		],
	},
}

type TestCenterRoute<
	Id extends string,
	Path extends (...args: never) => string,
	Display extends (...args: never[]) => Breadcrumb,
	BreadCrumbs extends (...args: never[]) => Breadcrumb[],
> = {
	[key in Id]: {
		path: Path
		display: Display
		breadcrumbs: BreadCrumbs
	}
}

type TestCenterRouteMap = TestCenterRoute<
	'landing',
	() => string,
	() => Breadcrumb,
	() => Breadcrumb[]
> &
	TestCenterRoute<
		'start',
		() => string,
		() => Breadcrumb,
		() => Breadcrumb[]
	> &
	TestCenterRoute<
		'status',
		() => string,
		() => Breadcrumb,
		() => Breadcrumb[]
	> &
	TestCenterRoute<
		'dashboard',
		(runId?: string) => string,
		() => Breadcrumb,
		() => Breadcrumb[]
	> &
	TestCenterRoute<
		'tags',
		() => string,
		() => Breadcrumb,
		() => Breadcrumb[]
	> &
	TestCenterRoute<
		'runs',
		() => string,
		() => Breadcrumb,
		() => Breadcrumb[]
	> &
	TestCenterRoute<
		'log',
		(runId?: string) => string,
		() => Breadcrumb,
		() => Breadcrumb[]
	> &
	// TODO(don): un-comment when we add issues page back
	// TestCenterRoute<
	// 	'issues',
	// 	() => string,
	// 	() => Breadcrumb,
	// 	() => Breadcrumb[]
	// > &
	TestCenterRoute<
		'model',
		() => string,
		() => Breadcrumb,
		() => Breadcrumb[]
	> &
	TestCenterRoute<
		'rules',
		(search?: string) => string,
		() => Breadcrumb,
		() => Breadcrumb[]
	> &
	TestCenterRoute<
		'flows',
		() => string,
		() => Breadcrumb,
		() => Breadcrumb[]
	> &
	TestCenterRoute<
		'data',
		() => string,
		() => Breadcrumb,
		() => Breadcrumb[]
	> &
	TestCenterRoute<
		'run-templates',
		() => string,
		() => Breadcrumb,
		() => Breadcrumb[]
	> &
	TestCenterRoute<
		'form-specs',
		() => string,
		() => Breadcrumb,
		() => Breadcrumb[]
	>

export const TEST_CENTER_ROUTES: TestCenterRouteMap = {
	landing: {
		path: () => 'start',
		display: () => ({
			id: 'start',
			displayText: 'Start',
			url: `/`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES.landing.display()],
	},
	start: {
		path: () => 'start',
		display: () => ({
			id: 'start',
			displayText: 'Start',
			url: `/start`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES.start.display()],
	},
	status: {
		path: () => 'status',
		display: () => ({
			id: 'status',
			displayText: 'Status',
			url: `/status`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES.status.display()],
	},
	dashboard: {
		path: (runId?: string) => `dashboard${runId ? `?run=${runId}` : ''}`,
		display: () => ({
			id: 'dashboard',
			displayText: 'Dashboard',
			url: `/dashboard`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES.log.display()],
	},
	tags: {
		path: () => 'tags',
		display: () => ({
			id: 'tags',
			displayText: 'Tags',
			url: `/tags`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES.tags.display()],
	},
	runs: {
		path: () => `runs`,
		display: () => ({
			id: 'runs',
			displayText: 'Runs',
			url: `/runs`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES.runs.display()],
	},
	log: {
		path: (runId?: string) => `log${runId ? `?run[$eq]=${runId}` : ''}`,
		display: () => ({
			id: 'log',
			displayText: 'Log',
			url: `/log`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES.log.display()],
	},
	// TODO(don): un-comment when we add issues page back
	// issues: {
	// 	path: () => `issues`,
	// 	display: () => ({
	// 		id: 'issues',
	// 		displayText: 'Issues',
	// 		url: `/issues`,
	// 	}),
	// 	breadcrumbs: () => [TEST_CENTER_ROUTES.issues.display()],
	// },
	model: {
		path: () => `model`,
		display: () => ({
			id: 'model',
			displayText: 'Model',
			url: `/model`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES.model.display()],
	},
	rules: {
		path: (search?: string) =>
			`rules${search ? `?search[$regex]=${search}` : ''}`,
		display: () => ({
			id: 'rules',
			displayText: 'Rules',
			url: `/rules`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES.rules.display()],
	},
	flows: {
		path: () => `flows`,
		display: () => ({
			id: 'flows',
			displayText: 'Flows',
			url: `/flows`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES.flows.display()],
	},
	data: {
		path: () => `data`,
		display: () => ({
			id: 'data',
			displayText: 'Data',
			url: `/data`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES.data.display()],
	},
	'run-templates': {
		path: () => `run-templates`,
		display: () => ({
			id: 'run-templates',
			displayText: 'Run Templates',
			url: `/run-templates`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES['run-templates'].display()],
	},
	'form-specs': {
		path: () => `form-specs`,
		display: () => ({
			id: 'form-specs',
			displayText: 'Form Specs',
			url: `/form-specs`,
		}),
		breadcrumbs: () => [TEST_CENTER_ROUTES['form-specs'].display()],
	},
}
