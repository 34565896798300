//TODO (Camilo): We should import the enum from our repository.
//This is currently not possible because of an issue with nestjs, maybe the generated types can fix this??
export enum RoleType {
	none,
	viewer,
	reviewer,
	member,
	owner,
	admin,
}

export const canEdit = (role: RoleType) => role >= RoleType.member
export const canManage = (role: RoleType) => role >= RoleType.owner
export const canCreate = (role: RoleType) => role >= RoleType.member
export const canRemove = (role: RoleType) => role >= RoleType.owner
export const isOwner = (role: RoleType) => role === RoleType.owner
