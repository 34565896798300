/**
 * Generated by @openapi-codegen
 *
 * @version 0.17.1
 */
import * as reactQuery from '@tanstack/react-query'
import { useRepositoryContext, RepositoryContext } from './repositoryContext'
import type * as Fetcher from './repositoryFetcher'
import { repositoryFetch } from './repositoryFetcher'
import type * as Schemas from './repositorySchemas'

export type RuleControllerFindAllTableDataPathParams = {
	appId: string
}

export type RuleControllerFindAllTableDataQueryParams = {
	filters?: Schemas.AssetsFilterDTO
	groupBy?: Record<string, any>
	sort?: 'parent' | 'name' | 'status' | 'location' | 'link-rule' | 'rule_type'
}

export type RuleControllerFindAllTableDataError =
	Fetcher.ErrorWrapper<undefined>

export type RuleControllerFindAllTableDataResponse =
	Schemas.AssetTableEntryDTO[]

export type RuleControllerFindAllTableDataVariables = {
	pathParams: RuleControllerFindAllTableDataPathParams
	queryParams?: RuleControllerFindAllTableDataQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchRuleControllerFindAllTableData = (
	variables: RuleControllerFindAllTableDataVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		RuleControllerFindAllTableDataResponse,
		RuleControllerFindAllTableDataError,
		undefined,
		{},
		RuleControllerFindAllTableDataQueryParams,
		RuleControllerFindAllTableDataPathParams
	>({
		url: '/api/v2/assets/{appId}/rule/application/table-data',
		method: 'get',
		...variables,
		signal,
	})

export const useRuleControllerFindAllTableData = <
	TData = RuleControllerFindAllTableDataResponse
>(
	variables: RuleControllerFindAllTableDataVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			RuleControllerFindAllTableDataResponse,
			RuleControllerFindAllTableDataError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		RuleControllerFindAllTableDataResponse,
		RuleControllerFindAllTableDataError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/rule/application/table-data',
			operationId: 'ruleControllerFindAllTableData',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRuleControllerFindAllTableData(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RuleControllerFindAllBasePathParams = {
	appId: string
}

export type RuleControllerFindAllBaseError = Fetcher.ErrorWrapper<undefined>

export type RuleControllerFindAllBaseResponse = Schemas.Rule[]

export type RuleControllerFindAllBaseVariables = {
	pathParams: RuleControllerFindAllBasePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRuleControllerFindAllBase = (
	variables: RuleControllerFindAllBaseVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		RuleControllerFindAllBaseResponse,
		RuleControllerFindAllBaseError,
		undefined,
		{},
		{},
		RuleControllerFindAllBasePathParams
	>({
		url: '/api/v2/assets/{appId}/rule',
		method: 'get',
		...variables,
		signal,
	})

export const useRuleControllerFindAllBase = <
	TData = RuleControllerFindAllBaseResponse
>(
	variables: RuleControllerFindAllBaseVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			RuleControllerFindAllBaseResponse,
			RuleControllerFindAllBaseError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		RuleControllerFindAllBaseResponse,
		RuleControllerFindAllBaseError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/rule',
			operationId: 'ruleControllerFindAllBase',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRuleControllerFindAllBase(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RuleControllerCreatePathParams = {
	appId: string
}

export type RuleControllerCreateError = Fetcher.ErrorWrapper<undefined>

export type RuleControllerCreateVariables = {
	body: Schemas.CreateAssetDto
	pathParams: RuleControllerCreatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRuleControllerCreate = (
	variables: RuleControllerCreateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Rule,
		RuleControllerCreateError,
		Schemas.CreateAssetDto,
		{},
		{},
		RuleControllerCreatePathParams
	>({
		url: '/api/v2/assets/{appId}/rule',
		method: 'post',
		...variables,
		signal,
	})

export const useRuleControllerCreate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Rule,
			RuleControllerCreateError,
			RuleControllerCreateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.Rule,
		RuleControllerCreateError,
		RuleControllerCreateVariables
	>({
		mutationFn: (variables: RuleControllerCreateVariables) =>
			fetchRuleControllerCreate({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type RuleControllerFindAllPathParams = {
	appId: string
}

export type RuleControllerFindAllQueryParams = {
	filters?: Schemas.AssetsFilterDTO
	sort?: 'parent' | 'name' | 'status' | 'location' | 'link-rule' | 'rule_type'
}

export type RuleControllerFindAllError = Fetcher.ErrorWrapper<undefined>

export type RuleControllerFindAllResponse =
	Schemas.RuleWithPopulatedParentAndTags[]

export type RuleControllerFindAllVariables = {
	pathParams: RuleControllerFindAllPathParams
	queryParams?: RuleControllerFindAllQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchRuleControllerFindAll = (
	variables: RuleControllerFindAllVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		RuleControllerFindAllResponse,
		RuleControllerFindAllError,
		undefined,
		{},
		RuleControllerFindAllQueryParams,
		RuleControllerFindAllPathParams
	>({
		url: '/api/v2/assets/{appId}/rule/application',
		method: 'get',
		...variables,
		signal,
	})

export const useRuleControllerFindAll = <TData = RuleControllerFindAllResponse>(
	variables: RuleControllerFindAllVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			RuleControllerFindAllResponse,
			RuleControllerFindAllError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		RuleControllerFindAllResponse,
		RuleControllerFindAllError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/rule/application',
			operationId: 'ruleControllerFindAll',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRuleControllerFindAll(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RuleControllerFindOnePathParams = {
	appId: string
	id: string
}

export type RuleControllerFindOneError = Fetcher.ErrorWrapper<undefined>

export type RuleControllerFindOneVariables = {
	pathParams: RuleControllerFindOnePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRuleControllerFindOne = (
	variables: RuleControllerFindOneVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Rule,
		RuleControllerFindOneError,
		undefined,
		{},
		{},
		RuleControllerFindOnePathParams
	>({
		url: '/api/v2/assets/{appId}/rule/{id}',
		method: 'get',
		...variables,
		signal,
	})

export const useRuleControllerFindOne = <TData = Schemas.Rule>(
	variables: RuleControllerFindOneVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.Rule,
			RuleControllerFindOneError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<Schemas.Rule, RuleControllerFindOneError, TData>(
		{
			queryKey: queryKeyFn({
				path: '/api/v2/assets/{appId}/rule/{id}',
				operationId: 'ruleControllerFindOne',
				variables,
			}),
			queryFn: ({ signal }) =>
				fetchRuleControllerFindOne(
					{ ...fetcherOptions, ...variables },
					signal
				),
			...options,
			...queryOptions,
		}
	)
}

export type RuleControllerUpdatePathParams = {
	appId: string
	id: string
}

export type RuleControllerUpdateError = Fetcher.ErrorWrapper<undefined>

export type RuleControllerUpdateVariables = {
	body: Schemas.UpdateAssetDto
	pathParams: RuleControllerUpdatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRuleControllerUpdate = (
	variables: RuleControllerUpdateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Rule,
		RuleControllerUpdateError,
		Schemas.UpdateAssetDto,
		{},
		{},
		RuleControllerUpdatePathParams
	>({
		url: '/api/v2/assets/{appId}/rule/{id}',
		method: 'put',
		...variables,
		signal,
	})

export const useRuleControllerUpdate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Rule,
			RuleControllerUpdateError,
			RuleControllerUpdateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.Rule,
		RuleControllerUpdateError,
		RuleControllerUpdateVariables
	>({
		mutationFn: (variables: RuleControllerUpdateVariables) =>
			fetchRuleControllerUpdate({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type RuleControllerRemovePathParams = {
	appId: string
	id: string
}

export type RuleControllerRemoveError = Fetcher.ErrorWrapper<undefined>

export type RuleControllerRemoveVariables = {
	pathParams: RuleControllerRemovePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRuleControllerRemove = (
	variables: RuleControllerRemoveVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		string,
		RuleControllerRemoveError,
		undefined,
		{},
		{},
		RuleControllerRemovePathParams
	>({
		url: '/api/v2/assets/{appId}/rule/{id}',
		method: 'delete',
		...variables,
		signal,
	})

export const useRuleControllerRemove = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			string,
			RuleControllerRemoveError,
			RuleControllerRemoveVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		string,
		RuleControllerRemoveError,
		RuleControllerRemoveVariables
	>({
		mutationFn: (variables: RuleControllerRemoveVariables) =>
			fetchRuleControllerRemove({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type RuleControllerImportPathParams = {
	appId: string
}

export type RuleControllerImportError = Fetcher.ErrorWrapper<undefined>

export type RuleControllerImportVariables = {
	body: Schemas.ImportAssetDto
	pathParams: RuleControllerImportPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRuleControllerImport = (
	variables: RuleControllerImportVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.ImportResponseDto,
		RuleControllerImportError,
		Schemas.ImportAssetDto,
		{},
		{},
		RuleControllerImportPathParams
	>({
		url: '/api/v2/assets/{appId}/rule/import',
		method: 'post',
		...variables,
		signal,
	})

export const useRuleControllerImport = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.ImportResponseDto,
			RuleControllerImportError,
			RuleControllerImportVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.ImportResponseDto,
		RuleControllerImportError,
		RuleControllerImportVariables
	>({
		mutationFn: (variables: RuleControllerImportVariables) =>
			fetchRuleControllerImport({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type RuleControllerUpdateParentPathParams = {
	appId: string
	id: string
}

export type RuleControllerUpdateParentError = Fetcher.ErrorWrapper<undefined>

export type RuleControllerUpdateParentVariables = {
	body: Schemas.UpdateAssetParentDTO
	pathParams: RuleControllerUpdateParentPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRuleControllerUpdateParent = (
	variables: RuleControllerUpdateParentVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Rule,
		RuleControllerUpdateParentError,
		Schemas.UpdateAssetParentDTO,
		{},
		{},
		RuleControllerUpdateParentPathParams
	>({
		url: '/api/v2/assets/{appId}/rule/{id}/updateParent',
		method: 'put',
		...variables,
		signal,
	})

export const useRuleControllerUpdateParent = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Rule,
			RuleControllerUpdateParentError,
			RuleControllerUpdateParentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.Rule,
		RuleControllerUpdateParentError,
		RuleControllerUpdateParentVariables
	>({
		mutationFn: (variables: RuleControllerUpdateParentVariables) =>
			fetchRuleControllerUpdateParent({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type RuleControllerUpdateTagsPathParams = {
	appId: string
	id: string
}

export type RuleControllerUpdateTagsError = Fetcher.ErrorWrapper<undefined>

export type RuleControllerUpdateTagsRequestBody = string[]

export type RuleControllerUpdateTagsVariables = {
	body?: RuleControllerUpdateTagsRequestBody
	pathParams: RuleControllerUpdateTagsPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRuleControllerUpdateTags = (
	variables: RuleControllerUpdateTagsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Rule,
		RuleControllerUpdateTagsError,
		RuleControllerUpdateTagsRequestBody,
		{},
		{},
		RuleControllerUpdateTagsPathParams
	>({
		url: '/api/v2/assets/{appId}/rule/{id}/tags',
		method: 'put',
		...variables,
		signal,
	})

export const useRuleControllerUpdateTags = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Rule,
			RuleControllerUpdateTagsError,
			RuleControllerUpdateTagsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.Rule,
		RuleControllerUpdateTagsError,
		RuleControllerUpdateTagsVariables
	>({
		mutationFn: (variables: RuleControllerUpdateTagsVariables) =>
			fetchRuleControllerUpdateTags({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type RuleControllerGetAssetsByNamePathParams = {
	appId: string
}

export type RuleControllerGetAssetsByNameQueryParams = {
	name?: string
	populate?: 'parent'[]
}

export type RuleControllerGetAssetsByNameError = Fetcher.ErrorWrapper<undefined>

export type RuleControllerGetAssetsByNameResponse = Schemas.Rule[]

export type RuleControllerGetAssetsByNameVariables = {
	pathParams: RuleControllerGetAssetsByNamePathParams
	queryParams?: RuleControllerGetAssetsByNameQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchRuleControllerGetAssetsByName = (
	variables: RuleControllerGetAssetsByNameVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		RuleControllerGetAssetsByNameResponse,
		RuleControllerGetAssetsByNameError,
		undefined,
		{},
		RuleControllerGetAssetsByNameQueryParams,
		RuleControllerGetAssetsByNamePathParams
	>({
		url: '/api/v2/assets/{appId}/rule/application/get-by-name',
		method: 'get',
		...variables,
		signal,
	})

export const useRuleControllerGetAssetsByName = <
	TData = RuleControllerGetAssetsByNameResponse
>(
	variables: RuleControllerGetAssetsByNameVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			RuleControllerGetAssetsByNameResponse,
			RuleControllerGetAssetsByNameError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		RuleControllerGetAssetsByNameResponse,
		RuleControllerGetAssetsByNameError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/rule/application/get-by-name',
			operationId: 'ruleControllerGetAssetsByName',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRuleControllerGetAssetsByName(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type DatasetControllerFindAllTableDataPathParams = {
	appId: string
}

export type DatasetControllerFindAllTableDataQueryParams = {
	filters?: Schemas.AssetsFilterDTO
	groupBy?: Record<string, any>
	sort?: 'parent' | 'name' | 'status' | 'location' | 'link-rule' | 'rule_type'
}

export type DatasetControllerFindAllTableDataError =
	Fetcher.ErrorWrapper<undefined>

export type DatasetControllerFindAllTableDataResponse =
	Schemas.AssetTableEntryDTO[]

export type DatasetControllerFindAllTableDataVariables = {
	pathParams: DatasetControllerFindAllTableDataPathParams
	queryParams?: DatasetControllerFindAllTableDataQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchDatasetControllerFindAllTableData = (
	variables: DatasetControllerFindAllTableDataVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		DatasetControllerFindAllTableDataResponse,
		DatasetControllerFindAllTableDataError,
		undefined,
		{},
		DatasetControllerFindAllTableDataQueryParams,
		DatasetControllerFindAllTableDataPathParams
	>({
		url: '/api/v2/assets/{appId}/dataset/application/table-data',
		method: 'get',
		...variables,
		signal,
	})

export const useDatasetControllerFindAllTableData = <
	TData = DatasetControllerFindAllTableDataResponse
>(
	variables: DatasetControllerFindAllTableDataVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			DatasetControllerFindAllTableDataResponse,
			DatasetControllerFindAllTableDataError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		DatasetControllerFindAllTableDataResponse,
		DatasetControllerFindAllTableDataError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/dataset/application/table-data',
			operationId: 'datasetControllerFindAllTableData',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchDatasetControllerFindAllTableData(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type DatasetControllerFindAllPathParams = {
	appId: string
}

export type DatasetControllerFindAllError = Fetcher.ErrorWrapper<undefined>

export type DatasetControllerFindAllResponse = Schemas.Dataset[]

export type DatasetControllerFindAllVariables = {
	pathParams: DatasetControllerFindAllPathParams
} & RepositoryContext['fetcherOptions']

export const fetchDatasetControllerFindAll = (
	variables: DatasetControllerFindAllVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		DatasetControllerFindAllResponse,
		DatasetControllerFindAllError,
		undefined,
		{},
		{},
		DatasetControllerFindAllPathParams
	>({
		url: '/api/v2/assets/{appId}/dataset',
		method: 'get',
		...variables,
		signal,
	})

export const useDatasetControllerFindAll = <
	TData = DatasetControllerFindAllResponse
>(
	variables: DatasetControllerFindAllVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			DatasetControllerFindAllResponse,
			DatasetControllerFindAllError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		DatasetControllerFindAllResponse,
		DatasetControllerFindAllError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/dataset',
			operationId: 'datasetControllerFindAll',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchDatasetControllerFindAll(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type DatasetControllerCreatePathParams = {
	appId: string
}

export type DatasetControllerCreateError = Fetcher.ErrorWrapper<undefined>

export type DatasetControllerCreateVariables = {
	body: Schemas.CreateAssetDto
	pathParams: DatasetControllerCreatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchDatasetControllerCreate = (
	variables: DatasetControllerCreateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Dataset,
		DatasetControllerCreateError,
		Schemas.CreateAssetDto,
		{},
		{},
		DatasetControllerCreatePathParams
	>({
		url: '/api/v2/assets/{appId}/dataset',
		method: 'post',
		...variables,
		signal,
	})

export const useDatasetControllerCreate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Dataset,
			DatasetControllerCreateError,
			DatasetControllerCreateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.Dataset,
		DatasetControllerCreateError,
		DatasetControllerCreateVariables
	>({
		mutationFn: (variables: DatasetControllerCreateVariables) =>
			fetchDatasetControllerCreate({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type DatasetControllerFindAllApplicationPathParams = {
	appId: string
}

export type DatasetControllerFindAllApplicationQueryParams = {
	filters?: Schemas.AssetsFilterDTO
	sort?: 'parent' | 'name' | 'status' | 'location' | 'link-rule' | 'rule_type'
}

export type DatasetControllerFindAllApplicationError =
	Fetcher.ErrorWrapper<undefined>

export type DatasetControllerFindAllApplicationResponse =
	Schemas.DatasetWithPopulatedParentAndTags[]

export type DatasetControllerFindAllApplicationVariables = {
	pathParams: DatasetControllerFindAllApplicationPathParams
	queryParams?: DatasetControllerFindAllApplicationQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchDatasetControllerFindAllApplication = (
	variables: DatasetControllerFindAllApplicationVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		DatasetControllerFindAllApplicationResponse,
		DatasetControllerFindAllApplicationError,
		undefined,
		{},
		DatasetControllerFindAllApplicationQueryParams,
		DatasetControllerFindAllApplicationPathParams
	>({
		url: '/api/v2/assets/{appId}/dataset/application',
		method: 'get',
		...variables,
		signal,
	})

export const useDatasetControllerFindAllApplication = <
	TData = DatasetControllerFindAllApplicationResponse
>(
	variables: DatasetControllerFindAllApplicationVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			DatasetControllerFindAllApplicationResponse,
			DatasetControllerFindAllApplicationError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		DatasetControllerFindAllApplicationResponse,
		DatasetControllerFindAllApplicationError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/dataset/application',
			operationId: 'datasetControllerFindAllApplication',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchDatasetControllerFindAllApplication(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type DatasetControllerFindOnePathParams = {
	appId: string
	id: string
}

export type DatasetControllerFindOneError = Fetcher.ErrorWrapper<undefined>

export type DatasetControllerFindOneVariables = {
	pathParams: DatasetControllerFindOnePathParams
} & RepositoryContext['fetcherOptions']

export const fetchDatasetControllerFindOne = (
	variables: DatasetControllerFindOneVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Dataset,
		DatasetControllerFindOneError,
		undefined,
		{},
		{},
		DatasetControllerFindOnePathParams
	>({
		url: '/api/v2/assets/{appId}/dataset/{id}',
		method: 'get',
		...variables,
		signal,
	})

export const useDatasetControllerFindOne = <TData = Schemas.Dataset>(
	variables: DatasetControllerFindOneVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.Dataset,
			DatasetControllerFindOneError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.Dataset,
		DatasetControllerFindOneError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/dataset/{id}',
			operationId: 'datasetControllerFindOne',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchDatasetControllerFindOne(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type DatasetControllerUpdatePathParams = {
	appId: string
	id: string
}

export type DatasetControllerUpdateError = Fetcher.ErrorWrapper<undefined>

export type DatasetControllerUpdateVariables = {
	body: Schemas.UpdateAssetDto
	pathParams: DatasetControllerUpdatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchDatasetControllerUpdate = (
	variables: DatasetControllerUpdateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Dataset,
		DatasetControllerUpdateError,
		Schemas.UpdateAssetDto,
		{},
		{},
		DatasetControllerUpdatePathParams
	>({
		url: '/api/v2/assets/{appId}/dataset/{id}',
		method: 'put',
		...variables,
		signal,
	})

export const useDatasetControllerUpdate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Dataset,
			DatasetControllerUpdateError,
			DatasetControllerUpdateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.Dataset,
		DatasetControllerUpdateError,
		DatasetControllerUpdateVariables
	>({
		mutationFn: (variables: DatasetControllerUpdateVariables) =>
			fetchDatasetControllerUpdate({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type DatasetControllerRemovePathParams = {
	appId: string
	id: string
}

export type DatasetControllerRemoveError = Fetcher.ErrorWrapper<undefined>

export type DatasetControllerRemoveVariables = {
	pathParams: DatasetControllerRemovePathParams
} & RepositoryContext['fetcherOptions']

export const fetchDatasetControllerRemove = (
	variables: DatasetControllerRemoveVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		string,
		DatasetControllerRemoveError,
		undefined,
		{},
		{},
		DatasetControllerRemovePathParams
	>({
		url: '/api/v2/assets/{appId}/dataset/{id}',
		method: 'delete',
		...variables,
		signal,
	})

export const useDatasetControllerRemove = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			string,
			DatasetControllerRemoveError,
			DatasetControllerRemoveVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		string,
		DatasetControllerRemoveError,
		DatasetControllerRemoveVariables
	>({
		mutationFn: (variables: DatasetControllerRemoveVariables) =>
			fetchDatasetControllerRemove({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type DatasetControllerImportPathParams = {
	appId: string
}

export type DatasetControllerImportError = Fetcher.ErrorWrapper<undefined>

export type DatasetControllerImportVariables = {
	body: Schemas.ImportAssetDto
	pathParams: DatasetControllerImportPathParams
} & RepositoryContext['fetcherOptions']

export const fetchDatasetControllerImport = (
	variables: DatasetControllerImportVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.ImportResponseDto,
		DatasetControllerImportError,
		Schemas.ImportAssetDto,
		{},
		{},
		DatasetControllerImportPathParams
	>({
		url: '/api/v2/assets/{appId}/dataset/import',
		method: 'post',
		...variables,
		signal,
	})

export const useDatasetControllerImport = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.ImportResponseDto,
			DatasetControllerImportError,
			DatasetControllerImportVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.ImportResponseDto,
		DatasetControllerImportError,
		DatasetControllerImportVariables
	>({
		mutationFn: (variables: DatasetControllerImportVariables) =>
			fetchDatasetControllerImport({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type DatasetControllerUpdateParentPathParams = {
	appId: string
	id: string
}

export type DatasetControllerUpdateParentError = Fetcher.ErrorWrapper<undefined>

export type DatasetControllerUpdateParentVariables = {
	body: Schemas.UpdateAssetParentDTO
	pathParams: DatasetControllerUpdateParentPathParams
} & RepositoryContext['fetcherOptions']

export const fetchDatasetControllerUpdateParent = (
	variables: DatasetControllerUpdateParentVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Dataset,
		DatasetControllerUpdateParentError,
		Schemas.UpdateAssetParentDTO,
		{},
		{},
		DatasetControllerUpdateParentPathParams
	>({
		url: '/api/v2/assets/{appId}/dataset/{id}/updateParent',
		method: 'put',
		...variables,
		signal,
	})

export const useDatasetControllerUpdateParent = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Dataset,
			DatasetControllerUpdateParentError,
			DatasetControllerUpdateParentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.Dataset,
		DatasetControllerUpdateParentError,
		DatasetControllerUpdateParentVariables
	>({
		mutationFn: (variables: DatasetControllerUpdateParentVariables) =>
			fetchDatasetControllerUpdateParent({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type DatasetControllerUpdateTagsPathParams = {
	appId: string
	id: string
}

export type DatasetControllerUpdateTagsError = Fetcher.ErrorWrapper<undefined>

export type DatasetControllerUpdateTagsRequestBody = string[]

export type DatasetControllerUpdateTagsVariables = {
	body?: DatasetControllerUpdateTagsRequestBody
	pathParams: DatasetControllerUpdateTagsPathParams
} & RepositoryContext['fetcherOptions']

export const fetchDatasetControllerUpdateTags = (
	variables: DatasetControllerUpdateTagsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Dataset,
		DatasetControllerUpdateTagsError,
		DatasetControllerUpdateTagsRequestBody,
		{},
		{},
		DatasetControllerUpdateTagsPathParams
	>({
		url: '/api/v2/assets/{appId}/dataset/{id}/tags',
		method: 'put',
		...variables,
		signal,
	})

export const useDatasetControllerUpdateTags = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Dataset,
			DatasetControllerUpdateTagsError,
			DatasetControllerUpdateTagsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.Dataset,
		DatasetControllerUpdateTagsError,
		DatasetControllerUpdateTagsVariables
	>({
		mutationFn: (variables: DatasetControllerUpdateTagsVariables) =>
			fetchDatasetControllerUpdateTags({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type DatasetControllerGetAssetsByNamePathParams = {
	appId: string
}

export type DatasetControllerGetAssetsByNameQueryParams = {
	name?: string
	populate?: 'parent'[]
}

export type DatasetControllerGetAssetsByNameError =
	Fetcher.ErrorWrapper<undefined>

export type DatasetControllerGetAssetsByNameResponse = Schemas.Dataset[]

export type DatasetControllerGetAssetsByNameVariables = {
	pathParams: DatasetControllerGetAssetsByNamePathParams
	queryParams?: DatasetControllerGetAssetsByNameQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchDatasetControllerGetAssetsByName = (
	variables: DatasetControllerGetAssetsByNameVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		DatasetControllerGetAssetsByNameResponse,
		DatasetControllerGetAssetsByNameError,
		undefined,
		{},
		DatasetControllerGetAssetsByNameQueryParams,
		DatasetControllerGetAssetsByNamePathParams
	>({
		url: '/api/v2/assets/{appId}/dataset/application/get-by-name',
		method: 'get',
		...variables,
		signal,
	})

export const useDatasetControllerGetAssetsByName = <
	TData = DatasetControllerGetAssetsByNameResponse
>(
	variables: DatasetControllerGetAssetsByNameVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			DatasetControllerGetAssetsByNameResponse,
			DatasetControllerGetAssetsByNameError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		DatasetControllerGetAssetsByNameResponse,
		DatasetControllerGetAssetsByNameError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/dataset/application/get-by-name',
			operationId: 'datasetControllerGetAssetsByName',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchDatasetControllerGetAssetsByName(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type FlowControllerFindAllTableDataPathParams = {
	appId: string
}

export type FlowControllerFindAllTableDataQueryParams = {
	filters?: Schemas.AssetsFilterDTO
	groupBy?: Record<string, any>
	sort?: 'parent' | 'name' | 'status' | 'location' | 'link-rule' | 'rule_type'
}

export type FlowControllerFindAllTableDataError =
	Fetcher.ErrorWrapper<undefined>

export type FlowControllerFindAllTableDataResponse =
	Schemas.AssetTableEntryDTO[]

export type FlowControllerFindAllTableDataVariables = {
	pathParams: FlowControllerFindAllTableDataPathParams
	queryParams?: FlowControllerFindAllTableDataQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchFlowControllerFindAllTableData = (
	variables: FlowControllerFindAllTableDataVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		FlowControllerFindAllTableDataResponse,
		FlowControllerFindAllTableDataError,
		undefined,
		{},
		FlowControllerFindAllTableDataQueryParams,
		FlowControllerFindAllTableDataPathParams
	>({
		url: '/api/v2/assets/{appId}/flow/application/table-data',
		method: 'get',
		...variables,
		signal,
	})

export const useFlowControllerFindAllTableData = <
	TData = FlowControllerFindAllTableDataResponse
>(
	variables: FlowControllerFindAllTableDataVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			FlowControllerFindAllTableDataResponse,
			FlowControllerFindAllTableDataError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		FlowControllerFindAllTableDataResponse,
		FlowControllerFindAllTableDataError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/flow/application/table-data',
			operationId: 'flowControllerFindAllTableData',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchFlowControllerFindAllTableData(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type FlowControllerFindAllBasePathParams = {
	appId: string
}

export type FlowControllerFindAllBaseError = Fetcher.ErrorWrapper<undefined>

export type FlowControllerFindAllBaseResponse = Schemas.Flow[]

export type FlowControllerFindAllBaseVariables = {
	pathParams: FlowControllerFindAllBasePathParams
} & RepositoryContext['fetcherOptions']

export const fetchFlowControllerFindAllBase = (
	variables: FlowControllerFindAllBaseVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		FlowControllerFindAllBaseResponse,
		FlowControllerFindAllBaseError,
		undefined,
		{},
		{},
		FlowControllerFindAllBasePathParams
	>({
		url: '/api/v2/assets/{appId}/flow',
		method: 'get',
		...variables,
		signal,
	})

export const useFlowControllerFindAllBase = <
	TData = FlowControllerFindAllBaseResponse
>(
	variables: FlowControllerFindAllBaseVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			FlowControllerFindAllBaseResponse,
			FlowControllerFindAllBaseError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		FlowControllerFindAllBaseResponse,
		FlowControllerFindAllBaseError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/flow',
			operationId: 'flowControllerFindAllBase',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchFlowControllerFindAllBase(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type FlowControllerCreatePathParams = {
	appId: string
}

export type FlowControllerCreateError = Fetcher.ErrorWrapper<undefined>

export type FlowControllerCreateVariables = {
	body: Schemas.CreateAssetDto
	pathParams: FlowControllerCreatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchFlowControllerCreate = (
	variables: FlowControllerCreateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Flow,
		FlowControllerCreateError,
		Schemas.CreateAssetDto,
		{},
		{},
		FlowControllerCreatePathParams
	>({
		url: '/api/v2/assets/{appId}/flow',
		method: 'post',
		...variables,
		signal,
	})

export const useFlowControllerCreate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Flow,
			FlowControllerCreateError,
			FlowControllerCreateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.Flow,
		FlowControllerCreateError,
		FlowControllerCreateVariables
	>({
		mutationFn: (variables: FlowControllerCreateVariables) =>
			fetchFlowControllerCreate({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type FlowControllerFindAllPathParams = {
	appId: string
}

export type FlowControllerFindAllQueryParams = {
	filters?: Schemas.AssetsFilterDTO
	sort?: 'parent' | 'name' | 'status' | 'location' | 'link-rule' | 'rule_type'
}

export type FlowControllerFindAllError = Fetcher.ErrorWrapper<undefined>

export type FlowControllerFindAllResponse =
	Schemas.FlowWithPopulatedParentAndTags[]

export type FlowControllerFindAllVariables = {
	pathParams: FlowControllerFindAllPathParams
	queryParams?: FlowControllerFindAllQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchFlowControllerFindAll = (
	variables: FlowControllerFindAllVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		FlowControllerFindAllResponse,
		FlowControllerFindAllError,
		undefined,
		{},
		FlowControllerFindAllQueryParams,
		FlowControllerFindAllPathParams
	>({
		url: '/api/v2/assets/{appId}/flow/application',
		method: 'get',
		...variables,
		signal,
	})

export const useFlowControllerFindAll = <TData = FlowControllerFindAllResponse>(
	variables: FlowControllerFindAllVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			FlowControllerFindAllResponse,
			FlowControllerFindAllError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		FlowControllerFindAllResponse,
		FlowControllerFindAllError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/flow/application',
			operationId: 'flowControllerFindAll',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchFlowControllerFindAll(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type FlowControllerFindOnePathParams = {
	appId: string
	id: string
}

export type FlowControllerFindOneError = Fetcher.ErrorWrapper<undefined>

export type FlowControllerFindOneVariables = {
	pathParams: FlowControllerFindOnePathParams
} & RepositoryContext['fetcherOptions']

export const fetchFlowControllerFindOne = (
	variables: FlowControllerFindOneVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Flow,
		FlowControllerFindOneError,
		undefined,
		{},
		{},
		FlowControllerFindOnePathParams
	>({
		url: '/api/v2/assets/{appId}/flow/{id}',
		method: 'get',
		...variables,
		signal,
	})

export const useFlowControllerFindOne = <TData = Schemas.Flow>(
	variables: FlowControllerFindOneVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.Flow,
			FlowControllerFindOneError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<Schemas.Flow, FlowControllerFindOneError, TData>(
		{
			queryKey: queryKeyFn({
				path: '/api/v2/assets/{appId}/flow/{id}',
				operationId: 'flowControllerFindOne',
				variables,
			}),
			queryFn: ({ signal }) =>
				fetchFlowControllerFindOne(
					{ ...fetcherOptions, ...variables },
					signal
				),
			...options,
			...queryOptions,
		}
	)
}

export type FlowControllerUpdatePathParams = {
	appId: string
	id: string
}

export type FlowControllerUpdateError = Fetcher.ErrorWrapper<undefined>

export type FlowControllerUpdateVariables = {
	body: Schemas.UpdateAssetDto
	pathParams: FlowControllerUpdatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchFlowControllerUpdate = (
	variables: FlowControllerUpdateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Flow,
		FlowControllerUpdateError,
		Schemas.UpdateAssetDto,
		{},
		{},
		FlowControllerUpdatePathParams
	>({
		url: '/api/v2/assets/{appId}/flow/{id}',
		method: 'put',
		...variables,
		signal,
	})

export const useFlowControllerUpdate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Flow,
			FlowControllerUpdateError,
			FlowControllerUpdateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.Flow,
		FlowControllerUpdateError,
		FlowControllerUpdateVariables
	>({
		mutationFn: (variables: FlowControllerUpdateVariables) =>
			fetchFlowControllerUpdate({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type FlowControllerRemovePathParams = {
	appId: string
	id: string
}

export type FlowControllerRemoveError = Fetcher.ErrorWrapper<undefined>

export type FlowControllerRemoveVariables = {
	pathParams: FlowControllerRemovePathParams
} & RepositoryContext['fetcherOptions']

export const fetchFlowControllerRemove = (
	variables: FlowControllerRemoveVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		string,
		FlowControllerRemoveError,
		undefined,
		{},
		{},
		FlowControllerRemovePathParams
	>({
		url: '/api/v2/assets/{appId}/flow/{id}',
		method: 'delete',
		...variables,
		signal,
	})

export const useFlowControllerRemove = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			string,
			FlowControllerRemoveError,
			FlowControllerRemoveVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		string,
		FlowControllerRemoveError,
		FlowControllerRemoveVariables
	>({
		mutationFn: (variables: FlowControllerRemoveVariables) =>
			fetchFlowControllerRemove({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type FlowControllerFindReferencingPathParams = {
	appId: string
	id: string
}

export type FlowControllerFindReferencingError = Fetcher.ErrorWrapper<undefined>

export type FlowControllerFindReferencingResponse = Schemas.Flow[]

export type FlowControllerFindReferencingVariables = {
	pathParams: FlowControllerFindReferencingPathParams
} & RepositoryContext['fetcherOptions']

export const fetchFlowControllerFindReferencing = (
	variables: FlowControllerFindReferencingVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		FlowControllerFindReferencingResponse,
		FlowControllerFindReferencingError,
		undefined,
		{},
		{},
		FlowControllerFindReferencingPathParams
	>({
		url: '/api/v2/assets/{appId}/flow/{id}/referencing',
		method: 'get',
		...variables,
		signal,
	})

export const useFlowControllerFindReferencing = <
	TData = FlowControllerFindReferencingResponse
>(
	variables: FlowControllerFindReferencingVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			FlowControllerFindReferencingResponse,
			FlowControllerFindReferencingError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		FlowControllerFindReferencingResponse,
		FlowControllerFindReferencingError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/flow/{id}/referencing',
			operationId: 'flowControllerFindReferencing',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchFlowControllerFindReferencing(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type FlowControllerImportPathParams = {
	appId: string
}

export type FlowControllerImportError = Fetcher.ErrorWrapper<undefined>

export type FlowControllerImportVariables = {
	body: Schemas.ImportAssetDto
	pathParams: FlowControllerImportPathParams
} & RepositoryContext['fetcherOptions']

export const fetchFlowControllerImport = (
	variables: FlowControllerImportVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.ImportResponseDto,
		FlowControllerImportError,
		Schemas.ImportAssetDto,
		{},
		{},
		FlowControllerImportPathParams
	>({
		url: '/api/v2/assets/{appId}/flow/import',
		method: 'post',
		...variables,
		signal,
	})

export const useFlowControllerImport = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.ImportResponseDto,
			FlowControllerImportError,
			FlowControllerImportVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.ImportResponseDto,
		FlowControllerImportError,
		FlowControllerImportVariables
	>({
		mutationFn: (variables: FlowControllerImportVariables) =>
			fetchFlowControllerImport({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type FlowControllerUpdateParentPathParams = {
	appId: string
	id: string
}

export type FlowControllerUpdateParentError = Fetcher.ErrorWrapper<undefined>

export type FlowControllerUpdateParentVariables = {
	body: Schemas.UpdateAssetParentDTO
	pathParams: FlowControllerUpdateParentPathParams
} & RepositoryContext['fetcherOptions']

export const fetchFlowControllerUpdateParent = (
	variables: FlowControllerUpdateParentVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Flow,
		FlowControllerUpdateParentError,
		Schemas.UpdateAssetParentDTO,
		{},
		{},
		FlowControllerUpdateParentPathParams
	>({
		url: '/api/v2/assets/{appId}/flow/{id}/updateParent',
		method: 'put',
		...variables,
		signal,
	})

export const useFlowControllerUpdateParent = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Flow,
			FlowControllerUpdateParentError,
			FlowControllerUpdateParentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.Flow,
		FlowControllerUpdateParentError,
		FlowControllerUpdateParentVariables
	>({
		mutationFn: (variables: FlowControllerUpdateParentVariables) =>
			fetchFlowControllerUpdateParent({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type FlowControllerUpdateTagsPathParams = {
	appId: string
	id: string
}

export type FlowControllerUpdateTagsError = Fetcher.ErrorWrapper<undefined>

export type FlowControllerUpdateTagsRequestBody = string[]

export type FlowControllerUpdateTagsVariables = {
	body?: FlowControllerUpdateTagsRequestBody
	pathParams: FlowControllerUpdateTagsPathParams
} & RepositoryContext['fetcherOptions']

export const fetchFlowControllerUpdateTags = (
	variables: FlowControllerUpdateTagsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.Flow,
		FlowControllerUpdateTagsError,
		FlowControllerUpdateTagsRequestBody,
		{},
		{},
		FlowControllerUpdateTagsPathParams
	>({
		url: '/api/v2/assets/{appId}/flow/{id}/tags',
		method: 'put',
		...variables,
		signal,
	})

export const useFlowControllerUpdateTags = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Flow,
			FlowControllerUpdateTagsError,
			FlowControllerUpdateTagsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.Flow,
		FlowControllerUpdateTagsError,
		FlowControllerUpdateTagsVariables
	>({
		mutationFn: (variables: FlowControllerUpdateTagsVariables) =>
			fetchFlowControllerUpdateTags({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type FlowControllerGetAssetsByNamePathParams = {
	appId: string
}

export type FlowControllerGetAssetsByNameQueryParams = {
	name?: string
	populate?: 'parent'[]
}

export type FlowControllerGetAssetsByNameError = Fetcher.ErrorWrapper<undefined>

export type FlowControllerGetAssetsByNameResponse = Schemas.Flow[]

export type FlowControllerGetAssetsByNameVariables = {
	pathParams: FlowControllerGetAssetsByNamePathParams
	queryParams?: FlowControllerGetAssetsByNameQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchFlowControllerGetAssetsByName = (
	variables: FlowControllerGetAssetsByNameVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		FlowControllerGetAssetsByNameResponse,
		FlowControllerGetAssetsByNameError,
		undefined,
		{},
		FlowControllerGetAssetsByNameQueryParams,
		FlowControllerGetAssetsByNamePathParams
	>({
		url: '/api/v2/assets/{appId}/flow/application/get-by-name',
		method: 'get',
		...variables,
		signal,
	})

export const useFlowControllerGetAssetsByName = <
	TData = FlowControllerGetAssetsByNameResponse
>(
	variables: FlowControllerGetAssetsByNameVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			FlowControllerGetAssetsByNameResponse,
			FlowControllerGetAssetsByNameError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		FlowControllerGetAssetsByNameResponse,
		FlowControllerGetAssetsByNameError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/flow/application/get-by-name',
			operationId: 'flowControllerGetAssetsByName',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchFlowControllerGetAssetsByName(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunTemplateControllerFindAllTableDataPathParams = {
	appId: string
}

export type RunTemplateControllerFindAllTableDataQueryParams = {
	filters?: Schemas.AssetsFilterDTO
	groupBy?: Record<string, any>
	sort?: 'parent' | 'name' | 'status' | 'location' | 'link-rule' | 'rule_type'
}

export type RunTemplateControllerFindAllTableDataError =
	Fetcher.ErrorWrapper<undefined>

export type RunTemplateControllerFindAllTableDataResponse =
	Schemas.AssetTableEntryDTO[]

export type RunTemplateControllerFindAllTableDataVariables = {
	pathParams: RunTemplateControllerFindAllTableDataPathParams
	queryParams?: RunTemplateControllerFindAllTableDataQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchRunTemplateControllerFindAllTableData = (
	variables: RunTemplateControllerFindAllTableDataVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		RunTemplateControllerFindAllTableDataResponse,
		RunTemplateControllerFindAllTableDataError,
		undefined,
		{},
		RunTemplateControllerFindAllTableDataQueryParams,
		RunTemplateControllerFindAllTableDataPathParams
	>({
		url: '/api/v2/assets/{appId}/run-template/application/table-data',
		method: 'get',
		...variables,
		signal,
	})

export const useRunTemplateControllerFindAllTableData = <
	TData = RunTemplateControllerFindAllTableDataResponse
>(
	variables: RunTemplateControllerFindAllTableDataVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			RunTemplateControllerFindAllTableDataResponse,
			RunTemplateControllerFindAllTableDataError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		RunTemplateControllerFindAllTableDataResponse,
		RunTemplateControllerFindAllTableDataError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/run-template/application/table-data',
			operationId: 'runTemplateControllerFindAllTableData',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunTemplateControllerFindAllTableData(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunTemplateControllerFindAllBasePathParams = {
	appId: string
}

export type RunTemplateControllerFindAllBaseError =
	Fetcher.ErrorWrapper<undefined>

export type RunTemplateControllerFindAllBaseResponse = Schemas.RunTemplate[]

export type RunTemplateControllerFindAllBaseVariables = {
	pathParams: RunTemplateControllerFindAllBasePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunTemplateControllerFindAllBase = (
	variables: RunTemplateControllerFindAllBaseVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		RunTemplateControllerFindAllBaseResponse,
		RunTemplateControllerFindAllBaseError,
		undefined,
		{},
		{},
		RunTemplateControllerFindAllBasePathParams
	>({
		url: '/api/v2/assets/{appId}/run-template',
		method: 'get',
		...variables,
		signal,
	})

export const useRunTemplateControllerFindAllBase = <
	TData = RunTemplateControllerFindAllBaseResponse
>(
	variables: RunTemplateControllerFindAllBaseVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			RunTemplateControllerFindAllBaseResponse,
			RunTemplateControllerFindAllBaseError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		RunTemplateControllerFindAllBaseResponse,
		RunTemplateControllerFindAllBaseError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/run-template',
			operationId: 'runTemplateControllerFindAllBase',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunTemplateControllerFindAllBase(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunTemplateControllerCreatePathParams = {
	appId: string
}

export type RunTemplateControllerCreateError = Fetcher.ErrorWrapper<undefined>

export type RunTemplateControllerCreateVariables = {
	body: Schemas.CreateAssetDto
	pathParams: RunTemplateControllerCreatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunTemplateControllerCreate = (
	variables: RunTemplateControllerCreateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RunTemplate,
		RunTemplateControllerCreateError,
		Schemas.CreateAssetDto,
		{},
		{},
		RunTemplateControllerCreatePathParams
	>({
		url: '/api/v2/assets/{appId}/run-template',
		method: 'post',
		...variables,
		signal,
	})

export const useRunTemplateControllerCreate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.RunTemplate,
			RunTemplateControllerCreateError,
			RunTemplateControllerCreateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.RunTemplate,
		RunTemplateControllerCreateError,
		RunTemplateControllerCreateVariables
	>({
		mutationFn: (variables: RunTemplateControllerCreateVariables) =>
			fetchRunTemplateControllerCreate({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type RunTemplateControllerFindAllPathParams = {
	appId: string
}

export type RunTemplateControllerFindAllQueryParams = {
	filters?: Schemas.AssetsFilterDTO
	sort?: 'parent' | 'name' | 'status' | 'location' | 'link-rule' | 'rule_type'
}

export type RunTemplateControllerFindAllError = Fetcher.ErrorWrapper<undefined>

export type RunTemplateControllerFindAllResponse =
	Schemas.RunTemplateWithPopulatedParentAndTags[]

export type RunTemplateControllerFindAllVariables = {
	pathParams: RunTemplateControllerFindAllPathParams
	queryParams?: RunTemplateControllerFindAllQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchRunTemplateControllerFindAll = (
	variables: RunTemplateControllerFindAllVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		RunTemplateControllerFindAllResponse,
		RunTemplateControllerFindAllError,
		undefined,
		{},
		RunTemplateControllerFindAllQueryParams,
		RunTemplateControllerFindAllPathParams
	>({
		url: '/api/v2/assets/{appId}/run-template/application',
		method: 'get',
		...variables,
		signal,
	})

export const useRunTemplateControllerFindAll = <
	TData = RunTemplateControllerFindAllResponse
>(
	variables: RunTemplateControllerFindAllVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			RunTemplateControllerFindAllResponse,
			RunTemplateControllerFindAllError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		RunTemplateControllerFindAllResponse,
		RunTemplateControllerFindAllError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/run-template/application',
			operationId: 'runTemplateControllerFindAll',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunTemplateControllerFindAll(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunTemplateControllerFindOnePathParams = {
	appId: string
	id: string
}

export type RunTemplateControllerFindOneError = Fetcher.ErrorWrapper<undefined>

export type RunTemplateControllerFindOneVariables = {
	pathParams: RunTemplateControllerFindOnePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunTemplateControllerFindOne = (
	variables: RunTemplateControllerFindOneVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RunTemplate,
		RunTemplateControllerFindOneError,
		undefined,
		{},
		{},
		RunTemplateControllerFindOnePathParams
	>({
		url: '/api/v2/assets/{appId}/run-template/{id}',
		method: 'get',
		...variables,
		signal,
	})

export const useRunTemplateControllerFindOne = <TData = Schemas.RunTemplate>(
	variables: RunTemplateControllerFindOneVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RunTemplate,
			RunTemplateControllerFindOneError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.RunTemplate,
		RunTemplateControllerFindOneError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/run-template/{id}',
			operationId: 'runTemplateControllerFindOne',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunTemplateControllerFindOne(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunTemplateControllerUpdatePathParams = {
	appId: string
	id: string
}

export type RunTemplateControllerUpdateError = Fetcher.ErrorWrapper<undefined>

export type RunTemplateControllerUpdateVariables = {
	body: Schemas.UpdateAssetDto
	pathParams: RunTemplateControllerUpdatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunTemplateControllerUpdate = (
	variables: RunTemplateControllerUpdateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RunTemplate,
		RunTemplateControllerUpdateError,
		Schemas.UpdateAssetDto,
		{},
		{},
		RunTemplateControllerUpdatePathParams
	>({
		url: '/api/v2/assets/{appId}/run-template/{id}',
		method: 'put',
		...variables,
		signal,
	})

export const useRunTemplateControllerUpdate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.RunTemplate,
			RunTemplateControllerUpdateError,
			RunTemplateControllerUpdateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.RunTemplate,
		RunTemplateControllerUpdateError,
		RunTemplateControllerUpdateVariables
	>({
		mutationFn: (variables: RunTemplateControllerUpdateVariables) =>
			fetchRunTemplateControllerUpdate({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type RunTemplateControllerRemovePathParams = {
	appId: string
	id: string
}

export type RunTemplateControllerRemoveError = Fetcher.ErrorWrapper<undefined>

export type RunTemplateControllerRemoveVariables = {
	pathParams: RunTemplateControllerRemovePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunTemplateControllerRemove = (
	variables: RunTemplateControllerRemoveVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		string,
		RunTemplateControllerRemoveError,
		undefined,
		{},
		{},
		RunTemplateControllerRemovePathParams
	>({
		url: '/api/v2/assets/{appId}/run-template/{id}',
		method: 'delete',
		...variables,
		signal,
	})

export const useRunTemplateControllerRemove = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			string,
			RunTemplateControllerRemoveError,
			RunTemplateControllerRemoveVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		string,
		RunTemplateControllerRemoveError,
		RunTemplateControllerRemoveVariables
	>({
		mutationFn: (variables: RunTemplateControllerRemoveVariables) =>
			fetchRunTemplateControllerRemove({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type RunTemplateControllerUpdateParentPathParams = {
	appId: string
	id: string
}

export type RunTemplateControllerUpdateParentError =
	Fetcher.ErrorWrapper<undefined>

export type RunTemplateControllerUpdateParentVariables = {
	body: Schemas.UpdateAssetParentDTO
	pathParams: RunTemplateControllerUpdateParentPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunTemplateControllerUpdateParent = (
	variables: RunTemplateControllerUpdateParentVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RunTemplate,
		RunTemplateControllerUpdateParentError,
		Schemas.UpdateAssetParentDTO,
		{},
		{},
		RunTemplateControllerUpdateParentPathParams
	>({
		url: '/api/v2/assets/{appId}/run-template/{id}/updateParent',
		method: 'put',
		...variables,
		signal,
	})

export const useRunTemplateControllerUpdateParent = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.RunTemplate,
			RunTemplateControllerUpdateParentError,
			RunTemplateControllerUpdateParentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.RunTemplate,
		RunTemplateControllerUpdateParentError,
		RunTemplateControllerUpdateParentVariables
	>({
		mutationFn: (variables: RunTemplateControllerUpdateParentVariables) =>
			fetchRunTemplateControllerUpdateParent({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type RunTemplateControllerUpdateTagsPathParams = {
	appId: string
	id: string
}

export type RunTemplateControllerUpdateTagsError =
	Fetcher.ErrorWrapper<undefined>

export type RunTemplateControllerUpdateTagsRequestBody = string[]

export type RunTemplateControllerUpdateTagsVariables = {
	body?: RunTemplateControllerUpdateTagsRequestBody
	pathParams: RunTemplateControllerUpdateTagsPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunTemplateControllerUpdateTags = (
	variables: RunTemplateControllerUpdateTagsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RunTemplate,
		RunTemplateControllerUpdateTagsError,
		RunTemplateControllerUpdateTagsRequestBody,
		{},
		{},
		RunTemplateControllerUpdateTagsPathParams
	>({
		url: '/api/v2/assets/{appId}/run-template/{id}/tags',
		method: 'put',
		...variables,
		signal,
	})

export const useRunTemplateControllerUpdateTags = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.RunTemplate,
			RunTemplateControllerUpdateTagsError,
			RunTemplateControllerUpdateTagsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.RunTemplate,
		RunTemplateControllerUpdateTagsError,
		RunTemplateControllerUpdateTagsVariables
	>({
		mutationFn: (variables: RunTemplateControllerUpdateTagsVariables) =>
			fetchRunTemplateControllerUpdateTags({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type RunTemplateControllerGetAssetsByNamePathParams = {
	appId: string
}

export type RunTemplateControllerGetAssetsByNameQueryParams = {
	name?: string
	populate?: 'parent'[]
}

export type RunTemplateControllerGetAssetsByNameError =
	Fetcher.ErrorWrapper<undefined>

export type RunTemplateControllerGetAssetsByNameResponse = Schemas.RunTemplate[]

export type RunTemplateControllerGetAssetsByNameVariables = {
	pathParams: RunTemplateControllerGetAssetsByNamePathParams
	queryParams?: RunTemplateControllerGetAssetsByNameQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchRunTemplateControllerGetAssetsByName = (
	variables: RunTemplateControllerGetAssetsByNameVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		RunTemplateControllerGetAssetsByNameResponse,
		RunTemplateControllerGetAssetsByNameError,
		undefined,
		{},
		RunTemplateControllerGetAssetsByNameQueryParams,
		RunTemplateControllerGetAssetsByNamePathParams
	>({
		url: '/api/v2/assets/{appId}/run-template/application/get-by-name',
		method: 'get',
		...variables,
		signal,
	})

export const useRunTemplateControllerGetAssetsByName = <
	TData = RunTemplateControllerGetAssetsByNameResponse
>(
	variables: RunTemplateControllerGetAssetsByNameVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			RunTemplateControllerGetAssetsByNameResponse,
			RunTemplateControllerGetAssetsByNameError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		RunTemplateControllerGetAssetsByNameResponse,
		RunTemplateControllerGetAssetsByNameError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/run-template/application/get-by-name',
			operationId: 'runTemplateControllerGetAssetsByName',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunTemplateControllerGetAssetsByName(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type FormSpecControllerFindAllTableDataPathParams = {
	appId: string
}

export type FormSpecControllerFindAllTableDataQueryParams = {
	filters?: Schemas.AssetsFilterDTO
	groupBy?: Record<string, any>
	sort?: 'parent' | 'name' | 'status' | 'location' | 'link-rule' | 'rule_type'
}

export type FormSpecControllerFindAllTableDataError =
	Fetcher.ErrorWrapper<undefined>

export type FormSpecControllerFindAllTableDataResponse =
	Schemas.AssetTableEntryDTO[]

export type FormSpecControllerFindAllTableDataVariables = {
	pathParams: FormSpecControllerFindAllTableDataPathParams
	queryParams?: FormSpecControllerFindAllTableDataQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchFormSpecControllerFindAllTableData = (
	variables: FormSpecControllerFindAllTableDataVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		FormSpecControllerFindAllTableDataResponse,
		FormSpecControllerFindAllTableDataError,
		undefined,
		{},
		FormSpecControllerFindAllTableDataQueryParams,
		FormSpecControllerFindAllTableDataPathParams
	>({
		url: '/api/v2/assets/{appId}/form-spec/application/table-data',
		method: 'get',
		...variables,
		signal,
	})

export const useFormSpecControllerFindAllTableData = <
	TData = FormSpecControllerFindAllTableDataResponse
>(
	variables: FormSpecControllerFindAllTableDataVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			FormSpecControllerFindAllTableDataResponse,
			FormSpecControllerFindAllTableDataError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		FormSpecControllerFindAllTableDataResponse,
		FormSpecControllerFindAllTableDataError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/form-spec/application/table-data',
			operationId: 'formSpecControllerFindAllTableData',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchFormSpecControllerFindAllTableData(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type FormSpecControllerFindAllBasePathParams = {
	appId: string
}

export type FormSpecControllerFindAllBaseError = Fetcher.ErrorWrapper<undefined>

export type FormSpecControllerFindAllBaseResponse = Schemas.FormSpec[]

export type FormSpecControllerFindAllBaseVariables = {
	pathParams: FormSpecControllerFindAllBasePathParams
} & RepositoryContext['fetcherOptions']

export const fetchFormSpecControllerFindAllBase = (
	variables: FormSpecControllerFindAllBaseVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		FormSpecControllerFindAllBaseResponse,
		FormSpecControllerFindAllBaseError,
		undefined,
		{},
		{},
		FormSpecControllerFindAllBasePathParams
	>({
		url: '/api/v2/assets/{appId}/form-spec',
		method: 'get',
		...variables,
		signal,
	})

export const useFormSpecControllerFindAllBase = <
	TData = FormSpecControllerFindAllBaseResponse
>(
	variables: FormSpecControllerFindAllBaseVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			FormSpecControllerFindAllBaseResponse,
			FormSpecControllerFindAllBaseError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		FormSpecControllerFindAllBaseResponse,
		FormSpecControllerFindAllBaseError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/form-spec',
			operationId: 'formSpecControllerFindAllBase',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchFormSpecControllerFindAllBase(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type FormSpecControllerCreatePathParams = {
	appId: string
}

export type FormSpecControllerCreateError = Fetcher.ErrorWrapper<undefined>

export type FormSpecControllerCreateVariables = {
	body: Schemas.CreateAssetDto
	pathParams: FormSpecControllerCreatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchFormSpecControllerCreate = (
	variables: FormSpecControllerCreateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.FormSpec,
		FormSpecControllerCreateError,
		Schemas.CreateAssetDto,
		{},
		{},
		FormSpecControllerCreatePathParams
	>({
		url: '/api/v2/assets/{appId}/form-spec',
		method: 'post',
		...variables,
		signal,
	})

export const useFormSpecControllerCreate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.FormSpec,
			FormSpecControllerCreateError,
			FormSpecControllerCreateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.FormSpec,
		FormSpecControllerCreateError,
		FormSpecControllerCreateVariables
	>({
		mutationFn: (variables: FormSpecControllerCreateVariables) =>
			fetchFormSpecControllerCreate({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type FormSpecControllerFindAllPathParams = {
	appId: string
}

export type FormSpecControllerFindAllQueryParams = {
	filters?: Schemas.AssetsFilterDTO
	sort?: 'parent' | 'name' | 'status' | 'location' | 'link-rule' | 'rule_type'
}

export type FormSpecControllerFindAllError = Fetcher.ErrorWrapper<undefined>

export type FormSpecControllerFindAllResponse =
	Schemas.FormSpecWithPopulatedParentAndTags[]

export type FormSpecControllerFindAllVariables = {
	pathParams: FormSpecControllerFindAllPathParams
	queryParams?: FormSpecControllerFindAllQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchFormSpecControllerFindAll = (
	variables: FormSpecControllerFindAllVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		FormSpecControllerFindAllResponse,
		FormSpecControllerFindAllError,
		undefined,
		{},
		FormSpecControllerFindAllQueryParams,
		FormSpecControllerFindAllPathParams
	>({
		url: '/api/v2/assets/{appId}/form-spec/application',
		method: 'get',
		...variables,
		signal,
	})

export const useFormSpecControllerFindAll = <
	TData = FormSpecControllerFindAllResponse
>(
	variables: FormSpecControllerFindAllVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			FormSpecControllerFindAllResponse,
			FormSpecControllerFindAllError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		FormSpecControllerFindAllResponse,
		FormSpecControllerFindAllError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/form-spec/application',
			operationId: 'formSpecControllerFindAll',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchFormSpecControllerFindAll(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type FormSpecControllerFindOnePathParams = {
	appId: string
	id: string
}

export type FormSpecControllerFindOneError = Fetcher.ErrorWrapper<undefined>

export type FormSpecControllerFindOneVariables = {
	pathParams: FormSpecControllerFindOnePathParams
} & RepositoryContext['fetcherOptions']

export const fetchFormSpecControllerFindOne = (
	variables: FormSpecControllerFindOneVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.FormSpec,
		FormSpecControllerFindOneError,
		undefined,
		{},
		{},
		FormSpecControllerFindOnePathParams
	>({
		url: '/api/v2/assets/{appId}/form-spec/{id}',
		method: 'get',
		...variables,
		signal,
	})

export const useFormSpecControllerFindOne = <TData = Schemas.FormSpec>(
	variables: FormSpecControllerFindOneVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.FormSpec,
			FormSpecControllerFindOneError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.FormSpec,
		FormSpecControllerFindOneError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/form-spec/{id}',
			operationId: 'formSpecControllerFindOne',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchFormSpecControllerFindOne(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type FormSpecControllerUpdatePathParams = {
	appId: string
	id: string
}

export type FormSpecControllerUpdateError = Fetcher.ErrorWrapper<undefined>

export type FormSpecControllerUpdateVariables = {
	body: Schemas.UpdateAssetDto
	pathParams: FormSpecControllerUpdatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchFormSpecControllerUpdate = (
	variables: FormSpecControllerUpdateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.FormSpec,
		FormSpecControllerUpdateError,
		Schemas.UpdateAssetDto,
		{},
		{},
		FormSpecControllerUpdatePathParams
	>({
		url: '/api/v2/assets/{appId}/form-spec/{id}',
		method: 'put',
		...variables,
		signal,
	})

export const useFormSpecControllerUpdate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.FormSpec,
			FormSpecControllerUpdateError,
			FormSpecControllerUpdateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.FormSpec,
		FormSpecControllerUpdateError,
		FormSpecControllerUpdateVariables
	>({
		mutationFn: (variables: FormSpecControllerUpdateVariables) =>
			fetchFormSpecControllerUpdate({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type FormSpecControllerRemovePathParams = {
	appId: string
	id: string
}

export type FormSpecControllerRemoveError = Fetcher.ErrorWrapper<undefined>

export type FormSpecControllerRemoveVariables = {
	pathParams: FormSpecControllerRemovePathParams
} & RepositoryContext['fetcherOptions']

export const fetchFormSpecControllerRemove = (
	variables: FormSpecControllerRemoveVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		string,
		FormSpecControllerRemoveError,
		undefined,
		{},
		{},
		FormSpecControllerRemovePathParams
	>({
		url: '/api/v2/assets/{appId}/form-spec/{id}',
		method: 'delete',
		...variables,
		signal,
	})

export const useFormSpecControllerRemove = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			string,
			FormSpecControllerRemoveError,
			FormSpecControllerRemoveVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		string,
		FormSpecControllerRemoveError,
		FormSpecControllerRemoveVariables
	>({
		mutationFn: (variables: FormSpecControllerRemoveVariables) =>
			fetchFormSpecControllerRemove({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type FormSpecControllerUpdateParentPathParams = {
	appId: string
	id: string
}

export type FormSpecControllerUpdateParentError =
	Fetcher.ErrorWrapper<undefined>

export type FormSpecControllerUpdateParentVariables = {
	body: Schemas.UpdateAssetParentDTO
	pathParams: FormSpecControllerUpdateParentPathParams
} & RepositoryContext['fetcherOptions']

export const fetchFormSpecControllerUpdateParent = (
	variables: FormSpecControllerUpdateParentVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.FormSpec,
		FormSpecControllerUpdateParentError,
		Schemas.UpdateAssetParentDTO,
		{},
		{},
		FormSpecControllerUpdateParentPathParams
	>({
		url: '/api/v2/assets/{appId}/form-spec/{id}/updateParent',
		method: 'put',
		...variables,
		signal,
	})

export const useFormSpecControllerUpdateParent = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.FormSpec,
			FormSpecControllerUpdateParentError,
			FormSpecControllerUpdateParentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.FormSpec,
		FormSpecControllerUpdateParentError,
		FormSpecControllerUpdateParentVariables
	>({
		mutationFn: (variables: FormSpecControllerUpdateParentVariables) =>
			fetchFormSpecControllerUpdateParent({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type FormSpecControllerUpdateTagsPathParams = {
	appId: string
	id: string
}

export type FormSpecControllerUpdateTagsError = Fetcher.ErrorWrapper<undefined>

export type FormSpecControllerUpdateTagsRequestBody = string[]

export type FormSpecControllerUpdateTagsVariables = {
	body?: FormSpecControllerUpdateTagsRequestBody
	pathParams: FormSpecControllerUpdateTagsPathParams
} & RepositoryContext['fetcherOptions']

export const fetchFormSpecControllerUpdateTags = (
	variables: FormSpecControllerUpdateTagsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.FormSpec,
		FormSpecControllerUpdateTagsError,
		FormSpecControllerUpdateTagsRequestBody,
		{},
		{},
		FormSpecControllerUpdateTagsPathParams
	>({
		url: '/api/v2/assets/{appId}/form-spec/{id}/tags',
		method: 'put',
		...variables,
		signal,
	})

export const useFormSpecControllerUpdateTags = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.FormSpec,
			FormSpecControllerUpdateTagsError,
			FormSpecControllerUpdateTagsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.FormSpec,
		FormSpecControllerUpdateTagsError,
		FormSpecControllerUpdateTagsVariables
	>({
		mutationFn: (variables: FormSpecControllerUpdateTagsVariables) =>
			fetchFormSpecControllerUpdateTags({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type FormSpecControllerGetAssetsByNamePathParams = {
	appId: string
}

export type FormSpecControllerGetAssetsByNameQueryParams = {
	name?: string
	populate?: 'parent'[]
}

export type FormSpecControllerGetAssetsByNameError =
	Fetcher.ErrorWrapper<undefined>

export type FormSpecControllerGetAssetsByNameResponse = Schemas.FormSpec[]

export type FormSpecControllerGetAssetsByNameVariables = {
	pathParams: FormSpecControllerGetAssetsByNamePathParams
	queryParams?: FormSpecControllerGetAssetsByNameQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchFormSpecControllerGetAssetsByName = (
	variables: FormSpecControllerGetAssetsByNameVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		FormSpecControllerGetAssetsByNameResponse,
		FormSpecControllerGetAssetsByNameError,
		undefined,
		{},
		FormSpecControllerGetAssetsByNameQueryParams,
		FormSpecControllerGetAssetsByNamePathParams
	>({
		url: '/api/v2/assets/{appId}/form-spec/application/get-by-name',
		method: 'get',
		...variables,
		signal,
	})

export const useFormSpecControllerGetAssetsByName = <
	TData = FormSpecControllerGetAssetsByNameResponse
>(
	variables: FormSpecControllerGetAssetsByNameVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			FormSpecControllerGetAssetsByNameResponse,
			FormSpecControllerGetAssetsByNameError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		FormSpecControllerGetAssetsByNameResponse,
		FormSpecControllerGetAssetsByNameError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/assets/{appId}/form-spec/application/get-by-name',
			operationId: 'formSpecControllerGetAssetsByName',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchFormSpecControllerGetAssetsByName(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type AuthControllerGetPermissionsError = Fetcher.ErrorWrapper<undefined>

export type AuthControllerGetPermissionsVariables =
	RepositoryContext['fetcherOptions']

export const fetchAuthControllerGetPermissions = (
	variables: AuthControllerGetPermissionsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedUser,
		AuthControllerGetPermissionsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/auth/whoami', method: 'get', ...variables, signal })

export const useAuthControllerGetPermissions = <
	TData = Schemas.TransformedUser
>(
	variables: AuthControllerGetPermissionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransformedUser,
			AuthControllerGetPermissionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.TransformedUser,
		AuthControllerGetPermissionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/auth/whoami',
			operationId: 'authControllerGetPermissions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchAuthControllerGetPermissions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type AuthControllerLogOutError = Fetcher.ErrorWrapper<undefined>

export type AuthControllerLogOutVariables = RepositoryContext['fetcherOptions']

export const fetchAuthControllerLogOut = (
	variables: AuthControllerLogOutVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		AuthControllerLogOutError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/auth/logout', method: 'post', ...variables, signal })

export const useAuthControllerLogOut = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			AuthControllerLogOutError,
			AuthControllerLogOutVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		AuthControllerLogOutError,
		AuthControllerLogOutVariables
	>({
		mutationFn: (variables: AuthControllerLogOutVariables) =>
			fetchAuthControllerLogOut({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type RunsControllerGetRunsError = Fetcher.ErrorWrapper<undefined>

export type RunsControllerGetRunsResponse = Schemas.RunMetadataDto[]

export type RunsControllerGetRunsVariables = RepositoryContext['fetcherOptions']

export const fetchRunsControllerGetRuns = (
	variables: RunsControllerGetRunsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		RunsControllerGetRunsResponse,
		RunsControllerGetRunsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/run', method: 'get', ...variables, signal })

export const useRunsControllerGetRuns = <TData = RunsControllerGetRunsResponse>(
	variables: RunsControllerGetRunsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			RunsControllerGetRunsResponse,
			RunsControllerGetRunsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		RunsControllerGetRunsResponse,
		RunsControllerGetRunsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/run',
			operationId: 'runsControllerGetRuns',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunsControllerGetRuns(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunsControllerGetVariantRunsPathParams = {
	id: string
}

export type RunsControllerGetVariantRunsQueryParams = {
	/**
	 * @minimum 1
	 * @default 50
	 */
	limit?: number
	/**
	 * @minimum 0
	 */
	offset?: number
}

export type RunsControllerGetVariantRunsError = Fetcher.ErrorWrapper<undefined>

export type RunsControllerGetVariantRunsVariables = {
	pathParams: RunsControllerGetVariantRunsPathParams
	queryParams?: RunsControllerGetVariantRunsQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsControllerGetVariantRuns = (
	variables: RunsControllerGetVariantRunsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.PaginatedRunDTO,
		RunsControllerGetVariantRunsError,
		undefined,
		{},
		RunsControllerGetVariantRunsQueryParams,
		RunsControllerGetVariantRunsPathParams
	>({ url: '/api/v2/run/{id}', method: 'get', ...variables, signal })

export const useRunsControllerGetVariantRuns = <
	TData = Schemas.PaginatedRunDTO
>(
	variables: RunsControllerGetVariantRunsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PaginatedRunDTO,
			RunsControllerGetVariantRunsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.PaginatedRunDTO,
		RunsControllerGetVariantRunsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/run/{id}',
			operationId: 'runsControllerGetVariantRuns',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunsControllerGetVariantRuns(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunsControllerHasRunsPathParams = {
	id: string
}

export type RunsControllerHasRunsError = Fetcher.ErrorWrapper<undefined>

export type RunsControllerHasRunsVariables = {
	pathParams: RunsControllerHasRunsPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsControllerHasRuns = (
	variables: RunsControllerHasRunsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		RunsControllerHasRunsError,
		undefined,
		{},
		{},
		RunsControllerHasRunsPathParams
	>({
		url: '/api/v2/run/variant/{id}/hasRuns',
		method: 'get',
		...variables,
		signal,
	})

export const useRunsControllerHasRuns = <TData = undefined>(
	variables: RunsControllerHasRunsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			undefined,
			RunsControllerHasRunsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<undefined, RunsControllerHasRunsError, TData>({
		queryKey: queryKeyFn({
			path: '/api/v2/run/variant/{id}/hasRuns',
			operationId: 'runsControllerHasRuns',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunsControllerHasRuns(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunsControllerGetCurrentPagePathParams = {
	runId: string
}

export type RunsControllerGetCurrentPageError = Fetcher.ErrorWrapper<undefined>

export type RunsControllerGetCurrentPageVariables = {
	pathParams: RunsControllerGetCurrentPagePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsControllerGetCurrentPage = (
	variables: RunsControllerGetCurrentPageVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.PageDTO,
		RunsControllerGetCurrentPageError,
		undefined,
		{},
		{},
		RunsControllerGetCurrentPagePathParams
	>({
		url: '/api/v2/run/{runId}/currentPage',
		method: 'get',
		...variables,
		signal,
	})

export const useRunsControllerGetCurrentPage = <TData = Schemas.PageDTO>(
	variables: RunsControllerGetCurrentPageVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PageDTO,
			RunsControllerGetCurrentPageError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.PageDTO,
		RunsControllerGetCurrentPageError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/run/{runId}/currentPage',
			operationId: 'runsControllerGetCurrentPage',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunsControllerGetCurrentPage(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunsControllerRemovePathParams = {
	variantId: string
}

export type RunsControllerRemoveError = Fetcher.ErrorWrapper<undefined>

export type RunsControllerRemoveVariables = {
	pathParams: RunsControllerRemovePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsControllerRemove = (
	variables: RunsControllerRemoveVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RemoveRunsResponseDTO,
		RunsControllerRemoveError,
		undefined,
		{},
		{},
		RunsControllerRemovePathParams
	>({
		url: '/api/v2/run/{variantId}',
		method: 'delete',
		...variables,
		signal,
	})

export const useRunsControllerRemove = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.RemoveRunsResponseDTO,
			RunsControllerRemoveError,
			RunsControllerRemoveVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.RemoveRunsResponseDTO,
		RunsControllerRemoveError,
		RunsControllerRemoveVariables
	>({
		mutationFn: (variables: RunsControllerRemoveVariables) =>
			fetchRunsControllerRemove({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type RunsControllerRunStoppedPathParams = {
	runId: string
}

export type RunsControllerRunStoppedError = Fetcher.ErrorWrapper<undefined>

export type RunsControllerRunStoppedVariables = {
	pathParams: RunsControllerRunStoppedPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsControllerRunStopped = (
	variables: RunsControllerRunStoppedVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		RunsControllerRunStoppedError,
		undefined,
		{},
		{},
		RunsControllerRunStoppedPathParams
	>({
		url: '/api/v2/run/{runId}/stopComplete',
		method: 'post',
		...variables,
		signal,
	})

export const useRunsControllerRunStopped = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			RunsControllerRunStoppedError,
			RunsControllerRunStoppedVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		RunsControllerRunStoppedError,
		RunsControllerRunStoppedVariables
	>({
		mutationFn: (variables: RunsControllerRunStoppedVariables) =>
			fetchRunsControllerRunStopped({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type RunsControllerPauseRunPathParams = {
	runId: string
	command: 'pause' | 'resume' | 'stop'
}

export type RunsControllerPauseRunError = Fetcher.ErrorWrapper<undefined>

export type RunsControllerPauseRunVariables = {
	pathParams: RunsControllerPauseRunPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsControllerPauseRun = (
	variables: RunsControllerPauseRunVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		RunsControllerPauseRunError,
		undefined,
		{},
		{},
		RunsControllerPauseRunPathParams
	>({
		url: '/api/v2/run/{runId}/{command}',
		method: 'post',
		...variables,
		signal,
	})

export const useRunsControllerPauseRun = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			RunsControllerPauseRunError,
			RunsControllerPauseRunVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		RunsControllerPauseRunError,
		RunsControllerPauseRunVariables
	>({
		mutationFn: (variables: RunsControllerPauseRunVariables) =>
			fetchRunsControllerPauseRun({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type RunParametersControllerGetAllError = Fetcher.ErrorWrapper<undefined>

export type RunParametersControllerGetAllVariables =
	RepositoryContext['fetcherOptions']

export const fetchRunParametersControllerGetAll = (
	variables: RunParametersControllerGetAllVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		RunParametersControllerGetAllError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/run/parameters', method: 'get', ...variables, signal })

export const useRunParametersControllerGetAll = <TData = undefined>(
	variables: RunParametersControllerGetAllVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			undefined,
			RunParametersControllerGetAllError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		undefined,
		RunParametersControllerGetAllError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/run/parameters',
			operationId: 'runParametersControllerGetAll',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunParametersControllerGetAll(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunParametersControllerCreateError = Fetcher.ErrorWrapper<undefined>

export type RunParametersControllerCreateVariables =
	RepositoryContext['fetcherOptions']

export const fetchRunParametersControllerCreate = (
	variables: RunParametersControllerCreateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		RunParametersControllerCreateError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/run/parameters', method: 'post', ...variables, signal })

export const useRunParametersControllerCreate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			RunParametersControllerCreateError,
			RunParametersControllerCreateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		RunParametersControllerCreateError,
		RunParametersControllerCreateVariables
	>({
		mutationFn: (variables: RunParametersControllerCreateVariables) =>
			fetchRunParametersControllerCreate({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type RunParametersControllerGetPathParams = {
	id: string
}

export type RunParametersControllerGetError = Fetcher.ErrorWrapper<undefined>

export type RunParametersControllerGetVariables = {
	pathParams: RunParametersControllerGetPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunParametersControllerGet = (
	variables: RunParametersControllerGetVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		RunParametersControllerGetError,
		undefined,
		{},
		{},
		RunParametersControllerGetPathParams
	>({
		url: '/api/v2/run/parameters/{id}',
		method: 'get',
		...variables,
		signal,
	})

export const useRunParametersControllerGet = <TData = undefined>(
	variables: RunParametersControllerGetVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			undefined,
			RunParametersControllerGetError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		undefined,
		RunParametersControllerGetError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/run/parameters/{id}',
			operationId: 'runParametersControllerGet',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunParametersControllerGet(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunParametersControllerUpdatePathParams = {
	id: string
}

export type RunParametersControllerUpdateError = Fetcher.ErrorWrapper<undefined>

export type RunParametersControllerUpdateVariables = {
	pathParams: RunParametersControllerUpdatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunParametersControllerUpdate = (
	variables: RunParametersControllerUpdateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		RunParametersControllerUpdateError,
		undefined,
		{},
		{},
		RunParametersControllerUpdatePathParams
	>({
		url: '/api/v2/run/parameters/{id}',
		method: 'put',
		...variables,
		signal,
	})

export const useRunParametersControllerUpdate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			RunParametersControllerUpdateError,
			RunParametersControllerUpdateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		RunParametersControllerUpdateError,
		RunParametersControllerUpdateVariables
	>({
		mutationFn: (variables: RunParametersControllerUpdateVariables) =>
			fetchRunParametersControllerUpdate({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type RunParametersControllerDeletePathParams = {
	id: string
}

export type RunParametersControllerDeleteError = Fetcher.ErrorWrapper<undefined>

export type RunParametersControllerDeleteVariables = {
	pathParams: RunParametersControllerDeletePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunParametersControllerDelete = (
	variables: RunParametersControllerDeleteVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		RunParametersControllerDeleteError,
		undefined,
		{},
		{},
		RunParametersControllerDeletePathParams
	>({
		url: '/api/v2/run/parameters/{id}/delete',
		method: 'post',
		...variables,
		signal,
	})

export const useRunParametersControllerDelete = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			RunParametersControllerDeleteError,
			RunParametersControllerDeleteVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		RunParametersControllerDeleteError,
		RunParametersControllerDeleteVariables
	>({
		mutationFn: (variables: RunParametersControllerDeleteVariables) =>
			fetchRunParametersControllerDelete({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type RunsLogControllerGetRunLogPathParams = {
	runId: string
}

export type RunsLogControllerGetRunLogError = Fetcher.ErrorWrapper<{
	status: 404
	payload: Schemas.RunLogDTO
}>

export type RunsLogControllerGetRunLogVariables = {
	pathParams: RunsLogControllerGetRunLogPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsLogControllerGetRunLog = (
	variables: RunsLogControllerGetRunLogVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RunLogDTO,
		RunsLogControllerGetRunLogError,
		undefined,
		{},
		{},
		RunsLogControllerGetRunLogPathParams
	>({ url: '/api/v2/run/{runId}/log', method: 'get', ...variables, signal })

export const useRunsLogControllerGetRunLog = <TData = Schemas.RunLogDTO>(
	variables: RunsLogControllerGetRunLogVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RunLogDTO,
			RunsLogControllerGetRunLogError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.RunLogDTO,
		RunsLogControllerGetRunLogError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/run/{runId}/log',
			operationId: 'runsLogControllerGetRunLog',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunsLogControllerGetRunLog(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunsLogControllerUpdateRunLogPathParams = {
	runId: string
}

export type RunsLogControllerUpdateRunLogError = Fetcher.ErrorWrapper<undefined>

export type RunsLogControllerUpdateRunLogVariables = {
	body: Schemas.RunLogDTO
	pathParams: RunsLogControllerUpdateRunLogPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsLogControllerUpdateRunLog = (
	variables: RunsLogControllerUpdateRunLogVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RunLogDTO,
		RunsLogControllerUpdateRunLogError,
		Schemas.RunLogDTO,
		{},
		{},
		RunsLogControllerUpdateRunLogPathParams
	>({ url: '/api/v2/run/{runId}/log', method: 'put', ...variables, signal })

export const useRunsLogControllerUpdateRunLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.RunLogDTO,
			RunsLogControllerUpdateRunLogError,
			RunsLogControllerUpdateRunLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.RunLogDTO,
		RunsLogControllerUpdateRunLogError,
		RunsLogControllerUpdateRunLogVariables
	>({
		mutationFn: (variables: RunsLogControllerUpdateRunLogVariables) =>
			fetchRunsLogControllerUpdateRunLog({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type RunsLogControllerGetLogsError = Fetcher.ErrorWrapper<undefined>

export type RunsLogControllerGetLogsVariables =
	RepositoryContext['fetcherOptions']

export const fetchRunsLogControllerGetLogs = (
	variables: RunsLogControllerGetLogsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RunStatusDTO,
		RunsLogControllerGetLogsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/run/log', method: 'get', ...variables, signal })

export const useRunsLogControllerGetLogs = <TData = Schemas.RunStatusDTO>(
	variables: RunsLogControllerGetLogsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RunStatusDTO,
			RunsLogControllerGetLogsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.RunStatusDTO,
		RunsLogControllerGetLogsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/run/log',
			operationId: 'runsLogControllerGetLogs',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunsLogControllerGetLogs(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunsLogControllerAppendRunLogPathParams = {
	runId: string
}

export type RunsLogControllerAppendRunLogError = Fetcher.ErrorWrapper<undefined>

export type RunsLogControllerAppendRunLogVariables = {
	body: Schemas.AppendRunLogRequestDTO
	pathParams: RunsLogControllerAppendRunLogPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsLogControllerAppendRunLog = (
	variables: RunsLogControllerAppendRunLogVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RunLogDTO,
		RunsLogControllerAppendRunLogError,
		Schemas.AppendRunLogRequestDTO,
		{},
		{},
		RunsLogControllerAppendRunLogPathParams
	>({
		url: '/api/v2/run/{runId}/log/append',
		method: 'post',
		...variables,
		signal,
	})

export const useRunsLogControllerAppendRunLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.RunLogDTO,
			RunsLogControllerAppendRunLogError,
			RunsLogControllerAppendRunLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.RunLogDTO,
		RunsLogControllerAppendRunLogError,
		RunsLogControllerAppendRunLogVariables
	>({
		mutationFn: (variables: RunsLogControllerAppendRunLogVariables) =>
			fetchRunsLogControllerAppendRunLog({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type RunsStatusControllerGetStatusPathParams = {
	id: string
}

export type RunsStatusControllerGetStatusError = Fetcher.ErrorWrapper<undefined>

export type RunsStatusControllerGetStatusVariables = {
	pathParams: RunsStatusControllerGetStatusPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsStatusControllerGetStatus = (
	variables: RunsStatusControllerGetStatusVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RunStatusDTO,
		RunsStatusControllerGetStatusError,
		undefined,
		{},
		{},
		RunsStatusControllerGetStatusPathParams
	>({ url: '/api/v2/run/{id}/status', method: 'get', ...variables, signal })

export const useRunsStatusControllerGetStatus = <TData = Schemas.RunStatusDTO>(
	variables: RunsStatusControllerGetStatusVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RunStatusDTO,
			RunsStatusControllerGetStatusError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.RunStatusDTO,
		RunsStatusControllerGetStatusError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/run/{id}/status',
			operationId: 'runsStatusControllerGetStatus',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunsStatusControllerGetStatus(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunsStatusControllerUpdateStatusPathParams = {
	id: string
}

export type RunsStatusControllerUpdateStatusError =
	Fetcher.ErrorWrapper<undefined>

export type RunsStatusControllerUpdateStatusVariables = {
	body: Schemas.RunStatusDTO
	pathParams: RunsStatusControllerUpdateStatusPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsStatusControllerUpdateStatus = (
	variables: RunsStatusControllerUpdateStatusVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RunStatusDTO,
		RunsStatusControllerUpdateStatusError,
		Schemas.RunStatusDTO,
		{},
		{},
		RunsStatusControllerUpdateStatusPathParams
	>({ url: '/api/v2/run/{id}/status', method: 'put', ...variables, signal })

export const useRunsStatusControllerUpdateStatus = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.RunStatusDTO,
			RunsStatusControllerUpdateStatusError,
			RunsStatusControllerUpdateStatusVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.RunStatusDTO,
		RunsStatusControllerUpdateStatusError,
		RunsStatusControllerUpdateStatusVariables
	>({
		mutationFn: (variables: RunsStatusControllerUpdateStatusVariables) =>
			fetchRunsStatusControllerUpdateStatus({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type RunsWorkQueueControllerGetPathParams = {
	runId: string
}

export type RunsWorkQueueControllerGetError = Fetcher.ErrorWrapper<{
	status: 404
	payload: Schemas.WorkQueueDTO
}>

export type RunsWorkQueueControllerGetVariables = {
	pathParams: RunsWorkQueueControllerGetPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsWorkQueueControllerGet = (
	variables: RunsWorkQueueControllerGetVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.WorkQueueDTO,
		RunsWorkQueueControllerGetError,
		undefined,
		{},
		{},
		RunsWorkQueueControllerGetPathParams
	>({
		url: '/api/v2/run/{runId}/workQueue',
		method: 'get',
		...variables,
		signal,
	})

export const useRunsWorkQueueControllerGet = <TData = Schemas.WorkQueueDTO>(
	variables: RunsWorkQueueControllerGetVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.WorkQueueDTO,
			RunsWorkQueueControllerGetError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.WorkQueueDTO,
		RunsWorkQueueControllerGetError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/run/{runId}/workQueue',
			operationId: 'runsWorkQueueControllerGet',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunsWorkQueueControllerGet(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunsWorkQueueControllerUpdatePathParams = {
	runId: string
}

export type RunsWorkQueueControllerUpdateError = Fetcher.ErrorWrapper<undefined>

export type RunsWorkQueueControllerUpdateVariables = {
	body: Schemas.WorkQueueDTO
	pathParams: RunsWorkQueueControllerUpdatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsWorkQueueControllerUpdate = (
	variables: RunsWorkQueueControllerUpdateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.WorkQueueDTO,
		RunsWorkQueueControllerUpdateError,
		Schemas.WorkQueueDTO,
		{},
		{},
		RunsWorkQueueControllerUpdatePathParams
	>({
		url: '/api/v2/run/{runId}/workQueue',
		method: 'put',
		...variables,
		signal,
	})

export const useRunsWorkQueueControllerUpdate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.WorkQueueDTO,
			RunsWorkQueueControllerUpdateError,
			RunsWorkQueueControllerUpdateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.WorkQueueDTO,
		RunsWorkQueueControllerUpdateError,
		RunsWorkQueueControllerUpdateVariables
	>({
		mutationFn: (variables: RunsWorkQueueControllerUpdateVariables) =>
			fetchRunsWorkQueueControllerUpdate({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type StartOptionsControllerGetStartOptionsError =
	Fetcher.ErrorWrapper<undefined>

export type StartOptionsControllerGetStartOptionsVariables =
	RepositoryContext['fetcherOptions']

export const fetchStartOptionsControllerGetStartOptions = (
	variables: StartOptionsControllerGetStartOptionsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		StartOptionsControllerGetStartOptionsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/run/options', method: 'get', ...variables, signal })

export const useStartOptionsControllerGetStartOptions = <TData = undefined>(
	variables: StartOptionsControllerGetStartOptionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			undefined,
			StartOptionsControllerGetStartOptionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		undefined,
		StartOptionsControllerGetStartOptionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/run/options',
			operationId: 'startOptionsControllerGetStartOptions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchStartOptionsControllerGetStartOptions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunsLogControllerV2GetRunPathParams = {
	id: string
	runNumber: string
}

export type RunsLogControllerV2GetRunError = Fetcher.ErrorWrapper<undefined>

export type RunsLogControllerV2GetRunVariables = {
	pathParams: RunsLogControllerV2GetRunPathParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsLogControllerV2GetRun = (
	variables: RunsLogControllerV2GetRunVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.RunDocument,
		RunsLogControllerV2GetRunError,
		undefined,
		{},
		{},
		RunsLogControllerV2GetRunPathParams
	>({
		url: '/api/v2/runs/{id}/run/{runNumber}',
		method: 'get',
		...variables,
		signal,
	})

export const useRunsLogControllerV2GetRun = <TData = Schemas.RunDocument>(
	variables: RunsLogControllerV2GetRunVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RunDocument,
			RunsLogControllerV2GetRunError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.RunDocument,
		RunsLogControllerV2GetRunError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/runs/{id}/run/{runNumber}',
			operationId: 'runsLogControllerV2GetRun',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunsLogControllerV2GetRun(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type RunsLogControllerV2GetRunLogPathParams = {
	id: string
	runNumber: string
}

export type RunsLogControllerV2GetRunLogQueryParams = {
	/**
	 * @minimum 1
	 * @default 50
	 */
	limit?: number
	/**
	 * @minimum 0
	 */
	offset?: number
	/**
	 * String sort
	 */
	sort?: string
	/**
	 * Only include results from a specific log type
	 */
	type?: Record<string, any>
}

export type RunsLogControllerV2GetRunLogError = Fetcher.ErrorWrapper<undefined>

export type RunsLogControllerV2GetRunLogVariables = {
	pathParams: RunsLogControllerV2GetRunLogPathParams
	queryParams?: RunsLogControllerV2GetRunLogQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchRunsLogControllerV2GetRunLog = (
	variables: RunsLogControllerV2GetRunLogVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.LogWithCount,
		RunsLogControllerV2GetRunLogError,
		undefined,
		{},
		RunsLogControllerV2GetRunLogQueryParams,
		RunsLogControllerV2GetRunLogPathParams
	>({
		url: '/api/v2/runs/{id}/log/{runNumber}',
		method: 'get',
		...variables,
		signal,
	})

export const useRunsLogControllerV2GetRunLog = <TData = Schemas.LogWithCount>(
	variables: RunsLogControllerV2GetRunLogVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.LogWithCount,
			RunsLogControllerV2GetRunLogError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.LogWithCount,
		RunsLogControllerV2GetRunLogError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/runs/{id}/log/{runNumber}',
			operationId: 'runsLogControllerV2GetRunLog',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRunsLogControllerV2GetRunLog(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type StartRunControllerStartRunPathParams = {
	id: string
}

export type StartRunControllerStartRunError = Fetcher.ErrorWrapper<undefined>

export type StartRunControllerStartRunVariables = {
	body: Schemas.StartRunRequestDTO
	pathParams: StartRunControllerStartRunPathParams
} & RepositoryContext['fetcherOptions']

export const fetchStartRunControllerStartRun = (
	variables: StartRunControllerStartRunVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.StartRunSuccessResponseDTO,
		StartRunControllerStartRunError,
		Schemas.StartRunRequestDTO,
		{},
		{},
		StartRunControllerStartRunPathParams
	>({
		url: '/api/v2/start/variant/{id}',
		method: 'post',
		...variables,
		signal,
	})

export const useStartRunControllerStartRun = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.StartRunSuccessResponseDTO,
			StartRunControllerStartRunError,
			StartRunControllerStartRunVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.StartRunSuccessResponseDTO,
		StartRunControllerStartRunError,
		StartRunControllerStartRunVariables
	>({
		mutationFn: (variables: StartRunControllerStartRunVariables) =>
			fetchStartRunControllerStartRun({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type VariantControllerGetVariantsError = Fetcher.ErrorWrapper<undefined>

export type VariantControllerGetVariantsVariables =
	RepositoryContext['fetcherOptions']

export const fetchVariantControllerGetVariants = (
	variables: VariantControllerGetVariantsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedVariantDtos,
		VariantControllerGetVariantsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/variants', method: 'get', ...variables, signal })

export const useVariantControllerGetVariants = <
	TData = Schemas.TransformedVariantDtos
>(
	variables: VariantControllerGetVariantsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransformedVariantDtos,
			VariantControllerGetVariantsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.TransformedVariantDtos,
		VariantControllerGetVariantsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/variants',
			operationId: 'variantControllerGetVariants',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchVariantControllerGetVariants(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type VariantControllerCreateVariantError =
	Fetcher.ErrorWrapper<undefined>

export type VariantControllerCreateVariantVariables = {
	body: Schemas.CreateVariantDto
} & RepositoryContext['fetcherOptions']

export const fetchVariantControllerCreateVariant = (
	variables: VariantControllerCreateVariantVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.VariantDto,
		VariantControllerCreateVariantError,
		Schemas.CreateVariantDto,
		{},
		{},
		{}
	>({ url: '/api/v2/variants', method: 'post', ...variables, signal })

export const useVariantControllerCreateVariant = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.VariantDto,
			VariantControllerCreateVariantError,
			VariantControllerCreateVariantVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.VariantDto,
		VariantControllerCreateVariantError,
		VariantControllerCreateVariantVariables
	>({
		mutationFn: (variables: VariantControllerCreateVariantVariables) =>
			fetchVariantControllerCreateVariant({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type VariantControllerGetVariantPathParams = {
	id: string
}

export type VariantControllerGetVariantError = Fetcher.ErrorWrapper<undefined>

export type VariantControllerGetVariantVariables = {
	pathParams: VariantControllerGetVariantPathParams
} & RepositoryContext['fetcherOptions']

export const fetchVariantControllerGetVariant = (
	variables: VariantControllerGetVariantVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		VariantControllerGetVariantError,
		undefined,
		{},
		{},
		VariantControllerGetVariantPathParams
	>({ url: '/api/v2/variants/{id}', method: 'get', ...variables, signal })

export const useVariantControllerGetVariant = <TData = undefined>(
	variables: VariantControllerGetVariantVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			undefined,
			VariantControllerGetVariantError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		undefined,
		VariantControllerGetVariantError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/variants/{id}',
			operationId: 'variantControllerGetVariant',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchVariantControllerGetVariant(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type VariantControllerUpdateVariantPathParams = {
	id: string
}

export type VariantControllerUpdateVariantError =
	Fetcher.ErrorWrapper<undefined>

export type VariantControllerUpdateVariantVariables = {
	body: Schemas.UpdateVariantDto
	pathParams: VariantControllerUpdateVariantPathParams
} & RepositoryContext['fetcherOptions']

export const fetchVariantControllerUpdateVariant = (
	variables: VariantControllerUpdateVariantVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.VariantDto,
		VariantControllerUpdateVariantError,
		Schemas.UpdateVariantDto,
		{},
		{},
		VariantControllerUpdateVariantPathParams
	>({ url: '/api/v2/variants/{id}', method: 'put', ...variables, signal })

export const useVariantControllerUpdateVariant = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.VariantDto,
			VariantControllerUpdateVariantError,
			VariantControllerUpdateVariantVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.VariantDto,
		VariantControllerUpdateVariantError,
		VariantControllerUpdateVariantVariables
	>({
		mutationFn: (variables: VariantControllerUpdateVariantVariables) =>
			fetchVariantControllerUpdateVariant({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type VariantControllerGetVariantsWithParentPathParams = {
	id: string
}

export type VariantControllerGetVariantsWithParentError =
	Fetcher.ErrorWrapper<undefined>

export type VariantControllerGetVariantsWithParentVariables = {
	pathParams: VariantControllerGetVariantsWithParentPathParams
} & RepositoryContext['fetcherOptions']

export const fetchVariantControllerGetVariantsWithParent = (
	variables: VariantControllerGetVariantsWithParentVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.VariantDto,
		VariantControllerGetVariantsWithParentError,
		undefined,
		{},
		{},
		VariantControllerGetVariantsWithParentPathParams
	>({
		url: '/api/v2/variants/parent/{id}',
		method: 'get',
		...variables,
		signal,
	})

export const useVariantControllerGetVariantsWithParent = <
	TData = Schemas.VariantDto
>(
	variables: VariantControllerGetVariantsWithParentVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.VariantDto,
			VariantControllerGetVariantsWithParentError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.VariantDto,
		VariantControllerGetVariantsWithParentError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/variants/parent/{id}',
			operationId: 'variantControllerGetVariantsWithParent',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchVariantControllerGetVariantsWithParent(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type VariantControllerGetVariantsWorkspacePathParams = {
	id: string
}

export type VariantControllerGetVariantsWorkspaceError =
	Fetcher.ErrorWrapper<undefined>

export type VariantControllerGetVariantsWorkspaceVariables = {
	pathParams: VariantControllerGetVariantsWorkspacePathParams
} & RepositoryContext['fetcherOptions']

export const fetchVariantControllerGetVariantsWorkspace = (
	variables: VariantControllerGetVariantsWorkspaceVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedWorkspaceDto,
		VariantControllerGetVariantsWorkspaceError,
		undefined,
		{},
		{},
		VariantControllerGetVariantsWorkspacePathParams
	>({
		url: '/api/v2/variants/{id}/workspace',
		method: 'get',
		...variables,
		signal,
	})

export const useVariantControllerGetVariantsWorkspace = <
	TData = Schemas.TransformedWorkspaceDto
>(
	variables: VariantControllerGetVariantsWorkspaceVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransformedWorkspaceDto,
			VariantControllerGetVariantsWorkspaceError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.TransformedWorkspaceDto,
		VariantControllerGetVariantsWorkspaceError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/variants/{id}/workspace',
			operationId: 'variantControllerGetVariantsWorkspace',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchVariantControllerGetVariantsWorkspace(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type VariantControllerGetVariantsApplicationPathParams = {
	id: string
}

export type VariantControllerGetVariantsApplicationError =
	Fetcher.ErrorWrapper<undefined>

export type VariantControllerGetVariantsApplicationVariables = {
	pathParams: VariantControllerGetVariantsApplicationPathParams
} & RepositoryContext['fetcherOptions']

export const fetchVariantControllerGetVariantsApplication = (
	variables: VariantControllerGetVariantsApplicationVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		VariantControllerGetVariantsApplicationError,
		undefined,
		{},
		{},
		VariantControllerGetVariantsApplicationPathParams
	>({
		url: '/api/v2/variants/{id}/application',
		method: 'get',
		...variables,
		signal,
	})

export const useVariantControllerGetVariantsApplication = <TData = undefined>(
	variables: VariantControllerGetVariantsApplicationVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			undefined,
			VariantControllerGetVariantsApplicationError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		undefined,
		VariantControllerGetVariantsApplicationError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/variants/{id}/application',
			operationId: 'variantControllerGetVariantsApplication',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchVariantControllerGetVariantsApplication(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type ModelControllerGetPathParams = {
	appId: string
}

export type ModelControllerGetQueryParams = {
	/**
	 * @minimum 1
	 * @default 50
	 */
	limit?: number
	/**
	 * @minimum 0
	 */
	offset?: number
}

export type ModelControllerGetError = Fetcher.ErrorWrapper<undefined>

export type ModelControllerGetVariables = {
	pathParams: ModelControllerGetPathParams
	queryParams?: ModelControllerGetQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchModelControllerGet = (
	variables: ModelControllerGetVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.ApplicationModelDTO,
		ModelControllerGetError,
		undefined,
		{},
		ModelControllerGetQueryParams,
		ModelControllerGetPathParams
	>({
		url: '/api/v2/variants/{appId}/model',
		method: 'get',
		...variables,
		signal,
	})

export const useModelControllerGet = <TData = Schemas.ApplicationModelDTO>(
	variables: ModelControllerGetVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ApplicationModelDTO,
			ModelControllerGetError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.ApplicationModelDTO,
		ModelControllerGetError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/variants/{appId}/model',
			operationId: 'modelControllerGet',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchModelControllerGet(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type ModelControllerDeletePathParams = {
	appId: string
}

export type ModelControllerDeleteError = Fetcher.ErrorWrapper<undefined>

export type ModelControllerDeleteVariables = {
	pathParams: ModelControllerDeletePathParams
} & RepositoryContext['fetcherOptions']

export const fetchModelControllerDelete = (
	variables: ModelControllerDeleteVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		ModelControllerDeleteError,
		undefined,
		{},
		{},
		ModelControllerDeletePathParams
	>({
		url: '/api/v2/variants/{appId}/model',
		method: 'delete',
		...variables,
		signal,
	})

export const useModelControllerDelete = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ModelControllerDeleteError,
			ModelControllerDeleteVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		ModelControllerDeleteError,
		ModelControllerDeleteVariables
	>({
		mutationFn: (variables: ModelControllerDeleteVariables) =>
			fetchModelControllerDelete({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type ModelControllerGetComponentPathParams = {
	/**
	 * The variant id
	 */
	appId: string
	/**
	 * The component id
	 */
	compId: string
}

export type ModelControllerGetComponentError = Fetcher.ErrorWrapper<undefined>

export type ModelControllerGetComponentVariables = {
	pathParams: ModelControllerGetComponentPathParams
} & RepositoryContext['fetcherOptions']

export const fetchModelControllerGetComponent = (
	variables: ModelControllerGetComponentVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.ApplicationComponentDTO,
		ModelControllerGetComponentError,
		undefined,
		{},
		{},
		ModelControllerGetComponentPathParams
	>({
		url: '/api/v2/variants/{appId}/model/components/{compId}',
		method: 'get',
		...variables,
		signal,
	})

export const useModelControllerGetComponent = <
	TData = Schemas.ApplicationComponentDTO
>(
	variables: ModelControllerGetComponentVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ApplicationComponentDTO,
			ModelControllerGetComponentError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.ApplicationComponentDTO,
		ModelControllerGetComponentError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/variants/{appId}/model/components/{compId}',
			operationId: 'modelControllerGetComponent',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchModelControllerGetComponent(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type ModelControllerDeleteComponentPathParams = {
	/**
	 * The application id
	 */
	id: string
	/**
	 * The component id
	 */
	compId: string
}

export type ModelControllerDeleteComponentError =
	Fetcher.ErrorWrapper<undefined>

export type ModelControllerDeleteComponentVariables = {
	pathParams: ModelControllerDeleteComponentPathParams
} & RepositoryContext['fetcherOptions']

export const fetchModelControllerDeleteComponent = (
	variables: ModelControllerDeleteComponentVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		ModelControllerDeleteComponentError,
		undefined,
		{},
		{},
		ModelControllerDeleteComponentPathParams
	>({
		url: '/api/v2/variants/{id}/model/components/{compId}',
		method: 'delete',
		...variables,
		signal,
	})

export const useModelControllerDeleteComponent = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ModelControllerDeleteComponentError,
			ModelControllerDeleteComponentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		ModelControllerDeleteComponentError,
		ModelControllerDeleteComponentVariables
	>({
		mutationFn: (variables: ModelControllerDeleteComponentVariables) =>
			fetchModelControllerDeleteComponent({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type ModelControllerApplyUpdatesPathParams = {
	appId: string
}

export type ModelControllerApplyUpdatesError = Fetcher.ErrorWrapper<undefined>

export type ModelControllerApplyUpdatesVariables = {
	body: Schemas.ApplicationModelUpdateRequestDTO
	pathParams: ModelControllerApplyUpdatesPathParams
} & RepositoryContext['fetcherOptions']

export const fetchModelControllerApplyUpdates = (
	variables: ModelControllerApplyUpdatesVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		ModelControllerApplyUpdatesError,
		Schemas.ApplicationModelUpdateRequestDTO,
		{},
		{},
		ModelControllerApplyUpdatesPathParams
	>({
		url: '/api/v2/variants/{appId}/model/update',
		method: 'post',
		...variables,
		signal,
	})

export const useModelControllerApplyUpdates = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ModelControllerApplyUpdatesError,
			ModelControllerApplyUpdatesVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		ModelControllerApplyUpdatesError,
		ModelControllerApplyUpdatesVariables
	>({
		mutationFn: (variables: ModelControllerApplyUpdatesVariables) =>
			fetchModelControllerApplyUpdates({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type ModelControllerSavePageModelPathParams = {
	/**
	 * The variant id
	 */
	appId: string
}

export type ModelControllerSavePageModelError = Fetcher.ErrorWrapper<undefined>

export type ModelControllerSavePageModelVariables = {
	body: Schemas.CreatePageModelDTO
	pathParams: ModelControllerSavePageModelPathParams
} & RepositoryContext['fetcherOptions']

export const fetchModelControllerSavePageModel = (
	variables: ModelControllerSavePageModelVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		string,
		ModelControllerSavePageModelError,
		Schemas.CreatePageModelDTO,
		{},
		{},
		ModelControllerSavePageModelPathParams
	>({
		url: '/api/v2/variants/{appId}/model/pages',
		method: 'post',
		...variables,
		signal,
	})

export const useModelControllerSavePageModel = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			string,
			ModelControllerSavePageModelError,
			ModelControllerSavePageModelVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		string,
		ModelControllerSavePageModelError,
		ModelControllerSavePageModelVariables
	>({
		mutationFn: (variables: ModelControllerSavePageModelVariables) =>
			fetchModelControllerSavePageModel({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type ModelControllerListPageModelsPathParams = {
	/**
	 * The variant id
	 */
	appId: string
}

export type ModelControllerListPageModelsQueryParams = {
	/**
	 * Filter by run id
	 */
	runId?: string
}

export type ModelControllerListPageModelsError = Fetcher.ErrorWrapper<undefined>

export type ModelControllerListPageModelsResponse = Schemas.PageModelDto[]

export type ModelControllerListPageModelsVariables = {
	pathParams: ModelControllerListPageModelsPathParams
	queryParams?: ModelControllerListPageModelsQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchModelControllerListPageModels = (
	variables: ModelControllerListPageModelsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		ModelControllerListPageModelsResponse,
		ModelControllerListPageModelsError,
		undefined,
		{},
		ModelControllerListPageModelsQueryParams,
		ModelControllerListPageModelsPathParams
	>({
		url: '/api/v2/variants/{appId}/model/pages',
		method: 'get',
		...variables,
		signal,
	})

export const useModelControllerListPageModels = <
	TData = ModelControllerListPageModelsResponse
>(
	variables: ModelControllerListPageModelsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ModelControllerListPageModelsResponse,
			ModelControllerListPageModelsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		ModelControllerListPageModelsResponse,
		ModelControllerListPageModelsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/variants/{appId}/model/pages',
			operationId: 'modelControllerListPageModels',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchModelControllerListPageModels(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type ModelControllerGetPageModelPathParams = {
	/**
	 * The variant id
	 */
	appId: string
	/**
	 * What kind of ID to fetch the model by.
	 * 		- When 'page-component', `id` should be an application component id. The most recent page model for this page will be retrieved.
	 * 		- When 'page-model', `id` should be a page model id. The page model with this id will be retrieved.
	 */
	modelIdKind: 'page-component' | 'page-model'
	/**
	 * Either a page model id or a page component id, depending on what `modelIdKind` is set to.
	 */
	id: string
}

export type ModelControllerGetPageModelError = Fetcher.ErrorWrapper<undefined>

export type ModelControllerGetPageModelVariables = {
	pathParams: ModelControllerGetPageModelPathParams
} & RepositoryContext['fetcherOptions']

export const fetchModelControllerGetPageModel = (
	variables: ModelControllerGetPageModelVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		string,
		ModelControllerGetPageModelError,
		undefined,
		{},
		{},
		ModelControllerGetPageModelPathParams
	>({
		url: '/api/v2/variants/{appId}/model/pages/{modelIdKind}/{id}/model',
		method: 'get',
		...variables,
		signal,
	})

export const useModelControllerGetPageModel = <TData = string>(
	variables: ModelControllerGetPageModelVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			string,
			ModelControllerGetPageModelError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<string, ModelControllerGetPageModelError, TData>(
		{
			queryKey: queryKeyFn({
				path: '/api/v2/variants/{appId}/model/pages/{modelIdKind}/{id}/model',
				operationId: 'modelControllerGetPageModel',
				variables,
			}),
			queryFn: ({ signal }) =>
				fetchModelControllerGetPageModel(
					{ ...fetcherOptions, ...variables },
					signal
				),
			...options,
			...queryOptions,
		}
	)
}

export type ModelControllerRemoveModelPathParams = {
	id: string
}

export type ModelControllerRemoveModelError = Fetcher.ErrorWrapper<undefined>

export type ModelControllerRemoveModelVariables = {
	pathParams: ModelControllerRemoveModelPathParams
} & RepositoryContext['fetcherOptions']

export const fetchModelControllerRemoveModel = (
	variables: ModelControllerRemoveModelVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		ModelControllerRemoveModelError,
		undefined,
		{},
		{},
		ModelControllerRemoveModelPathParams
	>({
		url: '/api/v2/variants/{id}/model/remove-model',
		method: 'delete',
		...variables,
		signal,
	})

export const useModelControllerRemoveModel = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ModelControllerRemoveModelError,
			ModelControllerRemoveModelVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		ModelControllerRemoveModelError,
		ModelControllerRemoveModelVariables
	>({
		mutationFn: (variables: ModelControllerRemoveModelVariables) =>
			fetchModelControllerRemoveModel({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type PermissionControllerGetPermissionsError =
	Fetcher.ErrorWrapper<undefined>

export type PermissionControllerGetPermissionsVariables =
	RepositoryContext['fetcherOptions']

export const fetchPermissionControllerGetPermissions = (
	variables: PermissionControllerGetPermissionsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.PermissionResponse,
		PermissionControllerGetPermissionsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/permissions', method: 'get', ...variables, signal })

export const usePermissionControllerGetPermissions = <
	TData = Schemas.PermissionResponse
>(
	variables: PermissionControllerGetPermissionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PermissionResponse,
			PermissionControllerGetPermissionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.PermissionResponse,
		PermissionControllerGetPermissionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/permissions',
			operationId: 'permissionControllerGetPermissions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchPermissionControllerGetPermissions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type PermissionControllerCreatePermissionError =
	Fetcher.ErrorWrapper<undefined>

export type PermissionControllerCreatePermissionVariables = {
	body: Schemas.CreatePermissionDto
} & RepositoryContext['fetcherOptions']

export const fetchPermissionControllerCreatePermission = (
	variables: PermissionControllerCreatePermissionVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.PermissionResponse | Schemas.InvitedUserPermissionDto,
		PermissionControllerCreatePermissionError,
		Schemas.CreatePermissionDto,
		{},
		{},
		{}
	>({ url: '/api/v2/permissions', method: 'post', ...variables, signal })

export const usePermissionControllerCreatePermission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.PermissionResponse | Schemas.InvitedUserPermissionDto,
			PermissionControllerCreatePermissionError,
			PermissionControllerCreatePermissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.PermissionResponse | Schemas.InvitedUserPermissionDto,
		PermissionControllerCreatePermissionError,
		PermissionControllerCreatePermissionVariables
	>({
		mutationFn: (
			variables: PermissionControllerCreatePermissionVariables
		) =>
			fetchPermissionControllerCreatePermission({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type PermissionControllerGetPermissionPathParams = {
	id: string
}

export type PermissionControllerGetPermissionError =
	Fetcher.ErrorWrapper<undefined>

export type PermissionControllerGetPermissionVariables = {
	pathParams: PermissionControllerGetPermissionPathParams
} & RepositoryContext['fetcherOptions']

export const fetchPermissionControllerGetPermission = (
	variables: PermissionControllerGetPermissionVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.PermissionResponse,
		PermissionControllerGetPermissionError,
		undefined,
		{},
		{},
		PermissionControllerGetPermissionPathParams
	>({ url: '/api/v2/permissions/{id}', method: 'get', ...variables, signal })

export const usePermissionControllerGetPermission = <
	TData = Schemas.PermissionResponse
>(
	variables: PermissionControllerGetPermissionVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PermissionResponse,
			PermissionControllerGetPermissionError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.PermissionResponse,
		PermissionControllerGetPermissionError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/permissions/{id}',
			operationId: 'permissionControllerGetPermission',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchPermissionControllerGetPermission(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type PermissionControllerUpdatePathParams = {
	id: string
}

export type PermissionControllerUpdateError = Fetcher.ErrorWrapper<undefined>

export type PermissionControllerUpdateVariables = {
	body: Schemas.UpdatePermissionRoleDto
	pathParams: PermissionControllerUpdatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchPermissionControllerUpdate = (
	variables: PermissionControllerUpdateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.PermissionResponse,
		PermissionControllerUpdateError,
		Schemas.UpdatePermissionRoleDto,
		{},
		{},
		PermissionControllerUpdatePathParams
	>({ url: '/api/v2/permissions/{id}', method: 'put', ...variables, signal })

export const usePermissionControllerUpdate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.PermissionResponse,
			PermissionControllerUpdateError,
			PermissionControllerUpdateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.PermissionResponse,
		PermissionControllerUpdateError,
		PermissionControllerUpdateVariables
	>({
		mutationFn: (variables: PermissionControllerUpdateVariables) =>
			fetchPermissionControllerUpdate({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type PermissionControllerRemovePathParams = {
	id: string
}

export type PermissionControllerRemoveError = Fetcher.ErrorWrapper<undefined>

export type PermissionControllerRemoveVariables = {
	pathParams: PermissionControllerRemovePathParams
} & RepositoryContext['fetcherOptions']

export const fetchPermissionControllerRemove = (
	variables: PermissionControllerRemoveVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.PermissionResponse,
		PermissionControllerRemoveError,
		undefined,
		{},
		{},
		PermissionControllerRemovePathParams
	>({
		url: '/api/v2/permissions/{id}',
		method: 'delete',
		...variables,
		signal,
	})

export const usePermissionControllerRemove = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.PermissionResponse,
			PermissionControllerRemoveError,
			PermissionControllerRemoveVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.PermissionResponse,
		PermissionControllerRemoveError,
		PermissionControllerRemoveVariables
	>({
		mutationFn: (variables: PermissionControllerRemoveVariables) =>
			fetchPermissionControllerRemove({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type PermissionControllerGetResourcePermissionsPathParams = {
	id: string
}

export type PermissionControllerGetResourcePermissionsError =
	Fetcher.ErrorWrapper<undefined>

export type PermissionControllerGetResourcePermissionsVariables = {
	pathParams: PermissionControllerGetResourcePermissionsPathParams
} & RepositoryContext['fetcherOptions']

export const fetchPermissionControllerGetResourcePermissions = (
	variables: PermissionControllerGetResourcePermissionsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.PermissionResponse,
		PermissionControllerGetResourcePermissionsError,
		undefined,
		{},
		{},
		PermissionControllerGetResourcePermissionsPathParams
	>({
		url: '/api/v2/permissions/resource/{id}',
		method: 'get',
		...variables,
		signal,
	})

export const usePermissionControllerGetResourcePermissions = <
	TData = Schemas.PermissionResponse
>(
	variables: PermissionControllerGetResourcePermissionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PermissionResponse,
			PermissionControllerGetResourcePermissionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.PermissionResponse,
		PermissionControllerGetResourcePermissionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/permissions/resource/{id}',
			operationId: 'permissionControllerGetResourcePermissions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchPermissionControllerGetResourcePermissions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type PermissionControllerGetUserPermissionsPathParams = {
	id: string
}

export type PermissionControllerGetUserPermissionsError =
	Fetcher.ErrorWrapper<undefined>

export type PermissionControllerGetUserPermissionsVariables = {
	pathParams: PermissionControllerGetUserPermissionsPathParams
} & RepositoryContext['fetcherOptions']

export const fetchPermissionControllerGetUserPermissions = (
	variables: PermissionControllerGetUserPermissionsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.PermissionResponse,
		PermissionControllerGetUserPermissionsError,
		undefined,
		{},
		{},
		PermissionControllerGetUserPermissionsPathParams
	>({
		url: '/api/v2/permissions/user/{id}',
		method: 'get',
		...variables,
		signal,
	})

export const usePermissionControllerGetUserPermissions = <
	TData = Schemas.PermissionResponse
>(
	variables: PermissionControllerGetUserPermissionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PermissionResponse,
			PermissionControllerGetUserPermissionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.PermissionResponse,
		PermissionControllerGetUserPermissionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/permissions/user/{id}',
			operationId: 'permissionControllerGetUserPermissions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchPermissionControllerGetUserPermissions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type UsageControllerGetWorkspaceUsagePathParams = {
	id: string
}

export type UsageControllerGetWorkspaceUsageQueryParams = {
	/**
	 * @format date-time
	 */
	date?: string
	runId?: string
	/**
	 * @minimum 1
	 * @default 50
	 */
	limit?: number
	/**
	 * @minimum 0
	 */
	offset?: number
	populate?: ('runId' | 'workspaceId')[]
}

export type UsageControllerGetWorkspaceUsageError =
	Fetcher.ErrorWrapper<undefined>

export type UsageControllerGetWorkspaceUsageResponse = Schemas.UsageDto[]

export type UsageControllerGetWorkspaceUsageVariables = {
	pathParams: UsageControllerGetWorkspaceUsagePathParams
	queryParams?: UsageControllerGetWorkspaceUsageQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchUsageControllerGetWorkspaceUsage = (
	variables: UsageControllerGetWorkspaceUsageVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		UsageControllerGetWorkspaceUsageResponse,
		UsageControllerGetWorkspaceUsageError,
		undefined,
		{},
		UsageControllerGetWorkspaceUsageQueryParams,
		UsageControllerGetWorkspaceUsagePathParams
	>({ url: '/api/v2/usage/{id}', method: 'get', ...variables, signal })

export const useUsageControllerGetWorkspaceUsage = <
	TData = UsageControllerGetWorkspaceUsageResponse
>(
	variables: UsageControllerGetWorkspaceUsageVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			UsageControllerGetWorkspaceUsageResponse,
			UsageControllerGetWorkspaceUsageError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		UsageControllerGetWorkspaceUsageResponse,
		UsageControllerGetWorkspaceUsageError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/usage/{id}',
			operationId: 'usageControllerGetWorkspaceUsage',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchUsageControllerGetWorkspaceUsage(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type UsageControllerCreateWorkspacePathParams = {
	id: string
}

export type UsageControllerCreateWorkspaceError =
	Fetcher.ErrorWrapper<undefined>

export type UsageControllerCreateWorkspaceVariables = {
	body: Schemas.CreateUsageDto
	pathParams: UsageControllerCreateWorkspacePathParams
} & RepositoryContext['fetcherOptions']

export const fetchUsageControllerCreateWorkspace = (
	variables: UsageControllerCreateWorkspaceVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.UsageDto,
		UsageControllerCreateWorkspaceError,
		Schemas.CreateUsageDto,
		{},
		{},
		UsageControllerCreateWorkspacePathParams
	>({ url: '/api/v2/usage/{id}', method: 'post', ...variables, signal })

export const useUsageControllerCreateWorkspace = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.UsageDto,
			UsageControllerCreateWorkspaceError,
			UsageControllerCreateWorkspaceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.UsageDto,
		UsageControllerCreateWorkspaceError,
		UsageControllerCreateWorkspaceVariables
	>({
		mutationFn: (variables: UsageControllerCreateWorkspaceVariables) =>
			fetchUsageControllerCreateWorkspace({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type AnalyticsControllerGetRunRuleStatisticsPathParams = {
	appId: string
	runId: string
}

export type AnalyticsControllerGetRunRuleStatisticsQueryParams = {
	/**
	 * Only include results from a specific run, or a set of runs
	 */
	runId?: void
	/**
	 * Filter for one or more rules by id
	 */
	ruleId?: void
	ruleType?:
		| 'accessibility-rule'
		| 'functional-rule'
		| 'resource-rule'
		| 'webform-rule'
		| 'link-rule'
	pageId?: string
	componentId?: string
	componentType?:
		| 'Iframe'
		| 'Body'
		| 'Header'
		| 'Footer'
		| 'Navbar'
		| 'Form'
		| 'Button'
		| 'Anchor'
		| 'Input'
		| 'Content'
		| 'Section'
		| 'Select'
		| 'Option'
		| 'Table'
		| 'Table Header'
		| 'Table Body'
		| 'Table Footer'
		| 'Table Row'
		| 'Table Header Cell'
		| 'Table Data Cell'
		| 'Large Heading'
		| 'Medium Heading'
		| 'Small Heading'
		| 'Webform'
		| 'Label'
		| 'Field'
		| 'Checkbox Cluster'
		| 'Radio Group'
		| 'Radio'
		| 'Checkbox'
		| 'Select Option'
		| 'Text Area'
		| 'Text Node'
		| 'Image'
		| 'Icon'
		| 'Grid'
		| 'Grid Item'
		| 'List'
		| 'List Item'
		| 'Unclassified'
	/**
	 * The fields to group by
	 */
	groupBy: string[]
	/**
	 * Populate certain fields by joining with other collections.
	 *
	 * Values in this list must be a subset of `groupBy`, and can only
	 * apply to id fields. Instead of containing
	 * an ObjectId, populated fields will contain the document the id
	 * refers to.
	 */
	populate?: string[]
}

export type AnalyticsControllerGetRunRuleStatisticsError =
	Fetcher.ErrorWrapper<undefined>

export type AnalyticsControllerGetRunRuleStatisticsVariables = {
	pathParams: AnalyticsControllerGetRunRuleStatisticsPathParams
	queryParams: AnalyticsControllerGetRunRuleStatisticsQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchAnalyticsControllerGetRunRuleStatistics = (
	variables: AnalyticsControllerGetRunRuleStatisticsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.AnalyticsResultsDTO,
		AnalyticsControllerGetRunRuleStatisticsError,
		undefined,
		{},
		AnalyticsControllerGetRunRuleStatisticsQueryParams,
		AnalyticsControllerGetRunRuleStatisticsPathParams
	>({
		url: '/api/v2/analytics/app/{appId}/run/{runId}',
		method: 'get',
		...variables,
		signal,
	})

export const useAnalyticsControllerGetRunRuleStatistics = <
	TData = Schemas.AnalyticsResultsDTO
>(
	variables: AnalyticsControllerGetRunRuleStatisticsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.AnalyticsResultsDTO,
			AnalyticsControllerGetRunRuleStatisticsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.AnalyticsResultsDTO,
		AnalyticsControllerGetRunRuleStatisticsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/analytics/app/{appId}/run/{runId}',
			operationId: 'analyticsControllerGetRunRuleStatistics',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchAnalyticsControllerGetRunRuleStatistics(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type AnalyticsControllerGetComponentRuleStatisticsPathParams = {
	appId: string
}

export type AnalyticsControllerGetComponentRuleStatisticsQueryParams = {
	/**
	 * Only include results from a specific run, or a set of runs
	 */
	runId?: void
	/**
	 * Filter for one or more rules by id
	 */
	ruleId?: void
	ruleType?:
		| 'accessibility-rule'
		| 'functional-rule'
		| 'resource-rule'
		| 'webform-rule'
		| 'link-rule'
	pageId?: string
	componentId?: string
	componentType?:
		| 'Iframe'
		| 'Body'
		| 'Header'
		| 'Footer'
		| 'Navbar'
		| 'Form'
		| 'Button'
		| 'Anchor'
		| 'Input'
		| 'Content'
		| 'Section'
		| 'Select'
		| 'Option'
		| 'Table'
		| 'Table Header'
		| 'Table Body'
		| 'Table Footer'
		| 'Table Row'
		| 'Table Header Cell'
		| 'Table Data Cell'
		| 'Large Heading'
		| 'Medium Heading'
		| 'Small Heading'
		| 'Webform'
		| 'Label'
		| 'Field'
		| 'Checkbox Cluster'
		| 'Radio Group'
		| 'Radio'
		| 'Checkbox'
		| 'Select Option'
		| 'Text Area'
		| 'Text Node'
		| 'Image'
		| 'Icon'
		| 'Grid'
		| 'Grid Item'
		| 'List'
		| 'List Item'
		| 'Unclassified'
	/**
	 * The fields to group by
	 */
	groupBy: string[]
	/**
	 * Populate certain fields by joining with other collections.
	 *
	 * Values in this list must be a subset of `groupBy`, and can only
	 * apply to id fields. Instead of containing
	 * an ObjectId, populated fields will contain the document the id
	 * refers to.
	 */
	populate?: string[]
}

export type AnalyticsControllerGetComponentRuleStatisticsError =
	Fetcher.ErrorWrapper<undefined>

export type AnalyticsControllerGetComponentRuleStatisticsVariables = {
	pathParams: AnalyticsControllerGetComponentRuleStatisticsPathParams
	queryParams: AnalyticsControllerGetComponentRuleStatisticsQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchAnalyticsControllerGetComponentRuleStatistics = (
	variables: AnalyticsControllerGetComponentRuleStatisticsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.AnalyticsResultsDTO,
		AnalyticsControllerGetComponentRuleStatisticsError,
		undefined,
		{},
		AnalyticsControllerGetComponentRuleStatisticsQueryParams,
		AnalyticsControllerGetComponentRuleStatisticsPathParams
	>({
		url: '/api/v2/analytics/app/{appId}/components',
		method: 'get',
		...variables,
		signal,
	})

export const useAnalyticsControllerGetComponentRuleStatistics = <
	TData = Schemas.AnalyticsResultsDTO
>(
	variables: AnalyticsControllerGetComponentRuleStatisticsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.AnalyticsResultsDTO,
			AnalyticsControllerGetComponentRuleStatisticsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.AnalyticsResultsDTO,
		AnalyticsControllerGetComponentRuleStatisticsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/analytics/app/{appId}/components',
			operationId: 'analyticsControllerGetComponentRuleStatistics',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchAnalyticsControllerGetComponentRuleStatistics(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type AnalyticsControllerGetPageRuleStatisticsPathParams = {
	appId: string
}

export type AnalyticsControllerGetPageRuleStatisticsQueryParams = {
	/**
	 * Only include results from a specific run, or a set of runs
	 */
	runId?: void
	/**
	 * Filter for one or more rules by id
	 */
	ruleId?: void
	ruleType?:
		| 'accessibility-rule'
		| 'functional-rule'
		| 'resource-rule'
		| 'webform-rule'
		| 'link-rule'
	pageId?: string
	componentId?: string
	componentType?:
		| 'Iframe'
		| 'Body'
		| 'Header'
		| 'Footer'
		| 'Navbar'
		| 'Form'
		| 'Button'
		| 'Anchor'
		| 'Input'
		| 'Content'
		| 'Section'
		| 'Select'
		| 'Option'
		| 'Table'
		| 'Table Header'
		| 'Table Body'
		| 'Table Footer'
		| 'Table Row'
		| 'Table Header Cell'
		| 'Table Data Cell'
		| 'Large Heading'
		| 'Medium Heading'
		| 'Small Heading'
		| 'Webform'
		| 'Label'
		| 'Field'
		| 'Checkbox Cluster'
		| 'Radio Group'
		| 'Radio'
		| 'Checkbox'
		| 'Select Option'
		| 'Text Area'
		| 'Text Node'
		| 'Image'
		| 'Icon'
		| 'Grid'
		| 'Grid Item'
		| 'List'
		| 'List Item'
		| 'Unclassified'
	/**
	 * The fields to group by
	 */
	groupBy: string[]
	/**
	 * Populate certain fields by joining with other collections.
	 *
	 * Values in this list must be a subset of `groupBy`, and can only
	 * apply to id fields. Instead of containing
	 * an ObjectId, populated fields will contain the document the id
	 * refers to.
	 */
	populate?: string[]
}

export type AnalyticsControllerGetPageRuleStatisticsError =
	Fetcher.ErrorWrapper<undefined>

export type AnalyticsControllerGetPageRuleStatisticsVariables = {
	pathParams: AnalyticsControllerGetPageRuleStatisticsPathParams
	queryParams: AnalyticsControllerGetPageRuleStatisticsQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchAnalyticsControllerGetPageRuleStatistics = (
	variables: AnalyticsControllerGetPageRuleStatisticsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.AnalyticsResultsDTO,
		AnalyticsControllerGetPageRuleStatisticsError,
		undefined,
		{},
		AnalyticsControllerGetPageRuleStatisticsQueryParams,
		AnalyticsControllerGetPageRuleStatisticsPathParams
	>({
		url: '/api/v2/analytics/app/{appId}/pages',
		method: 'get',
		...variables,
		signal,
	})

export const useAnalyticsControllerGetPageRuleStatistics = <
	TData = Schemas.AnalyticsResultsDTO
>(
	variables: AnalyticsControllerGetPageRuleStatisticsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.AnalyticsResultsDTO,
			AnalyticsControllerGetPageRuleStatisticsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.AnalyticsResultsDTO,
		AnalyticsControllerGetPageRuleStatisticsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/analytics/app/{appId}/pages',
			operationId: 'analyticsControllerGetPageRuleStatistics',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchAnalyticsControllerGetPageRuleStatistics(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type AnalyticsControllerGetPagePerformanceStatisticsPathParams = {
	/**
	 * The ID of the application
	 */
	appId: string
}

export type AnalyticsControllerGetPagePerformanceStatisticsQueryParams = {
	/**
	 * Only include statistics from a specific run
	 */
	runId?: string
	/**
	 * Only include statistics from a specific page
	 */
	pageId?: string
	/**
	 * Forcibly re-build analytics data for the specified run. Avoid this when possible because its expensive.
	 */
	forceRebuild?: boolean
}

export type AnalyticsControllerGetPagePerformanceStatisticsError =
	Fetcher.ErrorWrapper<undefined>

export type AnalyticsControllerGetPagePerformanceStatisticsVariables = {
	pathParams: AnalyticsControllerGetPagePerformanceStatisticsPathParams
	queryParams?: AnalyticsControllerGetPagePerformanceStatisticsQueryParams
} & RepositoryContext['fetcherOptions']

/**
 * Get page performance statistics
 */
export const fetchAnalyticsControllerGetPagePerformanceStatistics = (
	variables: AnalyticsControllerGetPagePerformanceStatisticsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.PerformanceResultsDTO,
		AnalyticsControllerGetPagePerformanceStatisticsError,
		undefined,
		{},
		AnalyticsControllerGetPagePerformanceStatisticsQueryParams,
		AnalyticsControllerGetPagePerformanceStatisticsPathParams
	>({
		url: '/api/v2/analytics/app/{appId}/pages/performance',
		method: 'get',
		...variables,
		signal,
	})

/**
 * Get page performance statistics
 */
export const useAnalyticsControllerGetPagePerformanceStatistics = <
	TData = Schemas.PerformanceResultsDTO
>(
	variables: AnalyticsControllerGetPagePerformanceStatisticsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PerformanceResultsDTO,
			AnalyticsControllerGetPagePerformanceStatisticsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.PerformanceResultsDTO,
		AnalyticsControllerGetPagePerformanceStatisticsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/analytics/app/{appId}/pages/performance',
			operationId: 'analyticsControllerGetPagePerformanceStatistics',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchAnalyticsControllerGetPagePerformanceStatistics(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type TagsControllerGetTagsWithIdsQueryParams = {
	ids: string[]
}

export type TagsControllerGetTagsWithIdsError = Fetcher.ErrorWrapper<undefined>

export type TagsControllerGetTagsWithIdsVariables = {
	queryParams: TagsControllerGetTagsWithIdsQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchTagsControllerGetTagsWithIds = (
	variables: TagsControllerGetTagsWithIdsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedTagDTOS,
		TagsControllerGetTagsWithIdsError,
		undefined,
		{},
		TagsControllerGetTagsWithIdsQueryParams,
		{}
	>({ url: '/api/v2/tags', method: 'get', ...variables, signal })

export const useTagsControllerGetTagsWithIds = <
	TData = Schemas.TransformedTagDTOS
>(
	variables: TagsControllerGetTagsWithIdsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransformedTagDTOS,
			TagsControllerGetTagsWithIdsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.TransformedTagDTOS,
		TagsControllerGetTagsWithIdsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/tags',
			operationId: 'tagsControllerGetTagsWithIds',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchTagsControllerGetTagsWithIds(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type TagsControllerGetAppTagsPathParams = {
	id: string
}

export type TagsControllerGetAppTagsError = Fetcher.ErrorWrapper<undefined>

export type TagsControllerGetAppTagsVariables = {
	pathParams: TagsControllerGetAppTagsPathParams
} & RepositoryContext['fetcherOptions']

export const fetchTagsControllerGetAppTags = (
	variables: TagsControllerGetAppTagsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedTagWithPopulatedParentDTOS,
		TagsControllerGetAppTagsError,
		undefined,
		{},
		{},
		TagsControllerGetAppTagsPathParams
	>({ url: '/api/v2/tags/{id}', method: 'get', ...variables, signal })

export const useTagsControllerGetAppTags = <
	TData = Schemas.TransformedTagWithPopulatedParentDTOS
>(
	variables: TagsControllerGetAppTagsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransformedTagWithPopulatedParentDTOS,
			TagsControllerGetAppTagsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.TransformedTagWithPopulatedParentDTOS,
		TagsControllerGetAppTagsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/tags/{id}',
			operationId: 'tagsControllerGetAppTags',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchTagsControllerGetAppTags(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type TagsControllerCreatePathParams = {
	id: string
}

export type TagsControllerCreateError = Fetcher.ErrorWrapper<undefined>

export type TagsControllerCreateVariables = {
	body: Schemas.CreateTagDto
	pathParams: TagsControllerCreatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchTagsControllerCreate = (
	variables: TagsControllerCreateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedTagDTO,
		TagsControllerCreateError,
		Schemas.CreateTagDto,
		{},
		{},
		TagsControllerCreatePathParams
	>({ url: '/api/v2/tags/{id}', method: 'post', ...variables, signal })

export const useTagsControllerCreate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TransformedTagDTO,
			TagsControllerCreateError,
			TagsControllerCreateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.TransformedTagDTO,
		TagsControllerCreateError,
		TagsControllerCreateVariables
	>({
		mutationFn: (variables: TagsControllerCreateVariables) =>
			fetchTagsControllerCreate({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type TagsControllerUpdateTagPathParams = {
	id: string
}

export type TagsControllerUpdateTagError = Fetcher.ErrorWrapper<undefined>

export type TagsControllerUpdateTagVariables = {
	body: Schemas.UpdateTagDto
	pathParams: TagsControllerUpdateTagPathParams
} & RepositoryContext['fetcherOptions']

export const fetchTagsControllerUpdateTag = (
	variables: TagsControllerUpdateTagVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedTagDTO,
		TagsControllerUpdateTagError,
		Schemas.UpdateTagDto,
		{},
		{},
		TagsControllerUpdateTagPathParams
	>({ url: '/api/v2/tags/{id}/update', method: 'put', ...variables, signal })

export const useTagsControllerUpdateTag = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TransformedTagDTO,
			TagsControllerUpdateTagError,
			TagsControllerUpdateTagVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.TransformedTagDTO,
		TagsControllerUpdateTagError,
		TagsControllerUpdateTagVariables
	>({
		mutationFn: (variables: TagsControllerUpdateTagVariables) =>
			fetchTagsControllerUpdateTag({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type TagsControllerUpdateParentPathParams = {
	appId: string
	tagId: string
}

export type TagsControllerUpdateParentError = Fetcher.ErrorWrapper<undefined>

export type TagsControllerUpdateParentVariables = {
	body: Schemas.UpdateTagParentDTO
	pathParams: TagsControllerUpdateParentPathParams
} & RepositoryContext['fetcherOptions']

export const fetchTagsControllerUpdateParent = (
	variables: TagsControllerUpdateParentVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedTagWithPopulatedParentDTO,
		TagsControllerUpdateParentError,
		Schemas.UpdateTagParentDTO,
		{},
		{},
		TagsControllerUpdateParentPathParams
	>({
		url: '/api/v2/tags/{appId}/{tagId}/updateParent',
		method: 'put',
		...variables,
		signal,
	})

export const useTagsControllerUpdateParent = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TransformedTagWithPopulatedParentDTO,
			TagsControllerUpdateParentError,
			TagsControllerUpdateParentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.TransformedTagWithPopulatedParentDTO,
		TagsControllerUpdateParentError,
		TagsControllerUpdateParentVariables
	>({
		mutationFn: (variables: TagsControllerUpdateParentVariables) =>
			fetchTagsControllerUpdateParent({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type TagsControllerRemoveTagPathParams = {
	id: string
	tagId: string
}

export type TagsControllerRemoveTagError = Fetcher.ErrorWrapper<undefined>

export type TagsControllerRemoveTagVariables = {
	pathParams: TagsControllerRemoveTagPathParams
} & RepositoryContext['fetcherOptions']

export const fetchTagsControllerRemoveTag = (
	variables: TagsControllerRemoveTagVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedTagDTO,
		TagsControllerRemoveTagError,
		undefined,
		{},
		{},
		TagsControllerRemoveTagPathParams
	>({
		url: '/api/v2/tags/{id}/{tagId}',
		method: 'delete',
		...variables,
		signal,
	})

export const useTagsControllerRemoveTag = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TransformedTagDTO,
			TagsControllerRemoveTagError,
			TagsControllerRemoveTagVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.TransformedTagDTO,
		TagsControllerRemoveTagError,
		TagsControllerRemoveTagVariables
	>({
		mutationFn: (variables: TagsControllerRemoveTagVariables) =>
			fetchTagsControllerRemoveTag({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type ApplicationControllerGetApplicationsError =
	Fetcher.ErrorWrapper<undefined>

export type ApplicationControllerGetApplicationsVariables =
	RepositoryContext['fetcherOptions']

export const fetchApplicationControllerGetApplications = (
	variables: ApplicationControllerGetApplicationsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedApplicationDtos,
		ApplicationControllerGetApplicationsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/applications', method: 'get', ...variables, signal })

export const useApplicationControllerGetApplications = <
	TData = Schemas.TransformedApplicationDtos
>(
	variables: ApplicationControllerGetApplicationsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransformedApplicationDtos,
			ApplicationControllerGetApplicationsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.TransformedApplicationDtos,
		ApplicationControllerGetApplicationsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/applications',
			operationId: 'applicationControllerGetApplications',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApplicationControllerGetApplications(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type ApplicationControllerCreateApplicationError =
	Fetcher.ErrorWrapper<undefined>

export type ApplicationControllerCreateApplicationVariables = {
	body: Schemas.CreateApplicationDto
} & RepositoryContext['fetcherOptions']

export const fetchApplicationControllerCreateApplication = (
	variables: ApplicationControllerCreateApplicationVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedApplicationDto,
		ApplicationControllerCreateApplicationError,
		Schemas.CreateApplicationDto,
		{},
		{},
		{}
	>({ url: '/api/v2/applications', method: 'post', ...variables, signal })

export const useApplicationControllerCreateApplication = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TransformedApplicationDto,
			ApplicationControllerCreateApplicationError,
			ApplicationControllerCreateApplicationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.TransformedApplicationDto,
		ApplicationControllerCreateApplicationError,
		ApplicationControllerCreateApplicationVariables
	>({
		mutationFn: (
			variables: ApplicationControllerCreateApplicationVariables
		) =>
			fetchApplicationControllerCreateApplication({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type ApplicationControllerGetApplicationPathParams = {
	id: string
}

export type ApplicationControllerGetApplicationError =
	Fetcher.ErrorWrapper<undefined>

export type ApplicationControllerGetApplicationVariables = {
	pathParams: ApplicationControllerGetApplicationPathParams
} & RepositoryContext['fetcherOptions']

export const fetchApplicationControllerGetApplication = (
	variables: ApplicationControllerGetApplicationVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedApplicationDto,
		ApplicationControllerGetApplicationError,
		undefined,
		{},
		{},
		ApplicationControllerGetApplicationPathParams
	>({ url: '/api/v2/applications/{id}', method: 'get', ...variables, signal })

export const useApplicationControllerGetApplication = <
	TData = Schemas.TransformedApplicationDto
>(
	variables: ApplicationControllerGetApplicationVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransformedApplicationDto,
			ApplicationControllerGetApplicationError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.TransformedApplicationDto,
		ApplicationControllerGetApplicationError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/applications/{id}',
			operationId: 'applicationControllerGetApplication',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApplicationControllerGetApplication(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type ApplicationControllerUpdatePathParams = {
	id: string
}

export type ApplicationControllerUpdateError = Fetcher.ErrorWrapper<undefined>

export type ApplicationControllerUpdateVariables = {
	body: Schemas.UpdateApplicationDto
	pathParams: ApplicationControllerUpdatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchApplicationControllerUpdate = (
	variables: ApplicationControllerUpdateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		ApplicationControllerUpdateError,
		Schemas.UpdateApplicationDto,
		{},
		{},
		ApplicationControllerUpdatePathParams
	>({ url: '/api/v2/applications/{id}', method: 'put', ...variables, signal })

export const useApplicationControllerUpdate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApplicationControllerUpdateError,
			ApplicationControllerUpdateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		ApplicationControllerUpdateError,
		ApplicationControllerUpdateVariables
	>({
		mutationFn: (variables: ApplicationControllerUpdateVariables) =>
			fetchApplicationControllerUpdate({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type ApplicationControllerRemovePathParams = {
	id: string
}

export type ApplicationControllerRemoveError = Fetcher.ErrorWrapper<undefined>

export type ApplicationControllerRemoveVariables = {
	pathParams: ApplicationControllerRemovePathParams
} & RepositoryContext['fetcherOptions']

export const fetchApplicationControllerRemove = (
	variables: ApplicationControllerRemoveVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		ApplicationControllerRemoveError,
		undefined,
		{},
		{},
		ApplicationControllerRemovePathParams
	>({
		url: '/api/v2/applications/{id}',
		method: 'delete',
		...variables,
		signal,
	})

export const useApplicationControllerRemove = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApplicationControllerRemoveError,
			ApplicationControllerRemoveVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		ApplicationControllerRemoveError,
		ApplicationControllerRemoveVariables
	>({
		mutationFn: (variables: ApplicationControllerRemoveVariables) =>
			fetchApplicationControllerRemove({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type ApplicationControllerGetApplicationsTagsPathParams = {
	id: string
}

export type ApplicationControllerGetApplicationsTagsError =
	Fetcher.ErrorWrapper<undefined>

export type ApplicationControllerGetApplicationsTagsVariables = {
	pathParams: ApplicationControllerGetApplicationsTagsPathParams
} & RepositoryContext['fetcherOptions']

export const fetchApplicationControllerGetApplicationsTags = (
	variables: ApplicationControllerGetApplicationsTagsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedApplicationDto,
		ApplicationControllerGetApplicationsTagsError,
		undefined,
		{},
		{},
		ApplicationControllerGetApplicationsTagsPathParams
	>({
		url: '/api/v2/applications/{id}/tags',
		method: 'get',
		...variables,
		signal,
	})

export const useApplicationControllerGetApplicationsTags = <
	TData = Schemas.TransformedApplicationDto
>(
	variables: ApplicationControllerGetApplicationsTagsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransformedApplicationDto,
			ApplicationControllerGetApplicationsTagsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.TransformedApplicationDto,
		ApplicationControllerGetApplicationsTagsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/applications/{id}/tags',
			operationId: 'applicationControllerGetApplicationsTags',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApplicationControllerGetApplicationsTags(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type ApplicationControllerGetApplicationWithParentPathParams = {
	id: string
}

export type ApplicationControllerGetApplicationWithParentError =
	Fetcher.ErrorWrapper<undefined>

export type ApplicationControllerGetApplicationWithParentVariables = {
	pathParams: ApplicationControllerGetApplicationWithParentPathParams
} & RepositoryContext['fetcherOptions']

export const fetchApplicationControllerGetApplicationWithParent = (
	variables: ApplicationControllerGetApplicationWithParentVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedApplicationDtos,
		ApplicationControllerGetApplicationWithParentError,
		undefined,
		{},
		{},
		ApplicationControllerGetApplicationWithParentPathParams
	>({
		url: '/api/v2/applications/parent/{id}',
		method: 'get',
		...variables,
		signal,
	})

export const useApplicationControllerGetApplicationWithParent = <
	TData = Schemas.TransformedApplicationDtos
>(
	variables: ApplicationControllerGetApplicationWithParentVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransformedApplicationDtos,
			ApplicationControllerGetApplicationWithParentError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.TransformedApplicationDtos,
		ApplicationControllerGetApplicationWithParentError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/applications/parent/{id}',
			operationId: 'applicationControllerGetApplicationWithParent',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApplicationControllerGetApplicationWithParent(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type HealthControllerGetHealthError = Fetcher.ErrorWrapper<undefined>

export type HealthControllerGetHealthVariables =
	RepositoryContext['fetcherOptions']

export const fetchHealthControllerGetHealth = (
	variables: HealthControllerGetHealthVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		HealthControllerGetHealthError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/health', method: 'get', ...variables, signal })

export const useHealthControllerGetHealth = <TData = undefined>(
	variables: HealthControllerGetHealthVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			undefined,
			HealthControllerGetHealthError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		undefined,
		HealthControllerGetHealthError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/health',
			operationId: 'healthControllerGetHealth',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchHealthControllerGetHealth(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type HealthControllerGetInfoError = Fetcher.ErrorWrapper<undefined>

export type HealthControllerGetInfoVariables =
	RepositoryContext['fetcherOptions']

export const fetchHealthControllerGetInfo = (
	variables: HealthControllerGetInfoVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Record<string, any>,
		HealthControllerGetInfoError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/health/info', method: 'get', ...variables, signal })

export const useHealthControllerGetInfo = <TData = Record<string, any>>(
	variables: HealthControllerGetInfoVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Record<string, any>,
			HealthControllerGetInfoError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Record<string, any>,
		HealthControllerGetInfoError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/health/info',
			operationId: 'healthControllerGetInfo',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchHealthControllerGetInfo(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type IssueControllerCreateError = Fetcher.ErrorWrapper<undefined>

export type IssueControllerCreateVariables = RepositoryContext['fetcherOptions']

export const fetchIssueControllerCreate = (
	variables: IssueControllerCreateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		IssueControllerCreateError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/issues', method: 'post', ...variables, signal })

export const useIssueControllerCreate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			IssueControllerCreateError,
			IssueControllerCreateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		IssueControllerCreateError,
		IssueControllerCreateVariables
	>({
		mutationFn: (variables: IssueControllerCreateVariables) =>
			fetchIssueControllerCreate({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type IssueControllerGetAllError = Fetcher.ErrorWrapper<undefined>

export type IssueControllerGetAllVariables = RepositoryContext['fetcherOptions']

export const fetchIssueControllerGetAll = (
	variables: IssueControllerGetAllVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		IssueControllerGetAllError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/issues', method: 'get', ...variables, signal })

export const useIssueControllerGetAll = <TData = undefined>(
	variables: IssueControllerGetAllVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			undefined,
			IssueControllerGetAllError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<undefined, IssueControllerGetAllError, TData>({
		queryKey: queryKeyFn({
			path: '/api/v2/issues',
			operationId: 'issueControllerGetAll',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchIssueControllerGetAll(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type IssueControllerUpdateOneError = Fetcher.ErrorWrapper<undefined>

export type IssueControllerUpdateOneVariables = {
	body: Schemas.IssueDto
} & RepositoryContext['fetcherOptions']

export const fetchIssueControllerUpdateOne = (
	variables: IssueControllerUpdateOneVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		IssueControllerUpdateOneError,
		Schemas.IssueDto,
		{},
		{},
		{}
	>({ url: '/api/v2/issues', method: 'put', ...variables, signal })

export const useIssueControllerUpdateOne = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			IssueControllerUpdateOneError,
			IssueControllerUpdateOneVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		IssueControllerUpdateOneError,
		IssueControllerUpdateOneVariables
	>({
		mutationFn: (variables: IssueControllerUpdateOneVariables) =>
			fetchIssueControllerUpdateOne({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type IssueControllerGetPathParams = {
	id: string
}

export type IssueControllerGetError = Fetcher.ErrorWrapper<undefined>

export type IssueControllerGetVariables = {
	pathParams: IssueControllerGetPathParams
} & RepositoryContext['fetcherOptions']

export const fetchIssueControllerGet = (
	variables: IssueControllerGetVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		IssueControllerGetError,
		undefined,
		{},
		{},
		IssueControllerGetPathParams
	>({ url: '/api/v2/issues/{id}', method: 'get', ...variables, signal })

export const useIssueControllerGet = <TData = undefined>(
	variables: IssueControllerGetVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<undefined, IssueControllerGetError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<undefined, IssueControllerGetError, TData>({
		queryKey: queryKeyFn({
			path: '/api/v2/issues/{id}',
			operationId: 'issueControllerGet',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchIssueControllerGet(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type IssueControllerDeletePathParams = {
	id: string
}

export type IssueControllerDeleteError = Fetcher.ErrorWrapper<undefined>

export type IssueControllerDeleteVariables = {
	pathParams: IssueControllerDeletePathParams
} & RepositoryContext['fetcherOptions']

export const fetchIssueControllerDelete = (
	variables: IssueControllerDeleteVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		IssueControllerDeleteError,
		undefined,
		{},
		{},
		IssueControllerDeletePathParams
	>({ url: '/api/v2/issues/{id}', method: 'delete', ...variables, signal })

export const useIssueControllerDelete = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			IssueControllerDeleteError,
			IssueControllerDeleteVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		IssueControllerDeleteError,
		IssueControllerDeleteVariables
	>({
		mutationFn: (variables: IssueControllerDeleteVariables) =>
			fetchIssueControllerDelete({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type IssueControllerUpdatePathParams = {
	id: string
}

export type IssueControllerUpdateError = Fetcher.ErrorWrapper<undefined>

export type IssueControllerUpdateVariables = {
	body: Schemas.IssueDto
	pathParams: IssueControllerUpdatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchIssueControllerUpdate = (
	variables: IssueControllerUpdateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		IssueControllerUpdateError,
		Schemas.IssueDto,
		{},
		{},
		IssueControllerUpdatePathParams
	>({ url: '/api/v2/issues/{id}', method: 'put', ...variables, signal })

export const useIssueControllerUpdate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			IssueControllerUpdateError,
			IssueControllerUpdateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		IssueControllerUpdateError,
		IssueControllerUpdateVariables
	>({
		mutationFn: (variables: IssueControllerUpdateVariables) =>
			fetchIssueControllerUpdate({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type IssueControllerGetLogsPathParams = {
	id: string
	appId: string
}

export type IssueControllerGetLogsError = Fetcher.ErrorWrapper<undefined>

export type IssueControllerGetLogsVariables = {
	pathParams: IssueControllerGetLogsPathParams
} & RepositoryContext['fetcherOptions']

export const fetchIssueControllerGetLogs = (
	variables: IssueControllerGetLogsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		IssueControllerGetLogsError,
		undefined,
		{},
		{},
		IssueControllerGetLogsPathParams
	>({
		url: '/api/v2/issues/{id}/logs/{appId}',
		method: 'get',
		...variables,
		signal,
	})

export const useIssueControllerGetLogs = <TData = undefined>(
	variables: IssueControllerGetLogsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			undefined,
			IssueControllerGetLogsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<undefined, IssueControllerGetLogsError, TData>({
		queryKey: queryKeyFn({
			path: '/api/v2/issues/{id}/logs/{appId}',
			operationId: 'issueControllerGetLogs',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchIssueControllerGetLogs(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type ScreenshotControllerCreateScreenshotEntityError =
	Fetcher.ErrorWrapper<undefined>

export type ScreenshotControllerCreateScreenshotEntityVariables =
	RepositoryContext['fetcherOptions']

export const fetchScreenshotControllerCreateScreenshotEntity = (
	variables: ScreenshotControllerCreateScreenshotEntityVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		ScreenshotControllerCreateScreenshotEntityError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/screenshots', method: 'post', ...variables, signal })

export const useScreenshotControllerCreateScreenshotEntity = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ScreenshotControllerCreateScreenshotEntityError,
			ScreenshotControllerCreateScreenshotEntityVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		ScreenshotControllerCreateScreenshotEntityError,
		ScreenshotControllerCreateScreenshotEntityVariables
	>({
		mutationFn: (
			variables: ScreenshotControllerCreateScreenshotEntityVariables
		) =>
			fetchScreenshotControllerCreateScreenshotEntity({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type ScreenshotControllerAddPublicScreenshotPathParams = {
	id: string
}

export type ScreenshotControllerAddPublicScreenshotError =
	Fetcher.ErrorWrapper<undefined>

export type ScreenshotControllerAddPublicScreenshotVariables = {
	pathParams: ScreenshotControllerAddPublicScreenshotPathParams
} & RepositoryContext['fetcherOptions']

export const fetchScreenshotControllerAddPublicScreenshot = (
	variables: ScreenshotControllerAddPublicScreenshotVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		ScreenshotControllerAddPublicScreenshotError,
		undefined,
		{},
		{},
		ScreenshotControllerAddPublicScreenshotPathParams
	>({
		url: '/api/v2/screenshots/public/{id}',
		method: 'post',
		...variables,
		signal,
	})

export const useScreenshotControllerAddPublicScreenshot = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ScreenshotControllerAddPublicScreenshotError,
			ScreenshotControllerAddPublicScreenshotVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		ScreenshotControllerAddPublicScreenshotError,
		ScreenshotControllerAddPublicScreenshotVariables
	>({
		mutationFn: (
			variables: ScreenshotControllerAddPublicScreenshotVariables
		) =>
			fetchScreenshotControllerAddPublicScreenshot({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type ScreenshotControllerUploadScreenshotPathParams = {
	screenshotId: string
}

export type ScreenshotControllerUploadScreenshotError =
	Fetcher.ErrorWrapper<undefined>

export type ScreenshotControllerUploadScreenshotVariables = {
	pathParams: ScreenshotControllerUploadScreenshotPathParams
} & RepositoryContext['fetcherOptions']

export const fetchScreenshotControllerUploadScreenshot = (
	variables: ScreenshotControllerUploadScreenshotVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		ScreenshotControllerUploadScreenshotError,
		undefined,
		{},
		{},
		ScreenshotControllerUploadScreenshotPathParams
	>({
		url: '/api/v2/screenshots/{screenshotId}',
		method: 'post',
		...variables,
		signal,
	})

export const useScreenshotControllerUploadScreenshot = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ScreenshotControllerUploadScreenshotError,
			ScreenshotControllerUploadScreenshotVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		ScreenshotControllerUploadScreenshotError,
		ScreenshotControllerUploadScreenshotVariables
	>({
		mutationFn: (
			variables: ScreenshotControllerUploadScreenshotVariables
		) =>
			fetchScreenshotControllerUploadScreenshot({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type ScreenshotControllerGetScreenshotPathParams = {
	screenshotId: string
}

export type ScreenshotControllerGetScreenshotError =
	Fetcher.ErrorWrapper<undefined>

export type ScreenshotControllerGetScreenshotVariables = {
	pathParams: ScreenshotControllerGetScreenshotPathParams
} & RepositoryContext['fetcherOptions']

export const fetchScreenshotControllerGetScreenshot = (
	variables: ScreenshotControllerGetScreenshotVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		ScreenshotControllerGetScreenshotError,
		undefined,
		{},
		{},
		ScreenshotControllerGetScreenshotPathParams
	>({
		url: '/api/v2/screenshots/{screenshotId}',
		method: 'get',
		...variables,
		signal,
	})

export const useScreenshotControllerGetScreenshot = <TData = undefined>(
	variables: ScreenshotControllerGetScreenshotVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			undefined,
			ScreenshotControllerGetScreenshotError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		undefined,
		ScreenshotControllerGetScreenshotError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/screenshots/{screenshotId}',
			operationId: 'screenshotControllerGetScreenshot',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchScreenshotControllerGetScreenshot(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type UserControllerCreatePATError = Fetcher.ErrorWrapper<undefined>

export type UserControllerCreatePATVariables = {
	body: Schemas.CreatePatDTO
} & RepositoryContext['fetcherOptions']

/**
 * Creates a new Personal Access Token (PAT) for the user making this request.
 */
export const fetchUserControllerCreatePAT = (
	variables: UserControllerCreatePATVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedCreatePatResponseDTO,
		UserControllerCreatePATError,
		Schemas.CreatePatDTO,
		{},
		{},
		{}
	>({ url: '/api/v2/users/pat', method: 'post', ...variables, signal })

/**
 * Creates a new Personal Access Token (PAT) for the user making this request.
 */
export const useUserControllerCreatePAT = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TransformedCreatePatResponseDTO,
			UserControllerCreatePATError,
			UserControllerCreatePATVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.TransformedCreatePatResponseDTO,
		UserControllerCreatePATError,
		UserControllerCreatePATVariables
	>({
		mutationFn: (variables: UserControllerCreatePATVariables) =>
			fetchUserControllerCreatePAT({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type UserControllerFindPATsError = Fetcher.ErrorWrapper<undefined>

export type UserControllerFindPATsVariables =
	RepositoryContext['fetcherOptions']

export const fetchUserControllerFindPATs = (
	variables: UserControllerFindPATsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedPatDTOS,
		UserControllerFindPATsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/users/pat', method: 'get', ...variables, signal })

export const useUserControllerFindPATs = <TData = Schemas.TransformedPatDTOS>(
	variables: UserControllerFindPATsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransformedPatDTOS,
			UserControllerFindPATsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.TransformedPatDTOS,
		UserControllerFindPATsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/users/pat',
			operationId: 'userControllerFindPATs',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchUserControllerFindPATs(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type UserControllerRevokePATPathParams = {
	id: string
}

export type UserControllerRevokePATError = Fetcher.ErrorWrapper<undefined>

export type UserControllerRevokePATVariables = {
	pathParams: UserControllerRevokePATPathParams
} & RepositoryContext['fetcherOptions']

/**
 * Delete one of the current user's PATs by its ID. This is a no-op if the PAT does not exist or if it belongs to another user.
 */
export const fetchUserControllerRevokePAT = (
	variables: UserControllerRevokePATVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedPatDTO,
		UserControllerRevokePATError,
		undefined,
		{},
		{},
		UserControllerRevokePATPathParams
	>({ url: '/api/v2/users/pat/{id}', method: 'delete', ...variables, signal })

/**
 * Delete one of the current user's PATs by its ID. This is a no-op if the PAT does not exist or if it belongs to another user.
 */
export const useUserControllerRevokePAT = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TransformedPatDTO,
			UserControllerRevokePATError,
			UserControllerRevokePATVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.TransformedPatDTO,
		UserControllerRevokePATError,
		UserControllerRevokePATVariables
	>({
		mutationFn: (variables: UserControllerRevokePATVariables) =>
			fetchUserControllerRevokePAT({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type UserControllerGetUsersQueryParams = {
	/**
	 * @minimum 1
	 * @default 50
	 */
	limit?: number
	/**
	 * @minimum 0
	 */
	offset?: number
	text?: string
	workspaces?: boolean
}

export type UserControllerGetUsersError = Fetcher.ErrorWrapper<undefined>

export type UserControllerGetUsersVariables = {
	queryParams?: UserControllerGetUsersQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchUserControllerGetUsers = (
	variables: UserControllerGetUsersVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.PaginatedUserDto,
		UserControllerGetUsersError,
		undefined,
		{},
		UserControllerGetUsersQueryParams,
		{}
	>({ url: '/api/v2/users', method: 'get', ...variables, signal })

export const useUserControllerGetUsers = <TData = Schemas.PaginatedUserDto>(
	variables: UserControllerGetUsersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PaginatedUserDto,
			UserControllerGetUsersError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.PaginatedUserDto,
		UserControllerGetUsersError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/users',
			operationId: 'userControllerGetUsers',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchUserControllerGetUsers(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type UserControllerGetUserPathParams = {
	id: string
}

export type UserControllerGetUserError = Fetcher.ErrorWrapper<undefined>

export type UserControllerGetUserVariables = {
	pathParams: UserControllerGetUserPathParams
} & RepositoryContext['fetcherOptions']

export const fetchUserControllerGetUser = (
	variables: UserControllerGetUserVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.UserDto,
		UserControllerGetUserError,
		undefined,
		{},
		{},
		UserControllerGetUserPathParams
	>({ url: '/api/v2/users/{id}', method: 'get', ...variables, signal })

export const useUserControllerGetUser = <TData = Schemas.UserDto>(
	variables: UserControllerGetUserVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.UserDto,
			UserControllerGetUserError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.UserDto,
		UserControllerGetUserError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/users/{id}',
			operationId: 'userControllerGetUser',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchUserControllerGetUser(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type UserControllerUpdatePathParams = {
	id: string
}

export type UserControllerUpdateError = Fetcher.ErrorWrapper<undefined>

export type UserControllerUpdateVariables = {
	body?: Schemas.UpdateUserDto
	pathParams: UserControllerUpdatePathParams
} & RepositoryContext['fetcherOptions']

export const fetchUserControllerUpdate = (
	variables: UserControllerUpdateVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.UserDto,
		UserControllerUpdateError,
		Schemas.UpdateUserDto,
		{},
		{},
		UserControllerUpdatePathParams
	>({ url: '/api/v2/users/{id}', method: 'put', ...variables, signal })

export const useUserControllerUpdate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.UserDto,
			UserControllerUpdateError,
			UserControllerUpdateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.UserDto,
		UserControllerUpdateError,
		UserControllerUpdateVariables
	>({
		mutationFn: (variables: UserControllerUpdateVariables) =>
			fetchUserControllerUpdate({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type UserControllerRemovePathParams = {
	id: string
}

export type UserControllerRemoveError = Fetcher.ErrorWrapper<undefined>

export type UserControllerRemoveVariables = {
	pathParams: UserControllerRemovePathParams
} & RepositoryContext['fetcherOptions']

export const fetchUserControllerRemove = (
	variables: UserControllerRemoveVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		UserControllerRemoveError,
		undefined,
		{},
		{},
		UserControllerRemovePathParams
	>({ url: '/api/v2/users/{id}', method: 'delete', ...variables, signal })

export const useUserControllerRemove = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			UserControllerRemoveError,
			UserControllerRemoveVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		UserControllerRemoveError,
		UserControllerRemoveVariables
	>({
		mutationFn: (variables: UserControllerRemoveVariables) =>
			fetchUserControllerRemove({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type UserControllerValidateUserError = Fetcher.ErrorWrapper<undefined>

export type UserControllerValidateUserVariables = {
	body: Schemas.CreateUserDto
} & RepositoryContext['fetcherOptions']

export const fetchUserControllerValidateUser = (
	variables: UserControllerValidateUserVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		UserControllerValidateUserError,
		Schemas.CreateUserDto,
		{},
		{},
		{}
	>({
		url: '/api/v2/users/validateUser',
		method: 'post',
		...variables,
		signal,
	})

export const useUserControllerValidateUser = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			UserControllerValidateUserError,
			UserControllerValidateUserVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		UserControllerValidateUserError,
		UserControllerValidateUserVariables
	>({
		mutationFn: (variables: UserControllerValidateUserVariables) =>
			fetchUserControllerValidateUser({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type UserControllerActivateUserPathParams = {
	id: string
}

export type UserControllerActivateUserError = Fetcher.ErrorWrapper<undefined>

export type UserControllerActivateUserVariables = {
	pathParams: UserControllerActivateUserPathParams
} & RepositoryContext['fetcherOptions']

export const fetchUserControllerActivateUser = (
	variables: UserControllerActivateUserVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.UserDto,
		UserControllerActivateUserError,
		undefined,
		{},
		{},
		UserControllerActivateUserPathParams
	>({
		url: '/api/v2/users/activateUser/{id}',
		method: 'post',
		...variables,
		signal,
	})

export const useUserControllerActivateUser = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.UserDto,
			UserControllerActivateUserError,
			UserControllerActivateUserVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.UserDto,
		UserControllerActivateUserError,
		UserControllerActivateUserVariables
	>({
		mutationFn: (variables: UserControllerActivateUserVariables) =>
			fetchUserControllerActivateUser({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type UserControllerDeactivateUserPathParams = {
	id: string
}

export type UserControllerDeactivateUserError = Fetcher.ErrorWrapper<undefined>

export type UserControllerDeactivateUserVariables = {
	pathParams: UserControllerDeactivateUserPathParams
} & RepositoryContext['fetcherOptions']

export const fetchUserControllerDeactivateUser = (
	variables: UserControllerDeactivateUserVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.UserDto,
		UserControllerDeactivateUserError,
		undefined,
		{},
		{},
		UserControllerDeactivateUserPathParams
	>({
		url: '/api/v2/users/deactivateUser/{id}',
		method: 'post',
		...variables,
		signal,
	})

export const useUserControllerDeactivateUser = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.UserDto,
			UserControllerDeactivateUserError,
			UserControllerDeactivateUserVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.UserDto,
		UserControllerDeactivateUserError,
		UserControllerDeactivateUserVariables
	>({
		mutationFn: (variables: UserControllerDeactivateUserVariables) =>
			fetchUserControllerDeactivateUser({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type WorkspaceControllerGetWorkspacesQueryParams = {
	/**
	 * @minimum 1
	 * @default 50
	 */
	limit?: number
	/**
	 * @minimum 0
	 */
	offset?: number
}

export type WorkspaceControllerGetWorkspacesError =
	Fetcher.ErrorWrapper<undefined>

export type WorkspaceControllerGetWorkspacesVariables = {
	queryParams?: WorkspaceControllerGetWorkspacesQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchWorkspaceControllerGetWorkspaces = (
	variables: WorkspaceControllerGetWorkspacesVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedWorkspaceDtos,
		WorkspaceControllerGetWorkspacesError,
		undefined,
		{},
		WorkspaceControllerGetWorkspacesQueryParams,
		{}
	>({ url: '/api/v2/workspace', method: 'get', ...variables, signal })

export const useWorkspaceControllerGetWorkspaces = <
	TData = Schemas.TransformedWorkspaceDtos
>(
	variables: WorkspaceControllerGetWorkspacesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransformedWorkspaceDtos,
			WorkspaceControllerGetWorkspacesError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.TransformedWorkspaceDtos,
		WorkspaceControllerGetWorkspacesError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/workspace',
			operationId: 'workspaceControllerGetWorkspaces',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchWorkspaceControllerGetWorkspaces(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type WorkspaceControllerCreateWorkspaceError =
	Fetcher.ErrorWrapper<undefined>

export type WorkspaceControllerCreateWorkspaceVariables = {
	body: Schemas.CreateWorkspaceDto
} & RepositoryContext['fetcherOptions']

export const fetchWorkspaceControllerCreateWorkspace = (
	variables: WorkspaceControllerCreateWorkspaceVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedWorkspaceDto,
		WorkspaceControllerCreateWorkspaceError,
		Schemas.CreateWorkspaceDto,
		{},
		{},
		{}
	>({ url: '/api/v2/workspace', method: 'post', ...variables, signal })

export const useWorkspaceControllerCreateWorkspace = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TransformedWorkspaceDto,
			WorkspaceControllerCreateWorkspaceError,
			WorkspaceControllerCreateWorkspaceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.TransformedWorkspaceDto,
		WorkspaceControllerCreateWorkspaceError,
		WorkspaceControllerCreateWorkspaceVariables
	>({
		mutationFn: (variables: WorkspaceControllerCreateWorkspaceVariables) =>
			fetchWorkspaceControllerCreateWorkspace({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type WorkspaceControllerGetWorkspacePathParams = {
	id: string
}

export type WorkspaceControllerGetWorkspaceError =
	Fetcher.ErrorWrapper<undefined>

export type WorkspaceControllerGetWorkspaceVariables = {
	pathParams: WorkspaceControllerGetWorkspacePathParams
} & RepositoryContext['fetcherOptions']

export const fetchWorkspaceControllerGetWorkspace = (
	variables: WorkspaceControllerGetWorkspaceVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedWorkspaceDto,
		WorkspaceControllerGetWorkspaceError,
		undefined,
		{},
		{},
		WorkspaceControllerGetWorkspacePathParams
	>({ url: '/api/v2/workspace/{id}', method: 'get', ...variables, signal })

export const useWorkspaceControllerGetWorkspace = <
	TData = Schemas.TransformedWorkspaceDto
>(
	variables: WorkspaceControllerGetWorkspaceVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransformedWorkspaceDto,
			WorkspaceControllerGetWorkspaceError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.TransformedWorkspaceDto,
		WorkspaceControllerGetWorkspaceError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/workspace/{id}',
			operationId: 'workspaceControllerGetWorkspace',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchWorkspaceControllerGetWorkspace(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type WorkspaceControllerUpdateWorkspacePathParams = {
	id: string
}

export type WorkspaceControllerUpdateWorkspaceError =
	Fetcher.ErrorWrapper<undefined>

export type WorkspaceControllerUpdateWorkspaceVariables = {
	body?: Schemas.UpdateWorkspaceDto
	pathParams: WorkspaceControllerUpdateWorkspacePathParams
} & RepositoryContext['fetcherOptions']

export const fetchWorkspaceControllerUpdateWorkspace = (
	variables: WorkspaceControllerUpdateWorkspaceVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		WorkspaceControllerUpdateWorkspaceError,
		Schemas.UpdateWorkspaceDto,
		{},
		{},
		WorkspaceControllerUpdateWorkspacePathParams
	>({ url: '/api/v2/workspace/{id}', method: 'put', ...variables, signal })

export const useWorkspaceControllerUpdateWorkspace = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			WorkspaceControllerUpdateWorkspaceError,
			WorkspaceControllerUpdateWorkspaceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		WorkspaceControllerUpdateWorkspaceError,
		WorkspaceControllerUpdateWorkspaceVariables
	>({
		mutationFn: (variables: WorkspaceControllerUpdateWorkspaceVariables) =>
			fetchWorkspaceControllerUpdateWorkspace({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type WorkspaceControllerDeleteWorkspacePathParams = {
	id: string
}

export type WorkspaceControllerDeleteWorkspaceError =
	Fetcher.ErrorWrapper<undefined>

export type WorkspaceControllerDeleteWorkspaceVariables = {
	pathParams: WorkspaceControllerDeleteWorkspacePathParams
} & RepositoryContext['fetcherOptions']

/**
 * Delete a workspace. Deprecated, use DELETE /entities/:entityId
 */
export const fetchWorkspaceControllerDeleteWorkspace = (
	variables: WorkspaceControllerDeleteWorkspaceVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		WorkspaceControllerDeleteWorkspaceError,
		undefined,
		{},
		{},
		WorkspaceControllerDeleteWorkspacePathParams
	>({ url: '/api/v2/workspace/{id}', method: 'delete', ...variables, signal })

/**
 * Delete a workspace. Deprecated, use DELETE /entities/:entityId
 */
export const useWorkspaceControllerDeleteWorkspace = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			WorkspaceControllerDeleteWorkspaceError,
			WorkspaceControllerDeleteWorkspaceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		WorkspaceControllerDeleteWorkspaceError,
		WorkspaceControllerDeleteWorkspaceVariables
	>({
		mutationFn: (variables: WorkspaceControllerDeleteWorkspaceVariables) =>
			fetchWorkspaceControllerDeleteWorkspace({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type WorkspaceControllerAddUnlimitedPlanPathParams = {
	wsId: string
}

export type WorkspaceControllerAddUnlimitedPlanError =
	Fetcher.ErrorWrapper<undefined>

export type WorkspaceControllerAddUnlimitedPlanVariables = {
	pathParams: WorkspaceControllerAddUnlimitedPlanPathParams
} & RepositoryContext['fetcherOptions']

export const fetchWorkspaceControllerAddUnlimitedPlan = (
	variables: WorkspaceControllerAddUnlimitedPlanVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.TransformedDefaultResponseDto,
		WorkspaceControllerAddUnlimitedPlanError,
		undefined,
		{},
		{},
		WorkspaceControllerAddUnlimitedPlanPathParams
	>({
		url: '/api/v2/workspace/{wsId}/add-unlimited-plan',
		method: 'post',
		...variables,
		signal,
	})

export const useWorkspaceControllerAddUnlimitedPlan = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TransformedDefaultResponseDto,
			WorkspaceControllerAddUnlimitedPlanError,
			WorkspaceControllerAddUnlimitedPlanVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.TransformedDefaultResponseDto,
		WorkspaceControllerAddUnlimitedPlanError,
		WorkspaceControllerAddUnlimitedPlanVariables
	>({
		mutationFn: (variables: WorkspaceControllerAddUnlimitedPlanVariables) =>
			fetchWorkspaceControllerAddUnlimitedPlan({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type InviteUserControllerGetInvitesQueryParams = {
	/**
	 * @minimum 1
	 * @default 50
	 */
	limit?: number
	/**
	 * @minimum 0
	 */
	offset?: number
	text?: string
}

export type InviteUserControllerGetInvitesError =
	Fetcher.ErrorWrapper<undefined>

export type InviteUserControllerGetInvitesResponse = Schemas.InviteUserDTO[]

export type InviteUserControllerGetInvitesVariables = {
	queryParams?: InviteUserControllerGetInvitesQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchInviteUserControllerGetInvites = (
	variables: InviteUserControllerGetInvitesVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		InviteUserControllerGetInvitesResponse,
		InviteUserControllerGetInvitesError,
		undefined,
		{},
		InviteUserControllerGetInvitesQueryParams,
		{}
	>({ url: '/api/v2/invite-user', method: 'get', ...variables, signal })

export const useInviteUserControllerGetInvites = <
	TData = InviteUserControllerGetInvitesResponse
>(
	variables: InviteUserControllerGetInvitesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			InviteUserControllerGetInvitesResponse,
			InviteUserControllerGetInvitesError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		InviteUserControllerGetInvitesResponse,
		InviteUserControllerGetInvitesError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/invite-user',
			operationId: 'inviteUserControllerGetInvites',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchInviteUserControllerGetInvites(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type InviteUserControllerCreateInvitationError =
	Fetcher.ErrorWrapper<undefined>

export type InviteUserControllerCreateInvitationVariables = {
	body: Schemas.CreateUserInvitationDto
} & RepositoryContext['fetcherOptions']

export const fetchInviteUserControllerCreateInvitation = (
	variables: InviteUserControllerCreateInvitationVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.InviteUserDTO,
		InviteUserControllerCreateInvitationError,
		Schemas.CreateUserInvitationDto,
		{},
		{},
		{}
	>({ url: '/api/v2/invite-user', method: 'post', ...variables, signal })

export const useInviteUserControllerCreateInvitation = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.InviteUserDTO,
			InviteUserControllerCreateInvitationError,
			InviteUserControllerCreateInvitationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.InviteUserDTO,
		InviteUserControllerCreateInvitationError,
		InviteUserControllerCreateInvitationVariables
	>({
		mutationFn: (
			variables: InviteUserControllerCreateInvitationVariables
		) =>
			fetchInviteUserControllerCreateInvitation({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type InviteUserControllerRemoveEntityPathParams = {
	invitationId: string
}

export type InviteUserControllerRemoveEntityError =
	Fetcher.ErrorWrapper<undefined>

export type InviteUserControllerRemoveEntityVariables = {
	pathParams: InviteUserControllerRemoveEntityPathParams
} & RepositoryContext['fetcherOptions']

export const fetchInviteUserControllerRemoveEntity = (
	variables: InviteUserControllerRemoveEntityVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		InviteUserControllerRemoveEntityError,
		undefined,
		{},
		{},
		InviteUserControllerRemoveEntityPathParams
	>({
		url: '/api/v2/invite-user/{invitationId}',
		method: 'delete',
		...variables,
		signal,
	})

export const useInviteUserControllerRemoveEntity = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			InviteUserControllerRemoveEntityError,
			InviteUserControllerRemoveEntityVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		InviteUserControllerRemoveEntityError,
		InviteUserControllerRemoveEntityVariables
	>({
		mutationFn: (variables: InviteUserControllerRemoveEntityVariables) =>
			fetchInviteUserControllerRemoveEntity({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type InstanceEntityControllerGetSystemsEntityError =
	Fetcher.ErrorWrapper<undefined>

export type InstanceEntityControllerGetSystemsEntityVariables =
	RepositoryContext['fetcherOptions']

export const fetchInstanceEntityControllerGetSystemsEntity = (
	variables: InstanceEntityControllerGetSystemsEntityVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.EntityResponse,
		InstanceEntityControllerGetSystemsEntityError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/v2/entities/system', method: 'get', ...variables, signal })

export const useInstanceEntityControllerGetSystemsEntity = <
	TData = Schemas.EntityResponse
>(
	variables: InstanceEntityControllerGetSystemsEntityVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EntityResponse,
			InstanceEntityControllerGetSystemsEntityError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		Schemas.EntityResponse,
		InstanceEntityControllerGetSystemsEntityError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/entities/system',
			operationId: 'instanceEntityControllerGetSystemsEntity',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchInstanceEntityControllerGetSystemsEntity(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type InstanceEntityControllerGetEntitysChildrenPathParams = {
	id: string
}

export type InstanceEntityControllerGetEntitysChildrenError =
	Fetcher.ErrorWrapper<undefined>

export type InstanceEntityControllerGetEntitysChildrenVariables = {
	pathParams: InstanceEntityControllerGetEntitysChildrenPathParams
} & RepositoryContext['fetcherOptions']

export const fetchInstanceEntityControllerGetEntitysChildren = (
	variables: InstanceEntityControllerGetEntitysChildrenVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		InstanceEntityControllerGetEntitysChildrenError,
		undefined,
		{},
		{},
		InstanceEntityControllerGetEntitysChildrenPathParams
	>({
		url: '/api/v2/entities/{id}/children',
		method: 'get',
		...variables,
		signal,
	})

export const useInstanceEntityControllerGetEntitysChildren = <
	TData = undefined
>(
	variables: InstanceEntityControllerGetEntitysChildrenVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			undefined,
			InstanceEntityControllerGetEntitysChildrenError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		undefined,
		InstanceEntityControllerGetEntitysChildrenError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/entities/{id}/children',
			operationId: 'instanceEntityControllerGetEntitysChildren',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchInstanceEntityControllerGetEntitysChildren(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type InstanceEntityControllerRemoveEntityPathParams = {
	id: string
}

export type InstanceEntityControllerRemoveEntityError =
	Fetcher.ErrorWrapper<undefined>

export type InstanceEntityControllerRemoveEntityVariables = {
	pathParams: InstanceEntityControllerRemoveEntityPathParams
} & RepositoryContext['fetcherOptions']

export const fetchInstanceEntityControllerRemoveEntity = (
	variables: InstanceEntityControllerRemoveEntityVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		InstanceEntityControllerRemoveEntityError,
		undefined,
		{},
		{},
		InstanceEntityControllerRemoveEntityPathParams
	>({ url: '/api/v2/entities/{id}', method: 'delete', ...variables, signal })

export const useInstanceEntityControllerRemoveEntity = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			InstanceEntityControllerRemoveEntityError,
			InstanceEntityControllerRemoveEntityVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		InstanceEntityControllerRemoveEntityError,
		InstanceEntityControllerRemoveEntityVariables
	>({
		mutationFn: (
			variables: InstanceEntityControllerRemoveEntityVariables
		) =>
			fetchInstanceEntityControllerRemoveEntity({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type SubscriptionControllerManageSubscriptionsError =
	Fetcher.ErrorWrapper<undefined>

export type SubscriptionControllerManageSubscriptionsVariables =
	RepositoryContext['fetcherOptions']

export const fetchSubscriptionControllerManageSubscriptions = (
	variables: SubscriptionControllerManageSubscriptionsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		SubscriptionControllerManageSubscriptionsError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/api/v2/subscription/manage-subscriptions',
		method: 'post',
		...variables,
		signal,
	})

export const useSubscriptionControllerManageSubscriptions = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			SubscriptionControllerManageSubscriptionsError,
			SubscriptionControllerManageSubscriptionsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		SubscriptionControllerManageSubscriptionsError,
		SubscriptionControllerManageSubscriptionsVariables
	>({
		mutationFn: (
			variables: SubscriptionControllerManageSubscriptionsVariables
		) =>
			fetchSubscriptionControllerManageSubscriptions({
				...fetcherOptions,
				...variables,
			}),
		...options,
	})
}

export type ViewControllerGetViewsPathParams = {
	parentId: string
}

export type ViewControllerGetViewsQueryParams = {
	/**
	 * The url the view should be displayed
	 */
	url?: string
}

export type ViewControllerGetViewsError = Fetcher.ErrorWrapper<undefined>

export type ViewControllerGetViewsResponse = Schemas.ViewDTO[]

export type ViewControllerGetViewsVariables = {
	pathParams: ViewControllerGetViewsPathParams
	queryParams?: ViewControllerGetViewsQueryParams
} & RepositoryContext['fetcherOptions']

export const fetchViewControllerGetViews = (
	variables: ViewControllerGetViewsVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		ViewControllerGetViewsResponse,
		ViewControllerGetViewsError,
		undefined,
		{},
		ViewControllerGetViewsQueryParams,
		ViewControllerGetViewsPathParams
	>({ url: '/api/v2/view/{parentId}', method: 'get', ...variables, signal })

export const useViewControllerGetViews = <
	TData = ViewControllerGetViewsResponse
>(
	variables: ViewControllerGetViewsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ViewControllerGetViewsResponse,
			ViewControllerGetViewsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRepositoryContext(options)
	return reactQuery.useQuery<
		ViewControllerGetViewsResponse,
		ViewControllerGetViewsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/api/v2/view/{parentId}',
			operationId: 'viewControllerGetViews',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchViewControllerGetViews(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	})
}

export type ViewControllerCreateViewPathParams = {
	parentId: string
}

export type ViewControllerCreateViewError = Fetcher.ErrorWrapper<undefined>

export type ViewControllerCreateViewVariables = {
	body: Schemas.CreateViewDTO
	pathParams: ViewControllerCreateViewPathParams
} & RepositoryContext['fetcherOptions']

export const fetchViewControllerCreateView = (
	variables: ViewControllerCreateViewVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.ViewDTO,
		ViewControllerCreateViewError,
		Schemas.CreateViewDTO,
		{},
		{},
		ViewControllerCreateViewPathParams
	>({ url: '/api/v2/view/{parentId}', method: 'post', ...variables, signal })

export const useViewControllerCreateView = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.ViewDTO,
			ViewControllerCreateViewError,
			ViewControllerCreateViewVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.ViewDTO,
		ViewControllerCreateViewError,
		ViewControllerCreateViewVariables
	>({
		mutationFn: (variables: ViewControllerCreateViewVariables) =>
			fetchViewControllerCreateView({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type ViewControllerUpdateViewPathParams = {
	parentId: string
	viewId: string
}

export type ViewControllerUpdateViewError = Fetcher.ErrorWrapper<undefined>

export type ViewControllerUpdateViewVariables = {
	body?: Schemas.UpdateViewDTO
	pathParams: ViewControllerUpdateViewPathParams
} & RepositoryContext['fetcherOptions']

export const fetchViewControllerUpdateView = (
	variables: ViewControllerUpdateViewVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		Schemas.ViewDTO,
		ViewControllerUpdateViewError,
		Schemas.UpdateViewDTO,
		{},
		{},
		ViewControllerUpdateViewPathParams
	>({
		url: '/api/v2/view/{parentId}/{viewId}',
		method: 'put',
		...variables,
		signal,
	})

export const useViewControllerUpdateView = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.ViewDTO,
			ViewControllerUpdateViewError,
			ViewControllerUpdateViewVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		Schemas.ViewDTO,
		ViewControllerUpdateViewError,
		ViewControllerUpdateViewVariables
	>({
		mutationFn: (variables: ViewControllerUpdateViewVariables) =>
			fetchViewControllerUpdateView({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type ViewControllerDeleteViewPathParams = {
	parentId: string
	viewId: string
}

export type ViewControllerDeleteViewError = Fetcher.ErrorWrapper<undefined>

export type ViewControllerDeleteViewVariables = {
	pathParams: ViewControllerDeleteViewPathParams
} & RepositoryContext['fetcherOptions']

export const fetchViewControllerDeleteView = (
	variables: ViewControllerDeleteViewVariables,
	signal?: AbortSignal
) =>
	repositoryFetch<
		undefined,
		ViewControllerDeleteViewError,
		undefined,
		{},
		{},
		ViewControllerDeleteViewPathParams
	>({
		url: '/api/v2/view/{parentId}/{viewId}',
		method: 'delete',
		...variables,
		signal,
	})

export const useViewControllerDeleteView = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ViewControllerDeleteViewError,
			ViewControllerDeleteViewVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRepositoryContext()
	return reactQuery.useMutation<
		undefined,
		ViewControllerDeleteViewError,
		ViewControllerDeleteViewVariables
	>({
		mutationFn: (variables: ViewControllerDeleteViewVariables) =>
			fetchViewControllerDeleteView({ ...fetcherOptions, ...variables }),
		...options,
	})
}

export type QueryOperation =
	| {
			path: '/api/v2/assets/{appId}/rule/application/table-data'
			operationId: 'ruleControllerFindAllTableData'
			variables: RuleControllerFindAllTableDataVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/rule'
			operationId: 'ruleControllerFindAllBase'
			variables: RuleControllerFindAllBaseVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/rule/application'
			operationId: 'ruleControllerFindAll'
			variables: RuleControllerFindAllVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/rule/{id}'
			operationId: 'ruleControllerFindOne'
			variables: RuleControllerFindOneVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/rule/application/get-by-name'
			operationId: 'ruleControllerGetAssetsByName'
			variables: RuleControllerGetAssetsByNameVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/dataset/application/table-data'
			operationId: 'datasetControllerFindAllTableData'
			variables: DatasetControllerFindAllTableDataVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/dataset'
			operationId: 'datasetControllerFindAll'
			variables: DatasetControllerFindAllVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/dataset/application'
			operationId: 'datasetControllerFindAllApplication'
			variables: DatasetControllerFindAllApplicationVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/dataset/{id}'
			operationId: 'datasetControllerFindOne'
			variables: DatasetControllerFindOneVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/dataset/application/get-by-name'
			operationId: 'datasetControllerGetAssetsByName'
			variables: DatasetControllerGetAssetsByNameVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/flow/application/table-data'
			operationId: 'flowControllerFindAllTableData'
			variables: FlowControllerFindAllTableDataVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/flow'
			operationId: 'flowControllerFindAllBase'
			variables: FlowControllerFindAllBaseVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/flow/application'
			operationId: 'flowControllerFindAll'
			variables: FlowControllerFindAllVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/flow/{id}'
			operationId: 'flowControllerFindOne'
			variables: FlowControllerFindOneVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/flow/{id}/referencing'
			operationId: 'flowControllerFindReferencing'
			variables: FlowControllerFindReferencingVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/flow/application/get-by-name'
			operationId: 'flowControllerGetAssetsByName'
			variables: FlowControllerGetAssetsByNameVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/run-template/application/table-data'
			operationId: 'runTemplateControllerFindAllTableData'
			variables: RunTemplateControllerFindAllTableDataVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/run-template'
			operationId: 'runTemplateControllerFindAllBase'
			variables: RunTemplateControllerFindAllBaseVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/run-template/application'
			operationId: 'runTemplateControllerFindAll'
			variables: RunTemplateControllerFindAllVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/run-template/{id}'
			operationId: 'runTemplateControllerFindOne'
			variables: RunTemplateControllerFindOneVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/run-template/application/get-by-name'
			operationId: 'runTemplateControllerGetAssetsByName'
			variables: RunTemplateControllerGetAssetsByNameVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/form-spec/application/table-data'
			operationId: 'formSpecControllerFindAllTableData'
			variables: FormSpecControllerFindAllTableDataVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/form-spec'
			operationId: 'formSpecControllerFindAllBase'
			variables: FormSpecControllerFindAllBaseVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/form-spec/application'
			operationId: 'formSpecControllerFindAll'
			variables: FormSpecControllerFindAllVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/form-spec/{id}'
			operationId: 'formSpecControllerFindOne'
			variables: FormSpecControllerFindOneVariables
	  }
	| {
			path: '/api/v2/assets/{appId}/form-spec/application/get-by-name'
			operationId: 'formSpecControllerGetAssetsByName'
			variables: FormSpecControllerGetAssetsByNameVariables
	  }
	| {
			path: '/api/v2/auth/whoami'
			operationId: 'authControllerGetPermissions'
			variables: AuthControllerGetPermissionsVariables
	  }
	| {
			path: '/api/v2/run'
			operationId: 'runsControllerGetRuns'
			variables: RunsControllerGetRunsVariables
	  }
	| {
			path: '/api/v2/run/{id}'
			operationId: 'runsControllerGetVariantRuns'
			variables: RunsControllerGetVariantRunsVariables
	  }
	| {
			path: '/api/v2/run/variant/{id}/hasRuns'
			operationId: 'runsControllerHasRuns'
			variables: RunsControllerHasRunsVariables
	  }
	| {
			path: '/api/v2/run/{runId}/currentPage'
			operationId: 'runsControllerGetCurrentPage'
			variables: RunsControllerGetCurrentPageVariables
	  }
	| {
			path: '/api/v2/run/parameters'
			operationId: 'runParametersControllerGetAll'
			variables: RunParametersControllerGetAllVariables
	  }
	| {
			path: '/api/v2/run/parameters/{id}'
			operationId: 'runParametersControllerGet'
			variables: RunParametersControllerGetVariables
	  }
	| {
			path: '/api/v2/run/{runId}/log'
			operationId: 'runsLogControllerGetRunLog'
			variables: RunsLogControllerGetRunLogVariables
	  }
	| {
			path: '/api/v2/run/log'
			operationId: 'runsLogControllerGetLogs'
			variables: RunsLogControllerGetLogsVariables
	  }
	| {
			path: '/api/v2/run/{id}/status'
			operationId: 'runsStatusControllerGetStatus'
			variables: RunsStatusControllerGetStatusVariables
	  }
	| {
			path: '/api/v2/run/{runId}/workQueue'
			operationId: 'runsWorkQueueControllerGet'
			variables: RunsWorkQueueControllerGetVariables
	  }
	| {
			path: '/api/v2/run/options'
			operationId: 'startOptionsControllerGetStartOptions'
			variables: StartOptionsControllerGetStartOptionsVariables
	  }
	| {
			path: '/api/v2/runs/{id}/run/{runNumber}'
			operationId: 'runsLogControllerV2GetRun'
			variables: RunsLogControllerV2GetRunVariables
	  }
	| {
			path: '/api/v2/runs/{id}/log/{runNumber}'
			operationId: 'runsLogControllerV2GetRunLog'
			variables: RunsLogControllerV2GetRunLogVariables
	  }
	| {
			path: '/api/v2/variants'
			operationId: 'variantControllerGetVariants'
			variables: VariantControllerGetVariantsVariables
	  }
	| {
			path: '/api/v2/variants/{id}'
			operationId: 'variantControllerGetVariant'
			variables: VariantControllerGetVariantVariables
	  }
	| {
			path: '/api/v2/variants/parent/{id}'
			operationId: 'variantControllerGetVariantsWithParent'
			variables: VariantControllerGetVariantsWithParentVariables
	  }
	| {
			path: '/api/v2/variants/{id}/workspace'
			operationId: 'variantControllerGetVariantsWorkspace'
			variables: VariantControllerGetVariantsWorkspaceVariables
	  }
	| {
			path: '/api/v2/variants/{id}/application'
			operationId: 'variantControllerGetVariantsApplication'
			variables: VariantControllerGetVariantsApplicationVariables
	  }
	| {
			path: '/api/v2/variants/{appId}/model'
			operationId: 'modelControllerGet'
			variables: ModelControllerGetVariables
	  }
	| {
			path: '/api/v2/variants/{appId}/model/components/{compId}'
			operationId: 'modelControllerGetComponent'
			variables: ModelControllerGetComponentVariables
	  }
	| {
			path: '/api/v2/variants/{appId}/model/pages'
			operationId: 'modelControllerListPageModels'
			variables: ModelControllerListPageModelsVariables
	  }
	| {
			path: '/api/v2/variants/{appId}/model/pages/{modelIdKind}/{id}/model'
			operationId: 'modelControllerGetPageModel'
			variables: ModelControllerGetPageModelVariables
	  }
	| {
			path: '/api/v2/permissions'
			operationId: 'permissionControllerGetPermissions'
			variables: PermissionControllerGetPermissionsVariables
	  }
	| {
			path: '/api/v2/permissions/{id}'
			operationId: 'permissionControllerGetPermission'
			variables: PermissionControllerGetPermissionVariables
	  }
	| {
			path: '/api/v2/permissions/resource/{id}'
			operationId: 'permissionControllerGetResourcePermissions'
			variables: PermissionControllerGetResourcePermissionsVariables
	  }
	| {
			path: '/api/v2/permissions/user/{id}'
			operationId: 'permissionControllerGetUserPermissions'
			variables: PermissionControllerGetUserPermissionsVariables
	  }
	| {
			path: '/api/v2/usage/{id}'
			operationId: 'usageControllerGetWorkspaceUsage'
			variables: UsageControllerGetWorkspaceUsageVariables
	  }
	| {
			path: '/api/v2/analytics/app/{appId}/run/{runId}'
			operationId: 'analyticsControllerGetRunRuleStatistics'
			variables: AnalyticsControllerGetRunRuleStatisticsVariables
	  }
	| {
			path: '/api/v2/analytics/app/{appId}/components'
			operationId: 'analyticsControllerGetComponentRuleStatistics'
			variables: AnalyticsControllerGetComponentRuleStatisticsVariables
	  }
	| {
			path: '/api/v2/analytics/app/{appId}/pages'
			operationId: 'analyticsControllerGetPageRuleStatistics'
			variables: AnalyticsControllerGetPageRuleStatisticsVariables
	  }
	| {
			path: '/api/v2/analytics/app/{appId}/pages/performance'
			operationId: 'analyticsControllerGetPagePerformanceStatistics'
			variables: AnalyticsControllerGetPagePerformanceStatisticsVariables
	  }
	| {
			path: '/api/v2/tags'
			operationId: 'tagsControllerGetTagsWithIds'
			variables: TagsControllerGetTagsWithIdsVariables
	  }
	| {
			path: '/api/v2/tags/{id}'
			operationId: 'tagsControllerGetAppTags'
			variables: TagsControllerGetAppTagsVariables
	  }
	| {
			path: '/api/v2/applications'
			operationId: 'applicationControllerGetApplications'
			variables: ApplicationControllerGetApplicationsVariables
	  }
	| {
			path: '/api/v2/applications/{id}'
			operationId: 'applicationControllerGetApplication'
			variables: ApplicationControllerGetApplicationVariables
	  }
	| {
			path: '/api/v2/applications/{id}/tags'
			operationId: 'applicationControllerGetApplicationsTags'
			variables: ApplicationControllerGetApplicationsTagsVariables
	  }
	| {
			path: '/api/v2/applications/parent/{id}'
			operationId: 'applicationControllerGetApplicationWithParent'
			variables: ApplicationControllerGetApplicationWithParentVariables
	  }
	| {
			path: '/api/v2/health'
			operationId: 'healthControllerGetHealth'
			variables: HealthControllerGetHealthVariables
	  }
	| {
			path: '/api/v2/health/info'
			operationId: 'healthControllerGetInfo'
			variables: HealthControllerGetInfoVariables
	  }
	| {
			path: '/api/v2/issues'
			operationId: 'issueControllerGetAll'
			variables: IssueControllerGetAllVariables
	  }
	| {
			path: '/api/v2/issues/{id}'
			operationId: 'issueControllerGet'
			variables: IssueControllerGetVariables
	  }
	| {
			path: '/api/v2/issues/{id}/logs/{appId}'
			operationId: 'issueControllerGetLogs'
			variables: IssueControllerGetLogsVariables
	  }
	| {
			path: '/api/v2/screenshots/{screenshotId}'
			operationId: 'screenshotControllerGetScreenshot'
			variables: ScreenshotControllerGetScreenshotVariables
	  }
	| {
			path: '/api/v2/users/pat'
			operationId: 'userControllerFindPATs'
			variables: UserControllerFindPATsVariables
	  }
	| {
			path: '/api/v2/users'
			operationId: 'userControllerGetUsers'
			variables: UserControllerGetUsersVariables
	  }
	| {
			path: '/api/v2/users/{id}'
			operationId: 'userControllerGetUser'
			variables: UserControllerGetUserVariables
	  }
	| {
			path: '/api/v2/workspace'
			operationId: 'workspaceControllerGetWorkspaces'
			variables: WorkspaceControllerGetWorkspacesVariables
	  }
	| {
			path: '/api/v2/workspace/{id}'
			operationId: 'workspaceControllerGetWorkspace'
			variables: WorkspaceControllerGetWorkspaceVariables
	  }
	| {
			path: '/api/v2/invite-user'
			operationId: 'inviteUserControllerGetInvites'
			variables: InviteUserControllerGetInvitesVariables
	  }
	| {
			path: '/api/v2/entities/system'
			operationId: 'instanceEntityControllerGetSystemsEntity'
			variables: InstanceEntityControllerGetSystemsEntityVariables
	  }
	| {
			path: '/api/v2/entities/{id}/children'
			operationId: 'instanceEntityControllerGetEntitysChildren'
			variables: InstanceEntityControllerGetEntitysChildrenVariables
	  }
	| {
			path: '/api/v2/view/{parentId}'
			operationId: 'viewControllerGetViews'
			variables: ViewControllerGetViewsVariables
	  }
